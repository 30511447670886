import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTimeService } from '@app/core/services/date-time.service';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { Item } from '@feedonomics/frontend-components';
import { IconDefinition, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'fdx-logs-filters',
    templateUrl: './logs-filters.component.html',
    styleUrls: ['./logs-filters.component.scss']
})
export class LogsFiltersComponent implements OnChanges {

    // Reuse ngbCollapse input for our own handling
    @Input() ngbCollapse: boolean;
    @Output() readonly ngbCollapseChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() logType: LogType;
    @Input() filters: FormGroup;
    @Input() minDate: NgbDateStruct;
    @Input() maxDate: NgbDateStruct;

    @Output() readonly submitFilters: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly clearFilters: EventEmitter<void> = new EventEmitter<void>();

    readonly faTriangleExclamation: IconDefinition = faTriangleExclamation;

    defaultFilters: string = null;
    previousFilters: string = null;
    showWarning: boolean = false;

    logTypes: typeof LogType = LogType;

    testItems: Item[] = [
        {
            value: 'test item 1',
            label: 'test item 1'
        },
        {
            value: 'test item 2',
            label: 'test item 2'
        },
        {
            value: 'test item 3',
            label: 'test item 3'
        }
    ];

    get timeZoneCode(): string {
        return this.dateTimeService.getTimezoneCode();
    }

    constructor(
        private readonly dateTimeService: DateTimeService,
        private readonly formBuilder: FormBuilder
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if ('logType' in changes) {
            this.handleLogTypeChange();
        }

        /**
         * Only trigger handleCollapse when we're actively collapsing
         */
        if ('ngbCollapse' in changes && changes.ngbCollapse.previousValue !== undefined && changes.ngbCollapse.currentValue === true) {
            this.handleCollapse();
        }
    }

    /**
     * On collapse, check the new filter values against the previous filter values. If they're different,
     * show the warning message.
     */
    handleCollapse(): void {
        if (this.previousFilters !== JSON.stringify(this.filters.value)) {
            this.showWarning = true;
        }
    }

    /**
     * When the log type changes, we've already cleared out the old input values from the FormGroup,
     * so take the new value as the new baseline that we've already sent to the API.
     * Reset the warning message and the ngbCollapse state so that handleCollapse works correctly.
     */
    handleLogTypeChange(): void {
        this.previousFilters = JSON.stringify(this.filters.value);
        this.showWarning = false;
        this.ngbCollapse = undefined;
    }

    /**
     * When we apply filters, set the previous filters to the new value, toggle off any warning message,
     * and emit the event.
     */
    applyFilters(): void {
        this.previousFilters = JSON.stringify(this.filters.value);
        this.showWarning = false;
        this.submitFilters.emit();
    }

    /**
     * When we clear all filters, the parent component will handle clearing the values, but we must reflect
     * those changes here, so instead of listening for this change to come back, just mimic resetting the filters
     * so we can set the previousFilters value.
     */
    clearAllFilters(): void {
        const tempFilters: FormGroup = this.formBuilder.group(this.filters.value);
        tempFilters.reset();
        this.previousFilters = JSON.stringify(tempFilters.value);

        this.showWarning = false;
        this.clearFilters.emit();
    }

    patchFromMultiselect(formControlName: string, event: unknown): void {
        this.filters.patchValue({ [formControlName]: event });
    }
}
