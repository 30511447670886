import FdxUI from '@ajs/services/fdxUI';
import { Component, OnDestroy, OnInit, Self, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { API_DATE_TIME_FORMAT, DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT, NGB_DATE_FORMAT } from '@app/core/models/constants/date-time';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { DateTimeService } from '@app/core/services/date-time.service';
import { LogsFiltersComponent } from '@app/logs/components/logs-filters/logs-filters.component';
import { LOG_TYPE_MAP } from '@app/logs/constants/logs.constants';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogTypeOption } from '@app/logs/models/types/log-type-option.type';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { PageChangedEvent } from '@app/modules/pagination/types/page-changed-event.type';
import { FilterChip } from '@app/standalone-components/buttons/filter-chips/filter-chips.component';
import { DateRange } from '@feedonomics/frontend-components';
import { IconDefinition, faDownload, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-logs-page',
    templateUrl: './logs-page.component.html',
    styleUrls: ['./logs-page.component.scss'],
    providers: [
        LogsStateService
    ]
})
export class LogsPageComponent implements OnInit, OnDestroy {
    appMenuTab: AppMenuTab = AppMenuTab.None;
    title: string = 'Logs';

    readonly filterIcon: IconDefinition = faFilter;
    readonly downloadIcon: IconDefinition = faDownload;
    private readonly unsubscribe$: Subject<void> = new Subject<void>();
    @ViewChild(LogsFiltersComponent) logsFilters: LogsFiltersComponent;

    logTypes: LogTypeOption[] = Array.from(LOG_TYPE_MAP.values());

    // eslint-disable-next-line @typescript-eslint/typedef
    logTypeForm = new FormGroup({
        logType: new FormControl<LogTypeOption>(this.logTypes[0])
    });

    get logType(): LogType {
        return this.logTypeForm.value.logType.value;
    }

    filters: FormGroup<{
        dateRange: FormControl<DateRange>,
        operation?: FormControl<string[]>
        action?: FormControl<string[]>
        field?: FormControl<string[]>
        user?: FormControl<string[]>
        status?: FormControl<string[]>
        module?: FormControl<string[]>
        /* changeLogFilter?: FormControl<string[]> */
    }> = new FormGroup({
        dateRange: new FormControl<DateRange>(null),
    });

    previousQuery: string = '';
    query: string = '';

    minDate: NgbDateStruct;
    maxDate: NgbDateStruct;

    filtersCollapsed: boolean = true;

    filterChips: FilterChip[] = [];

    itemsPerPage: number = 100;
    currentPage: number = 1;
    totalLogs: number = 0;

    get filtersActive(): boolean {
        return this.filterChips.length > 0 && this.filterChips.some((chip) => !chip.permanent);
    }

    get timeZoneCode(): string {
        return this.dateTimeService.getTimezoneCode();
    }

    constructor(
        private readonly dateTimeService: DateTimeService,
        private readonly fdxUI: FdxUI,
        @Self() private readonly logsStateService: LogsStateService,
        private readonly route: ActivatedRoute,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.fdxUI.setActiveTab(this.appMenuTab);
        this.fdxUI.setTitle(this.title);

        this.initDateRange();
        this.initLogsStateService();
        this.initLogType();
        this.initQueryParams();
        this.buildFilterChips();

        this.fireLogsRequest();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initDateRange(): void {
        const today = dayjs(new Date());
        const monthAgo = today.subtract(1, 'month');
        this.minDate = this.dateTimeService.dayJStoNgbDateStruct(today.subtract(1, 'year'));
        this.maxDate = this.dateTimeService.dayJStoNgbDateStruct(today);

        this.filters.patchValue({
            dateRange: new DateRange(
                this.dateTimeService.dayJSToNGBDate(monthAgo),
                this.dateTimeService.dayJSToNGBDate(today)
            )
        });
    }

    initLogsStateService(): void {
        this.logsStateService.logsTotalCount$.pipe(
            tap((totalLogs: number) => {
                this.totalLogs = totalLogs;
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    initLogType(): void {
        const routePath = this.route.snapshot.firstChild.routeConfig.path;
        const urlLogType = this.logTypes.find((option) => option.route === routePath);
        if (urlLogType) {
            this.logTypeForm.patchValue({
                logType: urlLogType
            });
        }

        this.handleLogTypeChange();

        this.logTypeForm.valueChanges.pipe(
            tap((values) => {
                this.handleLogTypeChange();

                const newRoutePath: string = values.logType.route;
                void this.router.navigate(
                    [newRoutePath],
                    {
                        relativeTo: this.route,
                        replaceUrl: true
                    }
                );
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    initQueryParams(): void {
        const params = this.route.snapshot.queryParamMap;

        const query = params.get('query');

        if (query) {
            this.query = decodeURIComponent(query);
            this.previousQuery = this.query;
        } else {
            this.previousQuery = '';
        }
    }

    fireLogsRequest(download: boolean = false): void {
        this.logsStateService.logsRequest$.next({
            page: this.currentPage,
            sort: 'time',
            log_disabled: true,
            start_date: this.filters.value.dateRange.isoStartDate,
            end_date: this.filters.value.dateRange.isoEndDate,
            download: download,
            user_timezone: this.dateTimeService.getTimezone()
        });
    }

    onSearchChange($event: string): void {
        this.query = $event;

        if (this.previousQuery !== this.query) {
            this.previousQuery = this.query;

            // keep current route but update query params
            void this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                    queryParams: {
                        query: this.query
                    }
                }
            );
        }

        /* this.resetGridPage();
        this.searched = true;
        this.gridApi?.refreshServerSide({ purge: true }); */
    }

    onPageChange($event: PageChangedEvent): void {
        this.currentPage = $event.pageNumber;
        this.fireLogsRequest();
    }

    private removeSpecificControls(): void {
        this.filters.removeControl('operation');
        this.filters.removeControl('action');
        this.filters.removeControl('field');
        this.filters.removeControl('user');
        this.filters.removeControl('status');
        this.filters.removeControl('module');
    }

    handleLogTypeChange(): void {
        this.removeSpecificControls();

        switch (this.logType) {
            case LogType.ImportsExports:
                this.filters.addControl('operation', new FormControl<string[]>(null), { emitEvent: false });
                break;
            case LogType.Transformers:
                this.filters.addControl('action', new FormControl<string[]>(null), { emitEvent: false });
                this.filters.addControl('field', new FormControl<string[]>(null), { emitEvent: false });
                this.filters.addControl('user', new FormControl<string[]>(null), { emitEvent: false });
                break;
            case LogType.FeedAlerts:
                this.filters.addControl('status', new FormControl<string[]>(null), { emitEvent: false });
                break;
            case LogType.Change:
                this.filters.addControl('action', new FormControl<string[]>(null), { emitEvent: false });
                this.filters.addControl('module', new FormControl<string[]>(null), { emitEvent: false });
                this.filters.addControl('user', new FormControl<string[]>(null), { emitEvent: false });
                // this.filters.addControl('changeLogFilter', new FormControl<string[]>(null), { emitEvent: false });
                break;
            default:    // Export Event Queue and EDRTS add nothing
                break;
        }

        this.buildFilterChips();
    }

    toggleFiltersCollapsed(): void {
        this.filtersCollapsed = !this.filtersCollapsed;
    }

    submitFilters(): void {
        this.filtersCollapsed = true;
        this.buildFilterChips();
        this.onSearchChange(this.query);
    }

    private buildFilterChips(): void {
        const newFilterChips: FilterChip[] = [];
        const controls = this.filters.controls;
        const values = this.filters.value;

        if (values.dateRange) {
            const startDateValue = values.dateRange.isoStartDate;
            const endDateValue = values.dateRange.isoEndDate;
            newFilterChips.push({
                label: 'Date range',
                control: controls.dateRange,
                display: this.getFormattedTimeFromNGB(startDateValue) + ' - ' + this.getFormattedTimeFromNGB(endDateValue) + ` (${this.timeZoneCode})`,
                permanent: true
            });
        }

        values.operation?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Operation',
                control: controls.operation,
                display: value
            });
        });

        values.action?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Action',
                control: controls.action,
                display: value
            });
        });

        values.field?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Field',
                control: controls.field,
                display: value
            });
        });

        values.user?.forEach((value: string) => {
            newFilterChips.push({
                label: 'User',
                control: controls.user,
                display: value
            });
        });

        values.status?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Status',
                control: controls.status,
                display: value
            });
        });

        values.module?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Module',
                control: controls.module,
                display: value
            });
        });

        /* values.changeLogFilter?.forEach((value: string) => {
            newFilterChips.push({
                label: 'Change log filter',
                control: controls.changeLogFilter,
                display: value
            });
        }); */

        this.filterChips = newFilterChips;
    }

    clearFilter(filterToClear: FilterChip): void {
        const value: unknown = filterToClear.control.value;

        if (Array.isArray(value)) {
            const index = value.indexOf(filterToClear.display);
            if (index > -1) {
                value.splice(index, 1);
                filterToClear.control.patchValue(value);
            }
        } else {
            filterToClear.control.reset();
        }

        /**
         * applyFilters in the filters component will trigger submitFilters here
         */
        this.logsFilters.applyFilters();
    }

    clearAllFilters(submit: boolean): void {
        const oldDateRange = this.filters.value.dateRange;
        this.filters.reset();
        this.filters.patchValue({
            dateRange: oldDateRange
        });

        if (submit) {
            /**
             * applyFilters in the filters component will trigger submitFilters here
             */
            this.logsFilters.applyFilters();
        } else {
            this.buildFilterChips();
        }

        const queryParams: Params = {
            query: null
        };

        // keep current route but update query params
        void this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams
            }
        );
    }

    getFormattedTimeFromAPI(dateTime: string): string {
        return this.dateTimeService.getFormattedTime(dateTime, DISPLAY_DATE_TIME_FORMAT, 'M/DD/YYYY H:mm') ??
            this.dateTimeService.getFormattedTime(dateTime, DISPLAY_DATE_TIME_FORMAT, API_DATE_TIME_FORMAT);
    }

    getFormattedTimeFromNGB(dateTime: string): string {
        return this.dateTimeService.getFormattedTime(dateTime, DISPLAY_DATE_FORMAT, NGB_DATE_FORMAT, this.dateTimeService.getTimezone());
    }

    downloadLogs(): void {
        this.fireLogsRequest(true);
    }
}
