<!-- eslint @angular-eslint/template/use-track-by-function:warn -->
<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<div
    [formGroup]="tForm"
    #formTransformer="ngForm"
    class="d-flex gap-2 align-items-start"
    [ngClass]="{'negative-offcanvas-margin': !onTransformersPage}" >
    <fdx-form-field
        *ngIf="!newTransformer"
        class="fdx-transformer-order"
        formControlName="order"
        [form]="formTransformer" >
        <fdx-input-number
            labelText="Reorder Transformer"
            [classes]="{'no-number-spinner': true, 'form-control': false}"
            [styles]="{'width': reorderWidth, 'text-align': 'right'}"
            [min]="'0'"
            [max]="maxLength"
            ngbTooltip="Reorder Transformer"
            placement="top-start" >
        </fdx-input-number>
    </fdx-form-field>

    <fdx-well
        class="flex-grow-1"
        style="width: 0;"
        [cardClasses]="wellClasses"
        [cardBodyClasses]="bodyClasses" >
        <fdx-well-header
            [classes]="headerClasses" >
            <span *ngIf="onTransformersPage && newTransformer">Add transformer</span>
            <fdx-form-field
                *ngIf="onTransformersPage && !newTransformer"
                formControlName="enabled"
                [form]="formTransformer"
                style="width: 48px;"
                [classes]="{'form-check': true, 'form-check-lg': true, 'form-switch': true}"
                [ngbTooltip]="enabledTooltip">
                <fdx-input-checkbox
                    labelText="Enable or disable transformer" >
                </fdx-input-checkbox>
            </fdx-form-field>
            <fdx-form-field
                *ngIf="!onTransformersPage"
                formControlName="currentFieldSelect"
                [classes]="{'d-flex': true, 'gap-3': true, 'fs-6': true}"
                [form]="formTransformer" >
                <fdx-label>Select variable</fdx-label>
                <fdx-input-wrapper
                    labelText="Choose Variable">
                    <ng-select
                        class="auto-grow"
                        formControlName="currentFieldSelect"
                        id="currentFieldSelect"
                        bindLabel="label"
                        [searchFn]="transformerUtilities.transformerDbFieldModelSearchFn"
                        (change)="changeFieldName()"
                        [clearable]="false"
                        placeholder="Select / search for a field" >
                        <ng-option *ngFor="let dbField of allDbFields" [value]="dbField">
                            <span [title]="dbField.title">{{ dbField.label }}</span>
                        </ng-option>
                    </ng-select>
                </fdx-input-wrapper>
            </fdx-form-field>
            <div class="d-flex align-items-center fs-6" [ngClass]="{'ms-3': onTransformersPage, 'ms-5': !onTransformersPage}">
                <div class="col-form-label p-0 me-3">Select rows to transform</div>
                <fdx-form-field
                    formControlName="forRowsSelection"
                    [classes]="{'form-check-inline': true}"
                    [form]="formTransformer" >
                    <fdx-input-radio
                        [controlId]="selectorRowOption.All"
                        [controlValue]="selectorRowOption.All" >
                    </fdx-input-radio>
                    <fdx-label>All rows</fdx-label>
                </fdx-form-field>
                <fdx-form-field
                    formControlName="forRowsSelection"
                    [classes]="{'form-check-inline': true}"
                    [form]="formTransformer" >
                    <fdx-input-radio
                        [controlId]="selectorRowOption.Some"
                        [controlValue]="selectorRowOption.Some" >
                    </fdx-input-radio>
                    <fdx-label>Some rows</fdx-label>
                </fdx-form-field>
            </div>
            <fdx-query-type-buttons
                class="ms-auto"
                [queryInputs]="queryInputs"
                [transformerInputs]="transformerInputs">
            </fdx-query-type-buttons>
        </fdx-well-header>

        <fdx-alert
            *ngIf="transformer.error"
            theme="danger"
            [body]="transformer.error"
            [dismissible]="true"
            (dismissed)="transformer.error = null">
        </fdx-alert>

        <!-- NOTE: WIP code for the new transformers page -->
        <!-- <fa-icon [icon]="sortIcon" size="lg" class="fdx-sort-icon"></fa-icon> -->

        <div class="fdx-bubble mb-3" [hidden]="!showQueryInputs">
            <div class="fdx-bubble-connect"></div>
            <div class="fdx-bubble-label">
                IF
            </div>
            <div class="fdx-bubble-content">
                <fdx-query-inputs
                    #queryInputs
                    [queryForm]="selectorForm"
                    [advancedQueryForm]="advancedSelectorGroup"
                    advancedQueryGroupName="advancedSelectorGroup"
                    advancedQueryControlName="advancedSelector"
                    [basicQueryForm]="basicSelectorGroup"
                    basicQueryGroupName="basicSelectorGroup"
                    [dbFields]="dbFields"
                    [(queryString)]="transformer.selector"
                    (queryStringChange)="checkModified()"
                    [(transformerString)]="transformer.transformer"
                    (transformerStringChange)="checkModified()">
                </fdx-query-inputs>
            </div>
        </div>

        <div class="fdx-bubble mb-3">
            <div class="fdx-bubble-label">
                <!-- NOTE: WIP code for the new transformers page -->
                <!-- {{ showQueryInputs ? 'THEN' : 'SET' }} <span class="text-primary">[{{currentField?.displayName}}]</span> {{ showQueryInputs ? '=' : 'TO' }} -->
                THEN
            </div>
            <div class="fdx-bubble-content">
                <fdx-transformer-inputs
                    #transformerInputs
                    [transformerForm]="transformerForm"
                    [advancedTransformerForm]="advancedTransformerGroup"
                    advancedTransformerGroupName="advancedTransformerGroup"
                    advancedTransformerControlName="advancedTransformer"
                    [basicTransformerForm]="basicTransformerGroup"
                    basicTransformerGroupName="basicTransformerGroup"
                    [dbFields]="dbFields"
                    [(transformerString)]="transformer.transformer"
                    (transformerStringChange)="checkModified()"
                    [queryInputs]="queryInputs" >
                </fdx-transformer-inputs>
            </div>
        </div>

        <div class="fdx-bubble">
            <div class="fdx-bubble-label">
                EXPORTS
            </div>
            <div class="fdx-bubble-content">
                <fdx-form-field
                    formControlName="exportModels"
                    [form]="formTransformer" >
                    <fdx-label [classes]="{'pt-0': true}">Select exports</fdx-label>
                    <fdx-input-wrapper>
                        <fdx-export-select
                            controlName="exportModels"
                            [formGroup]="tForm"
                            [exports]="exports"
                            [triggers]="triggers"
                            [multiple]="true" >
                        </fdx-export-select>
                    </fdx-input-wrapper>
                </fdx-form-field>
            </div>
        </div>

        <!-- NOTE: WIP code for the new transformers page -->
        <!-- <div class="fdx-col-expand d-flex align-items-start">
            <button
                class="btn btn-outline-secondary ms-auto"
                (click)="expandTransformer()"
                ngbTooltip="Stack" >
                <fa-icon [icon]="columnsIcon" class="no-margin" size="lg" transform="rotate-{{ rotation }}"></fa-icon>
            </button>
        </div> -->

        <div class="modified-dependency"
            *ngIf="shouldShowDependentFields">
            <fdx-transformers-field-dependency-tree-content
                [transformerFieldDependency]="transformerFieldDependency" >
            </fdx-transformers-field-dependency-tree-content>
            <div class="text-end">
                <button type="button" class="btn btn-outline-primary" (click)="dependenciesHidden = true">Hide this message</button>
            </div>
        </div>

        <fdx-well-footer
            [classes]="{'px-4': !onTransformersPage}" >
            <div class="d-flex gap-2"
                *ngIf="newTransformer">
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="addTransformer()"
                    [disabled]="processingAction" >
                    <fa-icon [icon]="iconBolt"></fa-icon>Add transformer
                </button>
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="clearTransformer()" >
                    Clear form
                </button>
            </div>

            <div class="d-flex gap-2"
                *ngIf="!newTransformer" >
                <button
                    type="button"
                    class="btn btn-primary"
                    (click)="editTransformer();"
                    [disabled]="processingAction" >
                    Update transformer
                </button>
                <button
                    type="button"
                    class="btn btn-link"
                    (click)="deleteTransformer()"
                    tabindex="-1">
                    Delete
                </button>
            </div>
        </fdx-well-footer>
    </fdx-well>
</div>
