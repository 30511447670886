<main class="d-flex flex-column gap-3 p-4">
    <div class="d-flex align-items-center gap-3">
        <fdx-page-title [beta]="true">{{ title }}</fdx-page-title>
        <form [formGroup]="logTypeForm">
            <fdx-form-field
                formControlName="logType"
                [classes]="{ 'log-type-dropdown': true }">
                <fdx-input-wrapper
                    labelText="Select log type">
                    <ng-select
                        formControlName="logType"
                        id="logType"
                        [items]="logTypes"
                        bindLabel="display"
                        [clearable]="false">
                    </ng-select>
                </fdx-input-wrapper>
            </fdx-form-field>
        </form>
    </div>
    <div class="card">
        <div class="d-flex align-items-center justify-content-between gap-2 py-3" [ngClass]="{'card-header': !filtersCollapsed, 'card-body': filtersCollapsed}">
            <fdx-search-bar
                class="w-100"
                [useSearchButton]="true"
                placeholder="Keyword search"
                (searchButtonClick)="onSearchChange($event)"
                [(value)]="query">
            </fdx-search-bar>

            <button
                type="button"
                class="btn btn-outline-secondary"
                [ngClass]="{'btn-toggled': !filtersCollapsed, 'btn-flagged': filtersActive}"
                (click)="toggleFiltersCollapsed()"
                [attr.aria-expanded]="!filtersCollapsed"
                aria-controls="filtersCollapse">
                <fa-icon [icon]="filterIcon" class="no-margin"></fa-icon>
            </button>
        </div>
        <fdx-logs-filters
            #filtersCollapse="ngbCollapse"
            [(ngbCollapse)]="filtersCollapsed"
            id="filtersCollapse"
            [logType]="logType"
            [filters]="filters"
            [minDate]="minDate"
            [maxDate]="maxDate"
            (submitFilters)="submitFilters()"
            (clearFilters)="clearAllFilters(false)">
        </fdx-logs-filters>
    </div>

    <fdx-filter-chips
        [filterChips]="filterChips"
        (clearFilterClick)="clearFilter($event)"
        (clearAllFiltersClick)="clearAllFilters(true)"
        [filtersCollapsed]="filtersCollapsed"
        [truncateLength]="35">
    </fdx-filter-chips>

    <div class="card">
        <div class="card-header d-flex align-items-center gap-3">
            <div class="ms-auto">
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    (click)="downloadLogs()"
                    popoverTitle="Download logs"
                    ngbPopover="Limited to 10,000 rows">
                    <fa-icon [icon]="downloadIcon" class="no-margin"></fa-icon>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <router-outlet />
        </div>
        <div class="card-footer">
            <fdx-pagination-bar
                [hidePerPageSelector]="false"
                [pageSize]="itemsPerPage"
                [recordsCount]="totalLogs"
                (pageChanged)="onPageChange($event)">
            </fdx-pagination-bar>
        </div>
    </div>
</main>
