import { Component } from '@angular/core';

@Component({
    selector: 'fdx-logs-edrts',
    templateUrl: './logs-edrts.component.html',
    styleUrls: ['./logs-edrts.component.scss']
})
export class LogsEDRTSComponent {

}
