import { Injectable } from '@angular/core';
import { API_DATE_TIME_FORMAT, API_TIMEZONE, DISPLAY_DATE_TIME_FORMAT } from '@app/core/models/constants/date-time';
import { TimeZoneService } from '@app/modules/timezone/services/time-zone.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime)


@Injectable({
    providedIn: 'root'
})
export class DateTimeService {

    constructor(
        private readonly timeZoneService: TimeZoneService
    ) {
    }

    /**
     * Converts the passed in dateTime to the new format, by default will convert from API date time format and timezone
     * @param dateTime - a valid date time string
     * @param newFormat - the date time format to convert to
     * @param currentFormat - the date time format we're converting from
     * @param currentTimezone - the timezone we're converting from
     * @returns the formatted time
     */
    getFormattedTime(dateTime: string, newFormat: string = DISPLAY_DATE_TIME_FORMAT, currentFormat: string = API_DATE_TIME_FORMAT, currentTimezone: string = API_TIMEZONE): string | null {
        if (!this.isDateTimeValid(dateTime, currentFormat)) {
            return null;
        }

        return this.convertToCurrentTimezone(dateTime, currentFormat, currentTimezone).format(newFormat);
    }

    /**
     * Converts the passed in dateTime to the API format, assuming the current format's timezone is the user's current timezone
     * @param dateTime - a valid date time string
     * @param currentFormat - the date time format we're converting from
     * @returns the time formatted for our APIs
     */
    getAPITime(dateTime: string, currentFormat: string = DISPLAY_DATE_TIME_FORMAT): string | null {
        if (!this.isDateTimeValid(dateTime, currentFormat)) {
            return null;
        }

        return this.convertToAPITimezone(dateTime, currentFormat).format(API_DATE_TIME_FORMAT);
    }

    /**
     * Checks to see if dateTime is a valid string that actually exists in the calendar year
     * This expects the YYYY-MM-DD HH:mm:ss format to be considered valid
     * @param dateTime - a valid date time string
     * @param timeFormat - a date time string format, default YYYY-MM-DD HH:mm:ss
     */
    isDateTimeValid(dateTime: string, timeFormat: string = API_DATE_TIME_FORMAT): boolean {
        return dayjs(dateTime, timeFormat, true).isValid();
    }

    /**
     * Guesses the user timezone
     * @returns the name of the timezone in a string format, such as 'America/New_York'
     */
    getTimezone(): string {
        return dayjs.tz.guess();
    }

    /**
     * Convert the date time string to the default API timezone (Eastern)
     * to the current timezone of the browser.
     * @param dateTime - a valid date time string
     * @param timeFormat - a date time string format, default MMM D, YYYY h:mm:ss A
     */
    convertToAPITimezone(dateTime: string, timeFormat: string = DISPLAY_DATE_TIME_FORMAT): dayjs.Dayjs {
        const currentTimezone = this.getTimezone();
        return dayjs.tz(dateTime, timeFormat, currentTimezone)   // parse as user's current timezone
            .tz(API_TIMEZONE)                    // format to EST timezone
    }

    /**
     * Convert the date time string from the default API timezone (Eastern)
     * to the current timezone of the browser.
     * @param dateTime - a valid date time string
     * @param timeFormat - a date time string format, default YYYY-MM-DD HH:mm:ss
     * @param timeZone - the timezone we're converting from, assume apiTimezone
     */
    convertToCurrentTimezone(dateTime: string, timeFormat: string = API_DATE_TIME_FORMAT, timeZone: string = API_TIMEZONE): dayjs.Dayjs {
        const currentTimezone = this.getTimezone();
        return dayjs.tz(dateTime, timeFormat, timeZone)   // parse as passed in timezone
            .tz(currentTimezone)                    // format to user's current timezone
    }

    /**
     * This returns the abbreviation of the current timezone (ex: EST, CST, PST, JST, etc.)
     * Note: this method guesses the timezone, and then parses the abbreviation rather than using dayjs to format the timezone
     *       this is because for timezones outside the USA (ex: dayjs will return GMT+9 instead of JST for Asia/Tokyo)
     * @returns - a string of the abbreviated timezone from the browser
     */
    getTimezoneCode(): string {
        const currentTimezone = dayjs().tz(this.getTimezone()).format('zzz');
        return currentTimezone.includes(' ')
            ? currentTimezone
                .split(' ')
                .map(([first]) => first)
                .join('')
            : currentTimezone;
    }

    /**
     * Compare two date times based on a passed in format.
     * @param dateTime1 - the first date to be compared
     * @param dateTime2 - the second date to be compared
     * @param timeFormat - the time format of both dates, default YYYY-MM-DD HH:mm:ss
     * @returns - a number dictating if dateTime1 is larger than, equal to, or less than dateTime2
     */
    comparator(dateTime1: string, dateTime2: string, timeFormat: string = API_DATE_TIME_FORMAT): number {
        const date1Number: number | null = this.isDateTimeValid(dateTime1, timeFormat) ? dayjs(dateTime1, timeFormat).unix() : null;
        const date2Number: number | null = this.isDateTimeValid(dateTime2, timeFormat) ? dayjs(dateTime2, timeFormat).unix() : null;
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }

    getDateTimeFromAPIValue(dateTime: string): dayjs.Dayjs {
        return dayjs.tz(dateTime, API_DATE_TIME_FORMAT, API_TIMEZONE);
    }

    getFormattedDateTime(time: dayjs.Dayjs): string | null {
        return `${time.format(DISPLAY_DATE_TIME_FORMAT)} ${this.timeZoneService.getAbbreviatedTimeZoneName(time)}`;
    }

    /**
     * Give a formatted difference of 2 dates (or one date and today) as per the dayjs from/from
     * now library
     * @param {string} startTime - the first date to be compared
     * @param {string} [endTime] = a second date to be compared.  If not supplied, it will be compared
     * to the current time.
     * @returns {string} A formatted difference between 2 dates or 1 date and today.
     */
    getFormattedTimeDifference(startTime: string, endTime?: string): string {
        if (!endTime) {
            return dayjs(startTime).fromNow();
        }
        return dayjs(startTime).to(dayjs(endTime));
    }

    /**
     * Converts a dayjs.Dayjs object to an NgbDateStruct
     * @param date
     * @returns the NgbDateStruct
     */
    dayJStoNgbDateStruct(date: dayjs.Dayjs): NgbDateStruct {
        return {
            year: date.year(),
            month: date.month() + 1,
            day: date.date()
        }
    }

    /**
     * Converts a dayjs.Dayjs object to an NgbDate
     * @param date
     * @returns the NgbDate
     */
    dayJSToNGBDate(date: dayjs.Dayjs): NgbDate {
        return new NgbDate(
            date.year(),
            date.month() + 1,
            date.date()
        )
    }
}
