import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME).controller('StatusController', ['$scope', '$http', '$cookies', '$filter', 'AppStateService', 'CSVService', 'fdxUI', function($scope, $http, $cookies, $filter, AppStateService, CSVService, fdxUI) {
	fdxUI.setTitle('Status Dashboard');
	fdxUI.setActiveTab('dashboards');

	$scope.data = {
		'_locked': AppStateService.getAccount().hasFeature('lock_feedstatus_dashboard', 'enabled'),
		'_loading': false,
		'_dbs': [],
		'_stats': {
			'Success_Success': 0,
			'Success_Issue': 0,
			'Issue_Success': 0,
			'Issue_Issue': 0,
			'db_row_count': 0,
			'import_types': {},
			'last_log_messages': {
				'exports': {},
				'imports': {}
			}
		},
		'_filter': {
			'import_status': [],
			'import_schedule_status': [],
			'import_log_message': [],
			'export_status': [],
			'export_schedule_status': [],
			'export_log_message': [],
			'name': ''
		},
		'_filter_options': {
			'status': [
				{
					'key': 'Success',
					'value': 'Success',
					'class': 'text-success'
				},
				{
					'key': 'Issue',
					'value': 'Issue(s)',
					'class': 'text-danger'
                }
			],
            'schedule_status': [
                {
                    'key': 'Scheduled',
                    'value': 'Scheduled',
                    'class': 'text-success'
                },
                {
                    'key': 'Triggered',
                    'value': 'Triggered',
                    'class': 'text-success'
                },
                {
                    'key': 'Unscheduled',
                    'value': 'Unscheduled',
                    'class': 'text-secondary'
                }
            ]
		},
		'apply_filter': function(filter) {
			Object.keys($scope.data._filter).forEach(function(key) {
				if (filter.hasOwnProperty(key)) {
					$scope.data._filter[key] = filter[key];
				} else {
					$scope.data._filter[key] = (key === 'name' ? '' : []);
				}
			});
		},
		'_search': function(row) {
			var found = true;

			// Check if name exists in the DB name, then check tags
			if ($scope.data._filter['name']) {
				var search_name = $scope.data._filter.name.toLowerCase();

				found = row.name.toLowerCase().includes(search_name);

				if (found === false) {
					row.exports.forEach(function(item) {
						item.tags.forEach(function(tag) {
							if (tag.value.toLowerCase().includes(search_name)) {
								found = true;
							}
						});
					});
				}
				if (found === false) {
					row.exports.forEach(function(item) {
						if (item.file_name.toLowerCase().includes(search_name)) {
							found = true;
						}
					});
				}
				if (found === false) {
					row.imports.forEach(function(item) {
						if(item.name.toLowerCase().includes(search_name)) {
							found = true;
						}
					});
				}
				if(found === false) {
					row.exports.forEach(function(item) {
						if(item.name.toLowerCase().includes(search_name)) {
							found = true;
						}
					});
				}
			}

			// If the result is found then make sure it matches the status filters
			if (found === true) {
				['import_status', 'import_schedule_status', 'export_status', 'export_schedule_status'].forEach(function (key) {
					var filter = $scope.data._filter[key];
					if (filter.length > 0 && filter.indexOf(row[key]) === -1) {
						found = false;
					}
				});
			}

			// If we still have a match then make sure the logs have the right message
			if (found === true) {
				// Check import log message
				if ($scope.data._filter.import_log_message.length > 0) {
					var found_import_log = false;

					row.imports.forEach(function(imp) {
						if (imp.last_log && $scope.data._filter.import_log_message.indexOf(imp.last_log.message) >= 0) {
							found_import_log = true;
						}
					});

					if (found_import_log === false) {
						found = false;
					}
				}

				// Check export log message
				if ($scope.data._filter.export_log_message.length > 0) {
					var found_export_log = false;

					row.exports.forEach(function(exp) {
						if (exp.last_log && $scope.data._filter.export_log_message.indexOf(exp.last_log.message) >= 0) {
							found_export_log = true;
						}
					});

					if (found_export_log === false) {
						found = false;
					}
				}
			}

			return found;
		},
		'get_dbs': function() {
			$scope.data._loading = true;

			$http.get('/api.php/accounts/' + AppStateService.getAccountId() + '/status' ).then(
				function(response) {
					$scope.data._dbs = $filter('orderBy')(response.data, 'name');

					$scope.data._dbs.forEach(function(db) {

						var key = `${db.import_status}_${db.export_status}`;
						$scope.data._stats[key]++;
						$scope.data._stats.db_row_count += parseInt(db.data_count);

						// Aggregate import types and last log messages
						db.imports.forEach(function(imp) {
							// Types
							if (!$scope.data._stats.import_types[imp.file_type]) {
								$scope.data._stats.import_types[imp.file_type] = 0;
							}

							$scope.data._stats.import_types[imp.file_type]++;

							// Messages
							if (imp.last_log && imp.last_log.message) {
								if (!$scope.data._stats.last_log_messages.imports[imp.last_log.message]) {
									$scope.data._stats.last_log_messages.imports[imp.last_log.message] = 0;
								}

								$scope.data._stats.last_log_messages.imports[imp.last_log.message]++;
							}
						});

						// Aggregate export last log messages
						db.exports.forEach(function(exp) {
							// Messages
							if (exp.last_log && exp.last_log.message) {
								if (!$scope.data._stats.last_log_messages.exports[exp.last_log.message]) {
									$scope.data._stats.last_log_messages.exports[exp.last_log.message] = 0;
								}

								$scope.data._stats.last_log_messages.exports[exp.last_log.message]++;
							}
						});
					});

					$scope.data._loading = false;
				},
				function (error) {
					const msg = (error && error.data && error.data.message) ? error.data.message : 'There was an error getting data back';

					fdxUI.showToastError(msg);
				}
			);
		},
		'get_import_types': function(db) {
			var types = {};

			db.imports.forEach(function(imp) {
				if (!types[imp.file_type]) {
					types[imp.file_type] = 0;
				}

				types[imp.file_type]++;
			});

			var types_formatted = [];
			Object.keys(types).forEach(function(type) {
				types_formatted.push(type + ' (' + types[type] + ')');
			});

			return types_formatted.join(', ');
		},
		'get_latest_log_date': function(db, item_key, log_key) {
			var newest_time = null;

			if (db && Array.isArray(db[item_key])) {
				db[item_key].forEach(function(item) {
					if (item[log_key] && item[log_key].time && (!newest_time || item[log_key].time > newest_time)) {
						newest_time = item[log_key].time;
					}
				});
			}

			if (newest_time === null) {
				if (item_key === 'exports') {
					var is_scheduled = false;

					db.exports.forEach(function(exp) {
						if (exp.cron !== null) {
							is_scheduled = true;
						}
					});

					if (is_scheduled) {
						return '<span class="text-muted">> 1 Week</span>';
					}
				} else if(item_key === 'imports' && parseInt(db.data_count) > 0) {
					return '<span class="text-muted">> 1 Week</span>';
				}

				return '<span class="text-muted">Never</span>';
			}

			return $filter('moment')(newest_time, 'MMM Do, YYYY @ h:mma') + ' EST';
		},
		'get_item_log_time': function(db, item, item_type, log_key) {
			if (item && item[log_key] && item[log_key]['time']) {
				return $filter('moment')(item[log_key]['time'], 'MMM Do, YYYY @ h:mma') + ' EST';
			}

			if (item_type === 'export' && item.cron !== null) {
				return '<span class="text-muted">> 1 Week</span>';
			} else if(item_type === 'import' && parseInt(db.data_count) > 0) {
				return '<span class="text-muted">> 1 Week</span>';
			}

			return '<span class="text-muted">Never</span>';
		}
	};

	$scope.toggle = function(obj, attr, val) {
		obj[attr] = val;
	};

	// Initialize page data if not locked
	if (!$scope.data._locked) {
		$scope.data.get_dbs();
	}

	$scope.download_in_progress = false;

	$scope.download_detailed_report = function(dbs){
		$scope.download_in_progress = true;
		var rows = [];
		rows.push([
			'db_id',
			'db_name',
			'db_group_name',
			'db_row_count',
			'import_id',
			'import_name',
			'import_join_type',
			'import_file_type',
			'import_file_location',
			'import_status',
			'import_schedule',
			'import_load_threshold',
			'import_last_log_message',
			'import_last_log_time',
			'import_last_successful_log_message',
			'import_last_successful_log_time',
			'export_id',
			'export_name',
			'export_filename',
			'export_tags',
			'export_status',
			'export_schedule',
			'export_threshold',
			'export_selector',
			'export_dgqs',
			'export_last_log_message',
			'export_last_log_time',
			'export_last_successful_log_message',
			'export_last_successful_log_time'
		]);

		dbs.forEach(function(db){
			db.imports.forEach(function(imprt){
				rows.push([
					db.id,
					db.name,
					db.db_group_name,
					db.data_count,
					imprt.id,
					imprt.name,
					imprt.join_type,
					imprt.file_type,
					imprt.file_location,
					imprt.status,
					imprt.cron,
					imprt.load_threshold,
					$filter('logMessage')(imprt.last_log, (imprt.file_location === 'local' ? 'File manually imported' : 'No import operations performed')).replace(/<[^>]*>?/gm, ''),
					(imprt.last_log.hasOwnProperty('time') ? imprt.last_log.time : ''),
					$filter('logMessage')(imprt.last_successful_log, (imprt.file_location === 'local' ? 'File manually imported' : 'No import operations performed')).replace(/<[^>]*>?/gm, ''),
					(imprt.last_successful_log.hasOwnProperty('time') ? imprt.last_successful_log.time : ''),
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]);
			});
			db.exports.forEach(function(exprt){
				var tags = [];
				exprt.tags.forEach(function(tag) {
					tags.push([tag.tag + ': ' + tag.value]);
				});
				tags = tags.length > 1 ? tags.join(', ') : '';

				rows.push([
					db.id,
					db.name,
					db.db_group_name,
					db.data_count,
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					exprt.id,
					exprt.name,
					exprt.file_name,
					tags,
					exprt.status,
					exprt.cron,
					exprt.threshold,
					exprt.export_selector,
					$filter('number')(exprt.dgqs.length, 0),
					$filter('logMessage')(exprt.last_log, 'No export operations performed').replace(/<[^>]*>?/gm, ''),
					(exprt.last_log.hasOwnProperty('time') ? exprt.last_log.time : ''),
					$filter('logMessage')(exprt.last_successful_log, 'No export operations performed').replace(/<[^>]*>?/gm, ''),
					(exprt.last_successful_log.hasOwnProperty('time') ? exprt.last_successful_log.time : '')
				]);
			});

			// print row for an empty db
			if(!db.imports.hasOwnProperty(0) && !db.exports.hasOwnProperty(0)){
				rows.push([
					db.id,
					db.name,
					db.db_group_name,
					db.data_count,
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					'',
					''
				]);
			};
		});

		CSVService.download('FeedStatusDetailedReport_' + moment().format('DD-MM-YYYY-hhmmss').toString() + '.csv', rows);
		$scope.download_in_progress = false;
	}

	$scope.download_simple_report = async function(dbs){
		$scope.download_in_progress = true;

		let feed_alerts_map = {};

		const response = await $http.get('/api.php/accounts/' + AppStateService.getAccountId() + '/feed_alerts_dashboard')
			.catch(() => {
				// Error but thats fine
			});

		if (response && response.data) {
			feed_alerts_map = response.data.reduce(
				(obj, item) => Object.assign(obj, { [item.db_id]: item.message }), {}
			);
		}

		const rows = [];
		rows.push([
			'FeedStatus Report'
		]);
		rows.push([
			'Account Name',
			AppStateService.getAccount().account_name,
		]);
		rows.push([
			'Date',
			moment().format('MMMM Do YYYY').toString(),
		]);
		rows.push([
			'',
		]);
		rows.push([
			'Database',
			'Group Name',
			'DB Count',
			'Import Status',
			'Export Status',
			'Feed Alerts',
			'Notes',
		]);

		dbs.forEach(function(db){
			rows.push([
				db.name,
				db.db_group_name,
				db.data_count,
				db.import_status,
				db.export_status,
				db.id in feed_alerts_map ? $scope.describe_threshold(feed_alerts_map[db.id]) : "Not Enabled"
			]);
		});

		CSVService.download('FeedStatusSimpleReport_' + moment().format('DD-MM-YYYY-hhmmss').toString() + '.csv', rows);
		$scope.download_in_progress = false;
	}

	$scope.describe_threshold = function (message) {
		switch (message) {
			case 'threshold_passed':
				return 'Passed';
			case 'threshold_failed':
				return 'Failed';
			case 'google_exception_thrown':
			case 'google_service_exception_thrown':
				return 'Exception Thrown';
		}
	}

	$scope.tagsToHtmlList = function(tags) {
		let html = "'<ul>";
		tags.forEach( function(tag) {
			html += '<li><span class="bold">' + tag.tag + ':</span> ' + tag.value + '</li>';
		});
		html += "</ul>'";
		return html;
	}

    $scope.getClass= (status) => {

        const classes = {
            Success: 'text-success',
            Triggered: 'text-info',
            Scheduled: 'text-info',
            Unscheduled: 'text-secondary',
            Issue: 'text-danger'
        }

        return classes[status];


    }

}]);
