<ng-template #input>
    <input
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        (blur)="onTouched()"
        [disabled]="disabled"
        [ngClass]="inputClasses"
        [ngStyle]="styles"
        type="number"
        [id]="id"
        [name]="controlName"
        [attr.title]="title ? title : null"
        [attr.placeholder]="placeholder ? placeholder: null"
        [attr.aria-label]="ariaLabel ? labelText: null"
        [attr.aria-describedby]="ariaDescribedBy ? ariaDescribedBy : null"
        [attr.readonly]="readonly ? readonly : null"
        [attr.data-lpignore]="!autocomplete ? 'true' : null"
        [attr.autocomplete]="!autocomplete ? 'off' : null"
        [attr.inputmode]="inputmode ? inputmode: null"

        [attr.step]="step ? step : null"

        #inputElement

        [attr.pattern]="control.errors?.['pattern'] ? control.errors.pattern.requiredPattern : null"
        [attr.min]="minValue"
        [attr.max]="maxValue"
        [attr.required]="control.errors?.['required'] ? 'required' : null" />
</ng-template>

<ng-container *ngIf="isInputGroup; else notInputGroup">
    <div
        fdxInputGroupAddons
        [leftAddons]="leftAddons"
        [input]="input"
        [rightAddons]="rightAddons"
        [validation]="validation">
    </div>
</ng-container>

<ng-template #notInputGroup>
    <ng-container *ngTemplateOutlet="input"></ng-container>
    <ng-container *ngTemplateOutlet="validation"></ng-container>
</ng-template>
<ng-template #validation>
    <fdx-validation [control]="control"
        [labelText]="labelText"
        [customValidationText]="customValidationText"
        [submitted]="form?.submitted"
        [ngClass]="{'invalid-feedback': !tooltipValidation, 'invalid-tooltip': tooltipValidation}"
    ></fdx-validation>
</ng-template>
<ng-template #copyButton>
    <ng-container *ngIf="showCopyButton">
        <ng-template #tipContent let-text="text">{{text}}</ng-template>
        <button
            class="btn btn-outline-secondary"
            type="button"
            [attr.id]="ariaDescribedBy ? ariaDescribedBy : null"
            (click)="inputsUtilitiesService.copyToClipboard(this, tip)"
            [ngbTooltip]="tipContent"
            triggers="manual"
            #tip="ngbTooltip">
            <fa-icon [icon]="iconCopy" class="no-margin"></fa-icon>
        </button>
    </ng-container>
</ng-template>
