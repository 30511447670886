<div class="d-flex gap-3 align-items-center">
    <div class="d-flex align-items-center justify-content-center gap-2">
        <span>Rows per page:</span>
        <ng-select
            id="pageSize"
            [(ngModel)]="rowCountLimit"
            [clearable]="false"
            [disabled]="disableDropdown"
            (change)="updateSelection()">
            <ng-option value="25">25</ng-option>
            <ng-option value="50">50</ng-option>
            <ng-option value="100">100</ng-option>
            <ng-option value="500">500</ng-option>
        </ng-select>
        <button
            type="button"
            name="previous"
            class="btn btn-outline-secondary"
            (click)="prev()"
            [disabled]="disablePrev">
            <fa-icon
                [icon]="prevIcon"
                class="no-margin"></fa-icon>
            <span class="visually-hidden">Load previous page content</span>
        </button>
        <button
            type="button"
            name="next"
            class="btn btn-outline-secondary"
            (click)="next()"
            [disabled]="disableNext">
            <fa-icon
                [icon]="nextIcon"
                class="no-margin"></fa-icon>
            <span class="visually-hidden">Load next page content</span>
        </button>
    </div>
    <div
        *ngIf="showRowCount"
        id="rowCount"
        class="text-center fw-semibold">
        {{ rowCount | number }} total {{ rowText }}
    </div>
</div>
