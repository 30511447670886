import { Component } from '@angular/core';

@Component({
    selector: 'fdx-logs-change',
    templateUrl: './logs-change.component.html',
    styleUrls: ['./logs-change.component.scss']
})
export class LogsChangeComponent {

}
