import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[fdxStopPropagation]'
})
export class StopPropagationDirective {
    @HostListener('click', ['$event'])
    onClick(event: Event): void {
        event.stopPropagation();
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: Event): void {
        event.stopPropagation();
    }
}
