import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule } from '@angular/router';
import { AccountAndDatabaseGuard } from '@app/core/guards/account-and-database.guard';
import { RouteParam } from '@app/core/guards/enums/route-param.enum';
import { LogsChangeComponent } from '@app/logs/components/logs-change/logs-change.component';
import { LogsEDRTSComponent } from '@app/logs/components/logs-edrts/logs-edrts.component';
import { LogsExportEventQueueComponent } from '@app/logs/components/logs-export-event-queue/logs-export-event-queue.component';
import { LogsFeedAlertsComponent } from '@app/logs/components/logs-feed-alerts/logs-feed-alerts.component';
import { LogsImportsExportsComponent } from '@app/logs/components/logs-imports-exports/logs-imports-exports.component';
import { LogsTransformersComponent } from '@app/logs/components/logs-transformers/logs-transformers.component';
import { LOG_TYPE_MAP } from '@app/logs/constants/logs.constants';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogsPageComponent } from '@app/logs/pages/logs-page/logs-page.component';
import { ReusableRoute } from '@app/route-reuse-strategy.class';

const defaultRedirect = LOG_TYPE_MAP.get(LogType.ImportsExports).route;

const routes: ReusableRoute[] = [
    {
        path: '',
        component: LogsPageComponent,
        canActivate: [
            AccountAndDatabaseGuard
        ],
        data: {
            /**
             * If the `:database_id` route param is the same then reuse the route even
             * if the `:type` route param has changed.
             * @param a future route
             * @param b current route
             * @returns boolean
             */
            shouldReuseRoute: (a: ActivatedRouteSnapshot, b: ActivatedRouteSnapshot): boolean => {
                return a.paramMap.get(RouteParam.DatabaseId) === b.paramMap.get(RouteParam.DatabaseId);
            }
        },
        children: [
            {
                path: defaultRedirect,
                component: LogsImportsExportsComponent
            },
            {
                path: LOG_TYPE_MAP.get(LogType.Transformers).route,
                component: LogsTransformersComponent
            },
            {
                path: LOG_TYPE_MAP.get(LogType.FeedAlerts).route,
                component: LogsFeedAlertsComponent
            },
            {
                path: LOG_TYPE_MAP.get(LogType.Change).route,
                component: LogsChangeComponent
            },
            {
                path: LOG_TYPE_MAP.get(LogType.ExportEventQueue).route,
                component: LogsExportEventQueueComponent
            },
            {
                path: LOG_TYPE_MAP.get(LogType.EDRTS).route,
                component: LogsEDRTSComponent
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: defaultRedirect
            },
            {
                path: '**',
                redirectTo: defaultRedirect
            }
        ]
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LogsRoutingModule { }
