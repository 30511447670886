/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable angular/controller-as-route */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { StateProvider, UrlRouterProvider } from '@uirouter/angularjs';
import { ILocationProvider } from 'angular';

function routesConfig($stateProvider: StateProvider, $urlRouterProvider: UrlRouterProvider, $locationProvider: ILocationProvider): void {

    $locationProvider.hashPrefix('');

    $stateProvider

        .state('app', {
            abstract: true,
            template: require('../layouts/base.html'),
            controller: 'MainController'
        })

        // moved to Angular router
        .state('app.automate-build-page', {
            url: '/:id/automate-build',
            template: ''
        })

        // moved to Angular router
        .state('app.ag-grid-demo-page', {
            url: '/demos/ag-grid',
            template: ''
        })

        // moved to Angular router
        .state('app.bootstrap-demo-page', {
            url: '/demos/bootstrap',
            template: ''
        })

        // moved to Angular router
        .state('app.inputs-demo-page', {
            url: '/demos/inputs',
            template: ''
        })

        // moved to Angular router - Old Route
        .state('app.databases', {
            url: '/databases',
            template: ''
        })

        // moved to Angular router - New Route
        .state('app.acct-databases', {
            url: '/:account_id/databases',
            template: ''
        })

        .state('app.ebay-selection', {
            url: '/ebay-selection',
            template: '<fdx-ebay-selection></fdx-ebay-selection>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            },
            data: {
                allowEbay: true,
                hideMenuDatabaseName: true
            }
        })

        // moved to Angular router
        .state('app.accounts', {
            url: '/accounts',
            template: '',
            data: {
                allowEbay: true
            }
        })

        // moved to Angular router
        .state('app.profile', {
            url: '/profile',
            template: ''
        })

        // moved to Angular router
        .state('app.keyboard-shortcuts', {
            url: '/keyboard-shortcuts',
            template: ''
        })

        // redirect route
        .state('app.keyboard_shortcuts', {
            url: '/keyboard_shortcuts',
            template: ''
        })

        // moved to Angular router
        .state('app.cheat_sheet', {
            url: '/cheat_sheet',
            template: ''
        })

        // stub for new url on Angular router
        .state('app.cheat_sheet_new', {
            url: '/help/cheat-sheet',
            template: ''
        })

        // Old route
        .state('app.feed_alerts_dashboard', {
            url: '/feed_alerts_dashboard',
            template: require('../dashboard_feed_alerts/feed_alerts.html'),
            controller: 'FeedAlertsController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            }
        })

        // New route
        .state('app.acct-feed_alerts_dashboard', {
            url: '/:account_id/feed_alerts_dashboard',
            template: require('../dashboard_feed_alerts/feed_alerts.html'),
            controller: 'FeedAlertsController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            }
        })

        .state('app.account-feed-alerts-dashboard', {
            url: '/:account_id/feed-alerts-dashboard',
            template: ''
        })

        // Old route
        .state('app.status', {
            url: '/status',
            template: require('../dashboard_status/status.html'),
            controller: 'StatusController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            }
        })

        // New route
        .state('app.acct-status', {
            url: '/:account_id/status',
            template: require('../dashboard_status/status.html'),
            controller: 'StatusController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            }
        })

        // Moved to Angular router
        .state('app.billing', {
            url: '/billing',
            template: ''
        })

        // Moved to Angular router
        .state('app.release_notes', {
            url: '/release-notes',
            template: ''
        })

        // redirect route
        .state('app.release_notes_redirect', {
            url: '/release_notes',
            template: ''
        })

        // Old route
        .state('app.feedsupport', {
            url: '/feedsupport',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true,
                lowerToast: true
            }
        })

        // New route
        .state('app.acct-feedsupport', {
            url: '/:account_id/feedsupport',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true,
                lowerToast: true
            }
        })

        // Old route
        .state('app.feedsupport_navigation_tab', {
            url: '/feedsupport/:fs_navigation_tab',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true
            }
        })

        // New route
        .state('app.acct-feedsupport_navigation_tab', {
            url: '/:account_id/feedsupport/:fs_navigation_tab',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true
            }
        })

        // Old route
        .state('app.feedsupport_single_ticket_view', {
            url: '/feedsupport/:fs_navigation_tab/:fs_ticket_number',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true
            }
        })

        // New Route
        .state('app.acct-feedsupport_single_ticket_view', {
            url: '/:account_id/feedsupport/:fs_navigation_tab/:fs_ticket_number',
            template: require('../feedsupport/feedsupport.html'),
            controller: 'FeedsupportController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'stores'], $stateParams)]
            },
            data: {
                allowEbay: true
            }
        })

        // moved to Angular router - Old Route
        .state('app.users', {
            url: '/users',
            data: {
                hideMenuDatabaseName: true
            },
            template: ''
        })

        // moved to Angular router - New Route
        .state('app.acct-users', {
            url: '/:account_id/users',
            data: {
                hideMenuDatabaseName: true
            },
            template: ''
        })

        .state('app.feedwizard_wizard', {
            url: '/feedwizard/:wizard',
            template: require('../feedwizard/feedwizard.html'),
            controller: 'FeedWizardController',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account'], $stateParams)]
            },
            params: {
                wizard: { value: null, squash: true }
            }
        })

        // moved to Angular router
        .state('app.imports-legacy', {
            url: '/:id/imports/legacy',
            template: ''
        })

        // moved to Angular router
        .state('app.imports', {
            url: '/:id/imports',
            template: ''
        })

        // moved to Angular router
        .state('app.imports-list', {
            url: '/:id/imports/list',
            template: '',
            params: {
                section: { squash: true, value: null }
            }
        })

        // moved to Angular router
        .state('app.imports-new', {
            url: '/:id/imports/new/:section',
            template: '',
            params: {
                section: { squash: true, value: null }
            }
        })

        // moved to Angular router
        .state('app.imports-edit-data-source', {
            url: '/:id/imports/:import_id/edit-data-source',
            template: ''
        })

        // moved to Angular router
        .state('app.imports-edit-import-type', {
            url: '/:id/imports/:import_id/edit-import-type',
            template: ''
        })

        // moved to Angular router
        .state('app.imports-new-source-settings', {
            url: '/:id/imports/new/source-settings',
            template: ''
        })

        // moved to Angular router
        .state('app.imports-edit-source-settings', {
            url: '/:id/imports/edit/:import_id/source-settings',
            template: ''
        })

        // moved to Angular router
        .state('app.imports-details', {
            url: '/:id/imports/:import_id/details?section',
            template: '',
            params: {
                section: { squash: true, value: null },
                import_id: { dynamic: true, squash: true, value: null }
            }
        })

        // redirects to ftp-triggers in the Angular router
        .state('app.ftp_triggers', {
            url: '/:id/ftp_triggers',
            template: ''
        })

        // moved to Angular router
        .state('app.ftp-triggers', {
            url: '/:id/ftp-triggers',
            template: ''
        })

        // redirects to ftp-account Angular router
        .state('app.ftp_account', {
            url: '/:id/ftp_account',
            template: ''
        })

        // moved to Angular router
        .state('app.ftp-account', {
            url: '/:id/ftp-account',
            template: ''
        })

        .state('app.file_maps', {
            url: '/:id/file_maps?import_id',
            template: require('../file_maps/file_maps.html'),
            controller: 'FileMapsController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            },
            params: {
                import_id: { value: null, squash: true }
            }
        })

        // moved to Angular router
        .state('app.file-maps', {
            url: '/:id/file-maps',
            template: ''
        })

        // moved to Angular router
        .state('app.extra-fields', {
            url: '/:id/extra-fields',
            template: ''
        })

        // moved to Angular router
        .state('app.data', {
            url: '/:id/data/:export_id?query&analyze&column_1&column_2&channel&country&cs_search&cs_filter&cs_sort_1&cs_sort_2&ic_sort&dv_condition&dv_issue&dv_search&dv_filtered_rows&column_state',
            template: '',
            data: {
                allowEbay: true
            }
        })

        // redirects to data in the Angular router
        .state('app.data-new', {
            url: '/:id/data-new/:export_id?query&analyze&column_1&column_2&channel&country&cs_search&cs_filter&cs_sort_1&cs_sort_2&ic_sort&dv_condition&dv_issue&dv_search&dv_filtered_rows&column_state',
            template: ''
        })

        // redirects to data in the Angular router
        .state('app.data-without-export', {
            url: '/:id/data',
            template: ''
        })

        // redirects to data in the Angular router
        .state('app.data-new-without-export', {
            url: '/:id/data-new',
            template: ''
        })

        // redirects to data in the Angular router
        .state('app.transformed_data', {
            url: '/:id/transformed_data/:export_id?query',
            template: ''
        })

        // redirects to data in the Angular router
        .state('app.transformed_data_ebay', {
            url: '/:id/transformed_data_ebay/:export_id?query',
            template: ''
        })

        // moved to Angular router
        .state('app.channel-listings', {
            url: '/:id/channel-listings?query&channel&sku&posted&processed&productStatus&name&inventoryMin&inventoryMax&priceMin&priceMax&errors&warnings',
            template: '',
            reloadOnSearch: false
        })

        // moved to Angular router
        .state('app.export-runs', {
            url: '/:id/export-runs?channel',
            template: '',
            reloadOnSearch: false
        })

        .state('app.transformers-new', {
            url: '/:id/transformers-new/:field_name',
            template: '<fdx-style-wrapper><fdx-transformers-page></fdx-transformers-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            },
            params: {
                field_name: { value: null, squash: true }
            }
        })

        .state('app.transformers', {
            url: '/:id/transformers/:field_name',
            template: require('../transformers/transformers-old.html'),
            controller: 'TransformersControllerOld',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            },
            params: {
                field_name: { value: null, squash: true }
            }
        })

        .state('app.transformers_bulk_import', {
            url: '/:id/transformers_bulk_import',
            template: '<fdx-style-wrapper><fdx-transformers-bulk-import-page></fdx-transformers-bulk-import-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        .state('app.transformers_field_order', {
            url: '/:id/transformers_field_order',
            template: '<fdx-style-wrapper><fdx-transformers-field-order-page></fdx-transformers-field-order-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        // redirects to data-overrides in the Angular router
        .state('app.transformed_data_overrides', {
            url: '/:id/transformed_data_overrides',
            template: '',
            reloadOnSearch: false,
            data: {
                allowEbay: true
            }
        })

        // moved to Angular router
        .state('app.data_overrides_new', {
            url: '/:id/data-overrides',
            template: '',
            reloadOnSearch: false,
            data: {
                allowEbay: true
            }
        })

        // moved to Angular router
        .state('app.data_overrides_transactions', {
            url: '/:id/data-overrides/transactions',
            template: ''
        })

        .state('app.error_overview', {
            url: '/:id/error-overview',
            template: ''
        })

        // moved to Angular router
        .state('app.error_overview_redirect', {
            url: '/:id/error_overview',
            template: ''
        })

        // moved to Angular router
        .state('app.error-resolution', {
            url: '/:id/error-resolution?query&channel&column_state',
            template: ''
        })

        // Redirects to error-resolution in the angular router
        .state('app.error_resolution_data', {
            url: '/:id/error_resolution_data?query&channel',
            template: ''
        })

        // moved to Angular router
        .state('app.exports', {
            url: '/:id/exports?export_id&scroll',
            template: '',
        })

        .state('app.exports-old', {
            url: '/:id/exports-old?export_id',
            redirectTo: 'app.exports'
        })

        // moved to Angular router
        .state('app.exports-list', {
            url: '/:id/exports/list',
            template: ''
        })

        // moved to Angular router
        .state('app.export_triggers_redirect', {
            url: '/:id/export-triggers',
            template: ''
        })

        // moved to Angular router
        .state('app.export_triggers', {
            url: '/:id/export_triggers',
            template: ''
        })

        // moved to Angular router
        .state('app.logs-new', {
            url: '/:id/logs-new/:type?query',
            template: '',
            params: {
                type: { value: null, squash: true }
            }
        })

        .state('app.logs', {
            url: '/:id/logs?log_type',
            template: require('../logs/logs.html'),
            controller: 'LogsController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            },
            params: {
                log_type: { value: null, squash: true }
            }
        })

        .state('app.vault', {
            url: '/:id/vault',
            template: ''
        })

        .state('app.reports_authorization', {
            url: '/:id/reports-authorization/:channel/:subtab?access_token&user_id&cms_id',
            template: '',
            params: {
                channel: { value: null, squash: true },
                subtab: { value: null, squash: true }
            }
        })

        .state('app.reports_authorization_redirect', {
            url: '/:id/reports_authorization/:channel/:subtab?access_token&user_id&cms_id',
            template: '',
            params: {
                channel: { value: null, squash: true },
                subtab: { value: null, squash: true }
            }
        })

        .state('app.reports_sku', {
            url: '/:id/reports_sku',
            template: '<fdx-style-wrapper><fdx-reports-sku-page></fdx-reports-sku-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        // redirects to /qa/feedgrader in the Angular router
        .state('app.feedgrader', {
            url: '/:id/feedgrader',
            template: ''
        })

        // moved to Angular router
        .state('app.qa-feedgrader', {
            url: '/:id/qa/feedgrader',
            template: ''
        })

        // redirects to /qa/feedqa in the Angular router
        .state('app.feedqa', {
            url: '/:id/feedqa',
            template: ''
        })

        // moved to Angular router
        .state('app.qa-feedqa', {
            url: '/:id/qa/feedqa',
            template: ''
        })

        // moved to Angular router
        .state('app.comments', {
            url: '/:id/comments',
            template: ''
        })

        // .state('app.insights', {
        //     url: '/:id/insights',
        //     template: '<fdx-style-wrapper><fdx-feedtelligence-page></fdx-feedtelligence-page></fdx-style-wrapper>',
        //     reloadOnSearch: false,
        //     resolve: {
        //         resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
        //     }
        // })

        // moved to Angular router
        .state('app.alerts', {
            url: '/:id/alerts',
            reloadOnSearch: false
        })

        // redirect to map-field
        .state('app.map_field', {
            url: '/:id/map_field',
            template: ''
        })

        // moved to Angular router
        .state('app.map-field', {
            url: '/:id/map-field',
            template: ''
        })

        // moved to Angular router
        .state('app.category_taxonomy_redirect', {
            url: '/:id/category_taxonomy?taxonomy&taxonomy_name&query&category&page&attribute&view_values',
            template: ''
        })

        // moved to Angular router
        .state('app.category-taxonomy', {
            url: '/:id/category-taxonomy?taxonomy&taxonomy_name&query&category&page&attribute&view_values',
            template: ''
        })

        // moved to Angular router
        .state('app.data_governance_redirect', {
            url: '/:id/data-governance?export_id',
            template: ''
        })

        // moved to Angular router
        .state('app.data_governance', {
            url: '/:id/data_governance?export_id',
            template: ''
        })

        .state('app.feedai', {
            url: '/:id/feedai',
            template: '<fdx-style-wrapper><fdx-feed-ai-page></fdx-feed-ai-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        .state('app.diff_tool', {
            url: '/:id/diff_tool',
            template: require('../diff_tool/diff_tool.html'),
            controller: 'DiffToolController',
            reloadOnSearch: false,
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        // moved to Angular router
        .state('app.summary_governance', {
            url: '/:id/summary-governance',
            template: ''
        })

        // redirect for old snake cased route
        .state('app.summary_governance_redirect', {
            url: '/:id/summary_governance',
            template: ''
        })

        // moved to Angular router
        .state('app.store_summary_report_redirect', {
            url: '/:id/store_summary_report',
            template: ''
        })

        // moved to Angular router
        .state('app.store_summary_report', {
            url: '/:id/store-summary-report',
            template: ''
        })

        .state('app.export_random_sampling_redirect', {
            url: '/:id/export-random-sampling?export_id',
            template: ''
        })

        // moved to Angular router
        .state('app.export_random_sampling', {
            url: '/:id/export_random_sampling?export_id',
            template: ''
        })

        .state('app.classifier', {
            url: '/:id/classifier',
            template: '<fdx-style-wrapper><fdx-classifier-page></fdx-classifier-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        .state('app.attributor', {
            url: '/:id/attributor',
            template: '<fdx-style-wrapper><fdx-attributor-page></fdx-attributor-page></fdx-style-wrapper>',
            resolve: {
                resolvedData: ['$stateParams', 'ResolversService', ($stateParams, ResolversService) => ResolversService.resolve(['user', 'account', 'database'], $stateParams)]
            }
        })

        .state('app.auth_redirect', {
            url: '/auth_redirect?redirect?endpoint'
        })

        // moved to Angular router
        .state('app.utilities', {
            url: '/utilities',
            template: ''
        })

        // moved to Angular router
        .state('app.genai', {
            url: '/:id/genai',
            template: ''
        })
        // moved to Angular router
        .state('app.genai-categorization', {
            url: '/:id/genai/categorization',
            template: ''
        })
        .state('app.genai-attributes', {
            url: '/:id/genai/attributes',
            template: ''
        });
    $urlRouterProvider
        .otherwise('/databases');

}

routesConfig.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider'];

export default routesConfig;
