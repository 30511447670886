import AppStateService from '@ajs/services/AppStateService';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil } from 'rxjs';

interface DropdownOption {
    display: string;
    value: string;
}

interface SortableFieldOption extends DropdownOption {
    source: string;
}

@Component({
    selector: 'fdx-export-options-form',
    styleUrls: ['export-options-form.component.scss'],
    templateUrl: 'export-options-form.component.html'
})
export class ExportOptionsFormComponent extends BaseExportFormComponent implements OnChanges, OnInit {
    @Input() dbSortableFields: { name: string; source: string; value: string; }[];
    @Input() exportFields: { field_name: string; export_field_name: string; }[];
    @Input() exportItem: ExistingExport;
    @Output() readonly exportOptionsChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    delimiterOptions: DropdownOption[] = [
        { display: 'tab', value: 'tab' },
        { display: 'comma', value: 'comma' },
        { display: 'pipe', value: 'pipe' },
        { display: 'semicolon', value: 'semicolon' }
    ];
    exportEncodingOptions: DropdownOption[] = [
        { display: 'None', value: '' },
        { display: 'ASCII', value: 'ascii' },
        { display: 'ISO-8859-1', value: 'ISO-8859-1' }
    ];
    exportFormatOptions: DropdownOption[] = [
        { display: 'Delimited', value: 'delimited' },
        { display: 'Custom XML', value: 'xml' },
        { display: 'JSON', value: 'json' },
        { display: 'NDJSON', value: 'ndjson' },
        { display: 'Custom XML (Item Grouping)', value: 'xml_item_group' },
        { display: 'JSON (Item Grouping)', value: 'json_item_group' },
        { display: 'NDJSON (Item Grouping)', value: 'ndjson_item_group' },
        { display: 'Amazon - Inventory and Price', value: 'amazon_inventory_and_price' },
        { display: 'Amazon - Inventory Loader', value: 'amazon_inventory_loader' },
        { display: 'Amazon - Product Feed', value: 'amazon_product_feed' },
        { display: 'Amazon - Shipping Feed', value: 'amazon_shipping_feed' },
    ];
    iconHelp = faQuestionCircle;
    itemGroupIDOptions: SortableFieldOption[];
    jsonMinifyTypeOptions: DropdownOption[] = [
        { display: 'Full Minification', value: 'full' },
        { display: 'New Line', value: 'new_line' },
        { display: 'Pretty Print', value: 'pretty_print' }
    ];
    deltaExportOptions: DropdownOption[] = [
        { display: 'Off', value: '0' },
        { display: 'Update and delete', value: '2' },
        { display: 'Update only', value: '1' },
        { display: 'Delete only', value: '3' },
    ];
    dedupeFieldOptions: SortableFieldOption[] = [];
    sortableFieldOptions: SortableFieldOption[] = [
        { display: 'Default Sort', source: '', value: '' }
    ];
    stripCharacterOptions = [
        { display: '\\r', value: '\r' },
        { display: '\\n', value: '\n' },
        { display: '\\t', value: '\t' }
    ];

    get disableRowOrder(): boolean {
        return !this.form?.controls.rowSort.value;
    }

    get disableDeduplicate(): boolean {
        return this.disableSortColumn;
    }

    get disableSortColumn(): boolean {
        return this.exportFields?.length < 2;
    }

    get isExportFormatDelimited(): boolean {
        return this.form?.controls.exportFormat.value === 'delimited';
    }

    get isExportFormatXml(): boolean {
        return this.form?.controls.exportFormat.value === 'xml';
    }

    get canEditDeltaExport(): boolean {
        const user = this.appStateService.getUser();
        const account = this.appStateService.getAccount();

        return this.appStateService.isPrivacyLevelAtLeastAdmin()
            || user?.hasFeature('delta_exports', 'enabled')
            || account?.hasFeature('delta_exports', 'enabled');
    }

    get showItemGroupID(): boolean {
        return this.form?.controls.exportFormat.value === 'json_item_group' ||
            this.form?.controls.exportFormat.value === 'xml_item_group' ||
            this.form?.controls.exportFormat.value === 'ndjson_item_group';
    }

    get showJsonMinifyType(): boolean {
        return this.form?.controls.exportFormat.value === 'json';
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnChanges({ exportItem, exportFields, dbSortableFields }: SimpleChanges) {
        super.ngOnChanges({ exportItem });

        if (exportFields?.currentValue && dbSortableFields?.currentValue) {
            this.sortableFieldOptions = this.extractSortableFieldOptions(exportFields.currentValue);
            this.extractItemGroupIDOptions(dbSortableFields.currentValue, exportFields.currentValue);
        }
        if (exportItem?.previousValue && exportItem?.currentValue?.id !== exportItem?.previousValue?.id) {
            this.sortableFieldOptions = this.extractSortableFieldOptions(exportFields.currentValue);
            this.extractItemGroupIDOptions(this.dbSortableFields, exportFields.currentValue);
        }
    }

    boolean(x: unknown): boolean {
        if (typeof x === 'string' && x === '0') {
            return false;
        }

        return !!x;
    }

    booleanToStringDigit(bool: boolean): '1' | '0' {
        return bool ? '1' : '0';
    }

    parseRowLimit(rowLimit: string | number) {
        return rowLimit === null || +rowLimit === 0 ? null : rowLimit;
    }

    extractStringDigitsFromBooleans(obj: Record<string, unknown>, keys: string[]): Record<string, '1' | '0'> {
        return keys.reduce((result, key) => {
            const val = obj[key];

            if (typeof val === 'boolean') {
                result[key] = this.booleanToStringDigit(val);
            }

            return result;
        }, {});
    }

    extractItemGroupIDOptions(dbSortableFields, exportFields): DropdownOption[] {
        this.itemGroupIDOptions = [];
        const itemGroupIDOptionNames = [];

        exportFields.forEach((field) => {
            if (field.export_field_name) {
                this.itemGroupIDOptions.push({
                    'display': field.export_field_name,
                    'value': field.export_field_name,
                    'source': "Fields from Export Map"
                });

                // build names map
                itemGroupIDOptionNames.push(field.export_field_name);
            }
        });

        dbSortableFields.forEach((field) => {
            if (!itemGroupIDOptionNames.includes(field.name)) {
                this.itemGroupIDOptions.push({
                    'display': field.name,
                    'value': field.value,
                    'source': "Fields from File Map"
                });
            }
        });

        // sort by value
        const res = this.itemGroupIDOptions.sort((a, b) => {
            const aVal = a.value.toLowerCase();
            const bVal = b.value.toLowerCase();
            return aVal.localeCompare(bVal);
        });

        // we might be update options but no form control exists for this export format
        if (this.form?.controls.itemGroupId) {
            // If the field no longer exists then reset it back to default of first
            const fieldStillExists = this.itemGroupIDOptions.find(option => {
                return option.value === this.form?.controls.itemGroupId.value;
            });
            if (!fieldStillExists) {
                this.form?.patchValue({
                    item_group_id: this.itemGroupIDOptions[0].value
                });
            }
        }

        return this.itemGroupIDOptions;
    }

    extractIndexFieldOptions(exportFields: { field_name: string; export_field_name: string; }[]): { display: string; value: string; }[] {
        const indexFieldOptions: { display: string; value: string; }[] = [];

        for (const { field_name } of exportFields) {
            if (!isEmpty(field_name)) {
                indexFieldOptions.push({
                    display: field_name,
                    value: field_name
                });
            }
        }

        // TODO replace with generic sortby comparator
        return indexFieldOptions.sort((a, b) => {
            const aVal = a.value.toLowerCase();
            const bVal = b.value.toLowerCase();
            return aVal.localeCompare(bVal);
        });
    }

    extractSortableFieldOptions(exportFields: { field_name: string; export_field_name: string; }[]): SortableFieldOption[] {
        const fields: SortableFieldOption[] = [
            {
                display: 'Default Sort',
                source: '',
                value: ''
            }
        ];

        const exportFieldNameSet = new Set();

        for (const { export_field_name } of exportFields) {
            if (!isEmpty(export_field_name)) {
                fields.push({
                    display: export_field_name,
                    source: 'Fields from Export Map',
                    value: export_field_name
                });

                exportFieldNameSet.add(export_field_name);
            }
        }

        this.dbSortableFields?.forEach(({ name, source, value }) => {
            if (!exportFieldNameSet.has(name)) {
                fields.push({
                    display: name,
                    source,
                    value
                });
            }
        });

        return fields;
    }

    protected initForm(): void {
        this.form = this.fb.group({
            fileHeader: [this.exportItem?.file_header ?? null],
            fileFooter: [this.exportItem?.file_footer ?? null],
            includeColumnNames: [this.boolean(this.exportItem?.include_column_names) ?? false],
            quotedFields: [this.boolean(this.exportItem?.quoted_fields) ?? false],
            deduplicatePreserveNulls: [this.boolean(this.exportItem?.deduplicate_preserve_nulls) ?? false],
            deltaExport: [this.exportItem?.delta_export ?? '0'],
            exportEncoding: [this.exportItem?.export_encoding ?? null],
            rowSort: [this.exportItem?.row_sort ?? null],
            rowOrder: [this.exportItem?.row_order ?? 'ASC'],
            rowLimit: [this.parseRowLimit(this.exportItem.row_limit)],
            exportFormat: [this.exportItem?.export_format ?? 'delimited'],
            itemGroupID: [this.exportItem?.item_group_id ?? ''],
            jsonMinifyType: [this.exportItem?.json_minify_type ?? 'full'],
            delimiter: [this.exportItem?.delimiter ?? 'tab'],
            enclosure: [this.exportItem?.enclosure ?? ''],
            escape: [this.exportItem?.escape ?? ''],
            stripCharacters: [this.exportItem?.strip_characters ?? ["\r", "\n", "\t"]],
            exportIndexField: [this.exportItem?.export_index_field ?? null],
            showEmptyTags: [this.boolean(this.exportItem?.show_empty_tags) ?? false],
            showEmptyParentTags: [this.boolean(this.exportItem?.show_empty_parent_tags) ?? true],
            useCdata: [this.boolean(this.exportItem?.use_cdata) ?? false],
            xmlWriteDocumentTag: [this.boolean(this.exportItem?.xml_write_document_tag) ?? true]
        });
    }

    protected patchForm(exportItem: ExistingExport): void {
        this.patchValue({
            fileHeader: exportItem.file_header ?? null,
            fileFooter: exportItem.file_footer ?? null,
            includeColumnNames: this.boolean(exportItem.include_column_names) ?? false,
            quotedFields: this.boolean(exportItem.quoted_fields) ?? false,
            deduplicatePreserveNulls: this.boolean(exportItem.deduplicate_preserve_nulls) ?? false,
            deltaExport: exportItem.delta_export ?? '0',
            exportEncoding: exportItem.export_encoding ?? null,
            rowSort: exportItem.row_sort ?? null,
            rowOrder: exportItem.row_order ?? 'ASC',
            rowLimit: this.parseRowLimit(exportItem.row_limit),
            exportFormat: exportItem.export_format ?? 'delimited',
            itemGroupID: exportItem.item_group_id ?? '',
            jsonMinifyType: exportItem.json_minify_type ?? 'full',
            delimiter: exportItem.delimiter ?? 'tab',
            enclosure: exportItem.enclosure ?? '',
            escape: exportItem.escape ?? '',
            stripCharacters: exportItem.strip_characters ?? ["\r", "\n", "\t"],
            exportIndexField: exportItem.export_index_field ?? null,
            showEmptyTags: this.boolean(exportItem.show_empty_tags) ?? false,
            showEmptyParentTags: this.boolean(exportItem.show_empty_parent_tags) ?? true,
            useCdata: this.boolean(exportItem.use_cdata) ?? false,
            xmlWriteDocumentTag: this.boolean(exportItem.xml_write_document_tag) ?? true
        });
    }

    protected afterFormInit() {
        this.form.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((changes) => {
                this.change.emit({
                    ...changes,
                    ...this.extractStringDigitsFromBooleans(changes, [
                        'deltaExport',
                        'includeColumnNames',
                        'showEmptyTags',
                        'showEmptyParentTags',
                        'deduplicatePreserveNulls',
                        'quotedFields',
                        'useCdata',
                        'xmlWriteDocumentTag'
                    ])
                });
            });

        this.formReady.emit(this.form);
    }

    handleDeduplicateFieldChange(deduplicateFieldName): void {
        this.exportItem.deduplicate_field_name = deduplicateFieldName;
        this.patchForm(this.exportItem);
        this.formReady.emit(this.form);
        this.exportOptionsChange.emit(true);
    }
}
