import { Component } from '@angular/core';

@Component({
    selector: 'fdx-logs-imports-exports',
    templateUrl: './logs-imports-exports.component.html',
    styleUrls: ['./logs-imports-exports.component.scss']
})
export class LogsImportsExportsComponent {

}
