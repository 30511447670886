import { LogAction } from '@app/logs/models/enums/log-action.enum';
import { LogType } from '@app/logs/models/enums/log-type.enum';
import { LogTypeOption } from '@app/logs/models/types/log-type-option.type';
import { LogsAPIRequests } from '@app/logs/models/types/logs.types';

export const LOG_TYPE_MAP: Map<string, LogTypeOption> = new Map([
    [LogType.ImportsExports, {
        value: LogType.ImportsExports,
        display: 'Import/Export logs',
        route: 'import-export'
    }],
    [LogType.Transformers, {
        value: LogType.Transformers,
        display: 'Transformer logs',
        route: 'transformer'
    }],
    [LogType.FeedAlerts, {
        value: LogType.FeedAlerts,
        display: 'Google Merchant Center logs',
        route: 'feed-alerts'
    }],
    [LogType.Change, {
        value: LogType.Change,
        display: 'Change logs',
        route: 'change'
    }],
    [LogType.ExportEventQueue, {
        value: LogType.ExportEventQueue,
        display: 'Export event queue logs',
        route: 'export-event-queue'
    }],
    [LogType.EDRTS, {
        value: LogType.EDRTS,
        display: 'EDRTS logs',
        route: 'edrts'
    }]
])

export const OPERATION_NAMES_MAP: Map<string, string> = new Map([
    ['db_import', 'Import'],
    ['export_local', 'Export'],
    ['download', 'download'],
    ['source_file_field_missing', 'Source file field missing'],
    ['no_fields_for_tmp_table', 'No fields for tmp table'],
    ['bad_http_status_code', 'Bad http status code'],
    ['xml_to_csv', 'XML to CSV'],
    ['xml_to_csv_download', 'XML to CSV download'],
    ['missing_item_group_id', 'Export'],
    ['import_missing_header', 'Import missing header'],
    ['export', 'Export'],
    ['amazon_api_error', 'Amazon API error'],
    ['data_governance_alert', 'Data governance alert'],
    ['data_governance_prevent_export', 'Data governance prevent export'],
    ['data_override_unique_violation', 'Data governance unique violation'],
    ['export_threshold_violation', 'Export threshold violation'],
    ['export_empty_violation', 'Summary empty violation'],
    ['update_threshold_violation', 'Update threshold violation'],
    ['load_threshold_violation', 'Load threshold violation'],
    ['load_threshold_percent_violation', 'Percent last import threshold violation'],
    ['import_chunk_mismatch', 'Import chunk mismatch'],
    ['invalid_xml_path', 'Invalid XML path'],
    ['transformer_fail', 'Transformer fail'],
    ['transformer_lex', 'Transformer lex'],
    ['transformer_parse', 'Transformer parse'],
    ['transformer_create', 'Transformer create'],
    ['curl_error', 'Curl error'],
    ['invalid_url', 'Invalid URL'],
    ['sftp_permissions_error ', 'SFTP permissions error'],
    ['sftp_operations_error', 'SFTP operations error'],
    ['export_webhook_error', 'Export webhook error'],
    ['export_missing_rad_association', 'RAD export database error'],
    ['export_webhook_log', 'Export webhook log'],
    ['download_limit', 'Download limit'],
    ['summary_governance_no_primary_key', 'Database primary key'],
    ['download_limit_uncompressed', 'Uncompressed download limit'],
    ['empty_file', 'Empty file'],
    ['gzip_fail', 'GZIP fail'],
    ['invalid_zip', 'Invalid zip'],
    ['deduplicate_field_error', 'Duplicate field error'],
    ['create_temporary_table_fail', 'Create tmp table fail'],
    ['dgq_parse_fail', 'DGQ parse fail'],
    ['dgq_lex_fail', 'DGQ lex fail'],
    ['dgq_create_fail', 'DGQ create fail'],
    ['dgq_call_fail', 'DGQ call fail'],
    ['preprocess_error', 'Preprocess error'],
    ['invalid_credentials', 'Invalid credentials'],
    ['domdocument_max_size', 'Document max size'],
    ['create_table_error', 'Create table error'],
    ['ndjson_to_csv', 'NDJSON did not parse'],
    ['json_to_csv', 'JSON did not parse'],
    ['summary_governance_internal_gm_error', 'Internal_error'],
    ['summary_governance_configuration_error', 'Internal_error'],
    ['bad_input', 'Input file is malformed'],
    ['export_json_field_invalid', 'Invalid JSON'],
    ['aggregate_duplicate_key', 'Aggregate duplicate key']
]);

export const LOGS_API_REQUESTS_MAP: LogsAPIRequests = new Map([
    ['GET|/login', {
        category: 'login',
        action: LogAction.Execute,
        display: 'Login'
    }],
    ['GET|/login/:username/:password', { // Legacy
        category: 'login',
        action: LogAction.Execute,
        display: 'Login'
    }],
    ['PUT|/accept_invite', {
        category: 'invitations',
        action: LogAction.Update,
        display: 'Accept Invitation'
    }],
    ['PUT|/forgot_password', {
        category: 'password',
        action: LogAction.Update,
        display: 'Forgot Password Request'
    }],
    ['PUT|/reset_password', {
        category: 'password',
        action: LogAction.Update,
        display: 'Password'
    }],
    ['POST|/accounts/:account_id/user_account_permissions', {
        category: 'permissions',
        action: LogAction.Create,
        display: 'Account Permissions'
    }],
    ['DELETE|/accounts/:account_id/user_account_permissions/:uap_id', {
        category: 'permissions',
        action: LogAction.Delete,
        display: 'Account Permissions'
    }],
    ['POST|/dbs/:db_id/user_db_permissions', {
        category: 'permissions',
        action: LogAction.Create,
        display: 'Database Permissions'
    }],
    ['DELETE|/dbs/:db_id/user_db_permissions/:udp_id', {
        category: 'permissions',
        action: LogAction.Delete,
        display: 'Database Permissions'
    }],
    ['PUT|/dbs/:db_id/user_db_permissions', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Database Permissions'
    }],
    ['PUT|/user/update_account_permission/:uap_id', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Account Permissions'
    }],
    ['PUT|/user/update_db_permission/:udp_id', {
        category: 'permissions',
        action: LogAction.Update,
        display: 'Database Permissions'
    }],
    ['POST|/stripe', {
        category: 'stripe',
        action: LogAction.Create,
        display: 'Stripe Authorization'
    }],
    ['POST|/dbs/:db_id/transformed_data_overrides/:export_id', {
        category: 'transformed_data_overrides',
        action: LogAction.CreateUpdate,
        display: 'Transformed Data Overrides'
    }],
    ['POST|/dbs/:db_id/transformed_data_overrides', {
        category: 'transformed_data_overrides',
        action: LogAction.Delete,
        display: 'Transformed Data Overrides'
    }],
    ['POST|/accounts/:account_id/dbs', {
        category: 'dbs',
        action: LogAction.Create,
        display: 'Database'
    }],
    ['POST|/dbs/:db_id/copy_db', {
        category: 'dbs',
        action: LogAction.Copy,
        display: 'Database'
    }],
    ['DELETE|/dbs/:db_id', {
        category: 'dbs',
        action: LogAction.Delete,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/pause', {
        category: 'pause_dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/unpause', {
        category: 'pause_dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['POST|/dbs/:db_id/primary_key', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'DB Primary Key'
    }],
    ['PUT|/dbs/:db_id/rename', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Database'
    }],
    ['PUT|/dbs/:db_id/email_address', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'Email Address (Alerts)'
    }],
    ['PUT|/dbs/:db_id/report_account', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Google Account'
    }],
    ['PUT|/dbs/:db_id/report_account_google_analytics', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Google Anaylitics Account'
    }],
    ['PUT|/dbs/:db_id/report_account_bing', {
        category: 'third_party_accounts',
        action: LogAction.Update,
        display: 'Bing Account/Campaign'
    }],
    ['POST|/dbs/:db_id/imports', {
        category: 'imports',
        action: LogAction.Create,
        display: 'Import'
    }],
    ['POST|/dbs/:db_id/imports/:import_id', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/update_import_fields', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Field Update'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/schedule', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Schedule'
    }],
    ['DELETE|/dbs/:db_id/imports/:import_id', {
        category: 'imports',
        action: LogAction.Delete,
        display: 'Import'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/delimiters', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Delimiters'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/xml_force_parse', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import XML Force Parse'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/file_map', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import File Map'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/thresholds', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Thresholds'
    }],
    ['PUT|/dbs/:db_id/imports/:import_id/retries', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Import Retries'
    }],
    ['PUT|/dbs/:db_id/extra_import_fields', {
        category: 'imports',
        action: LogAction.Update,
        display: 'Extra Import Fields'
    }],
    ['POST|/dbs/:db_id/transformers', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers/:transformer_id', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['DELETE|/dbs/:db_id/transformers/:field_name', {
        category: 'transformers',
        action: LogAction.Delete,
        display: 'Transformer'
    }],
    ['PUT|/dbs/:db_id/transformers/:transformer_id', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer'
    }],
    ['PUT|/dbs/:db_id/transformers/:transformer_id/sort_order', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer Order'
    }],
    ['POST|/dbs/:db_id/transformers/tokenize-old', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Tokenize Transformers'
    }],
    ['PUT|/dbs/:db_id/db_fields/', {
        category: 'db_fields',
        action: LogAction.Update,
        display: 'DB Fields Update'
    }],
    ['POST|/dbs/:db_id/exports', {
        category: 'exports',
        action: LogAction.Create,
        display: 'Export'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Export'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id/schedule', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Export Schedule'
    }],
    ['DELETE|/dbs/:db_id/exports/:export_id', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Export'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/run_parallel_export', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Export'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/run_export', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Export'
    }],
    ['POST|/dbs/:db_id/run_scheduled_exports', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Push Exports'
    }],
    ['POST|/dbs/:db_id/run_scheduled_imports', {
        category: 'imports',
        action: LogAction.Execute,
        display: 'Run Imports'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/data_governance_queries', {
        category: 'exports',
        action: LogAction.Create,
        display: 'Data Governance'
    }],
    ['POST|/dbs/:db_id/exports/:export_id/download_sample', {
        category: 'exports',
        action: LogAction.Execute,
        display: 'Feed AI Download Sample'
    }],
    ['PUT|/dbs/:db_id/exports/:export_id/data_governance_queries/:data_governance_query_id', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Data Governance'
    }],
    ['DELETE|/dbs/:db_id/exports/:export_id/data_governance_queries/:data_governance_query_id', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Data Governance'
    }],
    ['POST|/dbs/:db_id/unique_values', {
        category: 'unique_values',
        action: LogAction.Create,
        display: 'Set Map for a Field'
    }],
    ['POST|/dbs/:db_id/join_performance_report', {
        category: 'join_performance_report',
        action: LogAction.Execute,
        display: 'Join Performance Report'
    }],
    ['POST|/dbs/:db_id/ftp_triggers', {
        category: 'ftp',
        action: LogAction.Create,
        display: 'FTP Triggers'
    }],
    ['DELETE|/dbs/:db_id/ftp_triggers/:trigger_id', {
        category: 'ftp',
        action: LogAction.Delete,
        display: 'FTP Triggers'
    }],
    ['POST|/dbs/:db_id/amazon_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Amazon Authorization'
    }],
    ['POST|/dbs/:db_id/sears_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Sears Authorization'
    }],
    ['POST|/dbs/:db_id/walmart_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Walmart Authorization'
    }],
    ['POST|/dbs/:db_id/mercado_libre_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Mercado Libre Authorization'
    }],
    ['POST|/dbs/:db_id/ftp_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'FTP Authorization'
    }],
    ['POST|/dbs/:db_id/polyvore_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Polyvore Authorization'
    }],
    ['POST|/dbs/:db_id/google_merchant_center_authorization', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Google Merchant Center Authorization'
    }],
    ['POST|/dbs/:db_id/gmc_threshold', {
        category: 'feed_alerts',
        action: LogAction.Create,
        display: 'Google Merchant Center Alert Creation'
    }],
    ['POST|/dbs/:db_id/gmc_shopping_actions/test_order', {
        category: 'google_merchant_center',
        action: LogAction.Execute,
        display: 'Google Merchant Center Test Order'
    }],
    ['POST|/dbs/:db_id/feed_config', {
        category: 'feed_config',
        action: LogAction.Update,
        display: 'Feed Pull Configuration'
    }],
    ['POST|/dbs/:db_id/ftp_accounts', {
        category: 'ftp',
        action: LogAction.Create,
        display: 'FTP Account'
    }],
    ['PUT|/dbs/:db_id/ftp_accounts', {
        category: 'ftp',
        action: LogAction.Update,
        display: 'FTP Account'
    }],
    ['PUT|/dbs/:db_id/external_id', {
        category: 'dbs',
        action: LogAction.Update,
        display: 'DB External ID'
    }],
    ['PUT|/dbs/:db_id/transformer_notes', {
        category: 'transformers',
        action: LogAction.Update,
        display: 'Transformer Notes'
    }],
    ['POST|/dbs/:db_id/bulk_transformers', {
        category: 'transformers',
        action: LogAction.Create,
        display: 'Bulk Transformers'
    }],
    ['POST|/emails/send_auth_email', {
        category: 'emails',
        action: LogAction.Create,
        display: 'Send Email'
    }],
    ['POST|/dbs/:db_id/run_imports', {
        category: 'imports',
        action: LogAction.Execute,
        display: 'Run Imports'
    }],
    ['POST|/dbs/:db_id/google_service_account', {
        category: 'authorization',
        action: LogAction.Create,
        display: 'Google Service Account'
    }],
    ['PUT|/dbs/:db_id/google_service_account', {
        category: 'authorization',
        action: LogAction.Copy,
        display: 'Google Service Account'
    }],
    ['POST|/dbs/:db_id/classifier_info', {
        category: 'exports',
        action: LogAction.Update,
        display: 'Feed AI Classifier'
    }],
    ['POST|/dbs/:db_id/clear_cache', {
        category: 'exports',
        action: LogAction.Delete,
        display: 'Feed AI Clear Cache'
    }],
    ['POST|/dbs/:db_id/feed_summary_governance', {
        category: 'summary_governance',
        action: LogAction.Create,
        display: 'Summary Governance'
    }],
    ['PUT|/dbs/:db_id/feed_summary_governance/:summary_id', {
        category: 'summary_governance',
        action: LogAction.Update,
        display: 'Summary Governance'
    }],
    ['POST|/dbs/:db_id/feed_summary_governance/:summary_id/threshold', {
        category: 'summary_governance',
        action: LogAction.Create,
        display: 'Summary Governance Threshold'
    }],
    ['PUT|/dbs/:db_id/feed_summary_governance/:summary_id/threshold/:threshold_id', {
        category: 'summary_governance',
        action: LogAction.Update,
        display: 'Summary Governance Threshold'
    }],
    ['DELETE|/dbs/:db_id/feed_summary_governance/:summary_id/threshold/:threshold_id', {
        category: 'summary_governance',
        action: LogAction.Delete,
        display: 'Summary Governance Threshold'
    }]
]);

/**
 * This is only used for old log entries
 */
export const HUMAN_FRIENDLY_ERRORS_MAP: Map<string, string> = new Map([
    ['general_spam_policy', 'Automatic item disapproval due to policy violation'],
    ['generic_landing_page_redirect', 'Redirect to generic landing page'],
    ['image_link_broken', 'Invalid images'],
    ['image_link_generic', 'Generic images'],
    ['image_link_missing', 'Missing images'],
    ['image_link_roboted', 'Images cannot be crawled because of robots.txt restriction'],
    ['image_link_slow_crawl', 'Ensure that Google can access and crawl your images'],
    ['image_link_too_small', 'Images too small'],
    ['landing_page_error', 'Product pages cannot be accessed'],
    ['landing_page_roboted', 'Product pages cannot be crawled because of robots.txt restriction'],
    ['missing_required_gtin_error', 'Item requires a GTIN'],
    ['mobile_landing_page_error', 'Product pages cannot be accessed from a mobile device'],
    ['mobile_landing_page_roboted', 'Product pages cannot be crawled with a mobile user-agent because of robots.txt restriction'],
    ['forbidden_bulk_gtin_error', 'Item uses a GTIN reserved for bulk products when a valid GTIN is required'],
    ['image_link_small_warning', 'Image too small'],
    ['missing_required_gtin_warning', 'Item requires a GTIN'],
    ['voila_hammer_dangerous_knives', 'Items preemptively disapproved for a policy violation (Dangerous products: dangerous knives)'],
    ['voila_hammer_other_weapons', 'Items preemptively disapproved for a policy violation (Dangerous products: other weapons)'],
    ['wrong_tax', 'Incorrect tax information'],
    ['forbidden_coupon_gtin_error', 'Item uses a coupon GTIN when a valid GTIN is required'],
    ['forbidden_coupon_gtin_warning', 'Coupon code GTIN warning'],
    ['invalid_mime_type', 'Item landing page does not render properly'],
    ['missing_required_gtin_future_', 'Missing Required GTIN'],
    ['mismatching_brand_gtin_prefix_', 'Mismatching brand, gtin_'],
    ['price_mismatch', 'Temporary item disapprovals due to incorrect prices'],
    ['strong_id_inaccurate', 'Incorrect product identifiers'],
    ['title_all_caps', 'Capitalized titles'],
    ['image_link_missing_local_offer', 'Local items - missing image'],
    ['local_missing_description', 'Missing description'],
    ['local_offer_unmatchable', 'Local offer not matching any catalog or online offer'],
    ['availability_mismatch', 'Temporary item disapprovals due to incorrect availability'],
    ['missing_required_item_color', 'Missing Color'],
    ['missing_required_item_size', 'Missing Size'],
    ['multiple_sizes', 'Multiple sizes for one item'],
    ['wrong_shipping', 'Incorrect shipping costs'],
    ['availability_updated', 'Incorrect microdata availability information'],
    ['dq_pid_no_fresh_crawl', 'Preemptive disapproval for price'],
    ['dq_pid_price_no_extraction', 'Preemptive disapproval for price'],
    ['non_normalizable_sizes', 'Size not recognised'],
    ['title_long', 'Long titles']
]);
