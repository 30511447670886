<form [formGroup]="filters" #form="ngForm" (ngSubmit)="filters?.valid && applyFilters()">
    <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <label class="col-form-label pt-0" for="date-range">Date range ({{timeZoneCode}})</label>
                <fsc-daterange-picker
                    formControlName="dateRange"
                    [useShadowDom]="true"
                    shadowRootHost="fdx-style-wrapper"
                    inputId="date-range"
                    inputName="dateRange"
                    inputPlaceholder="mm/dd/yyyy - mm/dd/yyyy"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [clearable]="false">
                </fsc-daterange-picker>
            </div>
            <ng-container [ngSwitch]="logType">
                <ng-container *ngSwitchCase="logTypes.ImportsExports">
                    <div class="col-12 col-md-6 col-lg-2">
                        <fsc-multiselect
                            formControlName="operation"
                            inputId="operation"
                            label="Operation"
                            labelClass="col-form-label pt-0"
                            [items]="testItems"
                            [clearable]="true"
                            (dataSelected)="patchFromMultiselect('operation', $event)">
                        </fsc-multiselect>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="logTypes.Transformers">
                    <ng-container *ngTemplateOutlet="actionInput"></ng-container>
                    <div class="col-12 col-md-6 col-lg-2">
                        <fsc-multiselect
                            formControlName="field"
                            inputId="field"
                            label="Field"
                            labelClass="col-form-label pt-0"
                            [items]="testItems"
                            [clearable]="true"
                            (dataSelected)="patchFromMultiselect('field', $event)">
                        </fsc-multiselect>
                    </div>
                    <ng-container *ngTemplateOutlet="userInput"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="logTypes.FeedAlerts">
                    <div class="col-12 col-md-6 col-lg-2">
                        <fsc-multiselect
                            formControlName="status"
                            inputId="status"
                            label="Status"
                            labelClass="col-form-label pt-0"
                            [items]="testItems"
                            [clearable]="true"
                            (dataSelected)="patchFromMultiselect('status', $event)">
                        </fsc-multiselect>
                    </div>
                </ng-container>

                <ng-container *ngSwitchCase="logTypes.Change">
                    <ng-container *ngTemplateOutlet="actionInput"></ng-container>
                    <div class="col-12 col-md-6 col-lg-2">
                        <fsc-multiselect
                            formControlName="module"
                            inputId="module"
                            label="Module"
                            labelClass="col-form-label pt-0"
                            [items]="testItems"
                            [clearable]="true"
                            (dataSelected)="patchFromMultiselect('module', $event)">
                        </fsc-multiselect>
                    </div>
                    <ng-container *ngTemplateOutlet="userInput"></ng-container>
                </ng-container>
            </ng-container>
        </div>

        <ng-template #actionInput>
            <div class="col-12 col-md-6 col-lg-2">
                <fsc-multiselect
                    formControlName="action"
                    inputId="action"
                    label="Action"
                    labelClass="col-form-label pt-0"
                    [items]="testItems"
                    [clearable]="true"
                    (dataSelected)="patchFromMultiselect('action', $event)">
                </fsc-multiselect>
            </div>
        </ng-template>

        <ng-template #userInput>
            <div class="col-12 col-md-6 col-lg-2">
                <fsc-multiselect
                    formControlName="user"
                    inputId="user"
                    label="User"
                    labelClass="col-form-label pt-0"
                    [items]="testItems"
                    [clearable]="true"
                    (dataSelected)="patchFromMultiselect('user', $event)">
                </fsc-multiselect>
            </div>
        </ng-template>
    </div>
    <div class="card-footer d-flex gap-2 justify-content-end">
        <div *ngIf="showWarning" class="align-self-center pe-3">
            <div class="text-warning"><fa-icon [icon]="faTriangleExclamation" class="me-2"></fa-icon>The filters shown above have not yet been applied. Click the Apply filters button to apply them to the data table below.</div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!filters?.valid">Apply filters</button>
        <button type="button" class="btn btn-link text-secondary" (click)="clearAllFilters()">Clear all filters</button>
    </div>
</form>
