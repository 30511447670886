import { UIRouterState, UIRouterStateParams } from '@ajs/ajs-upgraded-providers';
import { AccountModel } from '@ajs/models/account';
import { UserModel } from '@ajs/models/user';
import AppStateService from '@ajs/services/AppStateService';
import CSVService from '@ajs/services/CSVService';
import FdxUI from '@ajs/services/fdxUI';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { DatabasesUtilitiesService } from '@app/databases/services/databases-utilities.service';
import { ExportConstants } from '@app/exports/constants/exports.constants';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { Export, GetExportsResponse } from '@app/exports/services/responses/get-exports.response';
import { FtpTriggerFieldModel } from '@app/ftp-trigger/models/ftp-trigger-field.model';
import { FtpTriggerDataService } from '@app/ftp-trigger/services/ftp-trigger-data.service';
import { GetFtpTriggersResponse } from '@app/ftp-trigger/services/responses/get-ftp-triggers.response';
import { CheatSheetOffcanvasComponent } from '@app/modules/cheat-sheet/components/cheat-sheet-offcanvas/cheat-sheet-offcanvas.component';
import { QueryType } from '@app/modules/inputs/enums/query-type.enum';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { SelectOptionType } from '@app/modules/inputs/types/select-option.type';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { OffcanvasService } from '@app/modules/offcanvas/services/offcanvas.service';
import { BasePageComponent } from '@app/modules/page/abstract/base-page.component';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { SearchPipe } from '@app/modules/pipes/pipes/search/search.pipe';
import { TransformersDownloadModal } from '@app/transformers/components/transformers-download-modal/transformers-download-modal.component';
import { TransformersFaqModalComponent } from '@app/transformers/components/transformers-faq-modal/transformers-faq-modal.component';
import { TransformersFieldDependencyTreeModalComponent } from '@app/transformers/components/transformers-field-dependency-tree-modal/transformers-field-dependency-tree-modal.component';
import { TransformerConstants } from '@app/transformers/constants/transformer.constants';
import { TransformerDbFieldModel } from '@app/transformers/models/transformer-db-field.model';
import { TransformerDisplayModel } from '@app/transformers/models/transformer-display.model';
import { TransformerFieldDependencyModel } from '@app/transformers/models/transformer-field-dependency.model';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { TransformersRequest } from '@app/transformers/services/requests/transformers.request';
import { DbFieldsTransformerCountResponse } from '@app/transformers/services/responses/db-fields-transformer-count.response';
import { DeleteAllTransformersResponse } from '@app/transformers/services/responses/delete-all-transformers.response';
import { TransformerUtilitiesService } from '@app/transformers/services/transformer-utilities.service';
import { TransformersDataService } from '@app/transformers/services/transformers-data.service';
import { EmptyStateBodyTextSize, EmptyStateImageType } from '@feedonomics/frontend-components';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faArrowRight, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { forkJoin, switchMap, takeUntil, tap } from 'rxjs';

enum DownloadTypeEnum {
    All = 'alphabetical',
    FEO = 'field_execution_order',
    Select = 'select'
}

@Component({
    selector: 'fdx-transformers-page',
    templateUrl: './transformers-page.component.html',
    styleUrls: ['./transformers-page.component.scss']
})
export class TransformersPageComponent extends BasePageComponent implements OnInit {
    appMenuTab = AppMenuTab.Transformers;
    title: string = 'Transformers';
    faAlertArrow: IconDefinition = faArrowRight;

    databaseId: string = this.appStateService.getDatabaseId();

    get stateParamFieldName(): string | null {
        return this.$stateParams.field_name;
    }

    get user(): UserModel {
        return this.appStateService.getUser();
    }

    get account(): AccountModel {
        return this.appStateService.getAccount();
    }

    get shouldShowDependencyFieldsButton(): boolean {
        return this.transformerFieldDependency?.dependent_fields.length > 0;
    }

    allDbFields: TransformerDbFieldModel[] = [];

    dbFields: AggregatedDbFieldsType = {
        autocompleteFields: [],
        validFields: []
    }

    fieldSelectForm: UntypedFormGroup = new UntypedFormGroup({
        currentFieldSelect:  new UntypedFormControl()
    });

    get currentFieldSelect(): UntypedFormControl {
        return this.fieldSelectForm.controls.currentFieldSelect as UntypedFormControl;
    }

    get currentField(): TransformerDbFieldModel {
        return this.currentFieldSelect?.value;
    }

    transformerFieldDependency: TransformerFieldDependencyModel;

    allTransformers: TransformerModel[] = [];
    transformers: TransformerDisplayModel[] = [];
    newTransformer: TransformerDisplayModel = {...TransformerConstants.newTransformer};

    exports: Export[];

    get queryValue(): string {
        return this.filterForm.value.query;
    }

    get statusValue(): string {
        return this.filterForm.value.status;
    }

    get exportsValue(): Export[] {
        return this.filterForm.value.exports;
    }

    filterForm: UntypedFormGroup = new UntypedFormGroup({
        query: new UntypedFormControl(''),
        status: new UntypedFormControl(''),
        exports: new UntypedFormControl()
    });

    statusOptions: SelectOptionType[] = [
        {
            value: '',
            display: 'Transformer status: all'
        },
        {
            value: '1',
            display: 'Transformer status: enabled'
        },
        {
            value: '0',
            display: 'Transformer status: disabled'
        },
    ]

    triggers: FtpTriggerFieldModel[];

    helpIcon: IconDefinition = faQuestionCircle;
    trashIcon: IconDefinition = faTrashAlt;

    transformersPerPage: number = 25;
    totalItems: number = null;
    numPages: number = null;
    currentPage: number = 1;

    emptyStateImageType: typeof EmptyStateImageType = EmptyStateImageType;
    emptyStateBodyTextSize: typeof EmptyStateBodyTextSize = EmptyStateBodyTextSize;

    get existingTransformersText(): string {
        if (this.transformers.length === this.allTransformers.length) {
            return `Displaying ${this.allTransformers.length} existing transformers for `;
        }
        return `Displaying ${this.transformers.length} of ${this.allTransformers.length} existing transformers for `;
    }

    constructor(
        @Inject(UIRouterState) private readonly $state: any,
        @Inject(UIRouterStateParams) private readonly $stateParams: any,
        private readonly appStateService: AppStateService,
        private readonly csvService: CSVService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly databasesUtilitiesService: DatabasesUtilitiesService,
        private readonly exportsDataService: ExportsDataService,
        private readonly ftpTriggerDataService: FtpTriggerDataService,
        private readonly localStorageService: LocalStorageService,
        private readonly modalService: ModalService,
        private readonly offcanvasService: OffcanvasService,
        private readonly orderByPipe: OrderByPipe,
        private readonly searchPipe: SearchPipe,
        private readonly transformersDataService: TransformersDataService,
        public readonly transformerUtilities: TransformerUtilitiesService,
        @Inject(WINDOW) private readonly window: Window,
        readonly fdxUI: FdxUI
    ) {
        super(fdxUI);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initData();
    }

    initData(): void {
        this.databasesDataService.getFieldsWithParams<DbFieldsTransformerCountResponse>(this.databaseId, {transformer_count: 1})
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (allDbFields: DbFieldsTransformerCountResponse[]) => {
                    /* Db Fields */
                    this.allDbFields = allDbFields.map<TransformerDbFieldModel>((dbField: DbFieldsTransformerCountResponse, index: number) => {
                        const mapped = this.transformerUtilities.mapDbFieldToModel(dbField, index);

                        if (mapped.displayName === this.stateParamFieldName) {
                            this.currentFieldSelect.patchValue(mapped);
                        }

                        return mapped;
                    });

                    if (!this.currentField) {
                        const lastVariableName: string = this.localStorageService.getItem(this.transformerUtilities.variableSelectionKey);
                        const newVariableName: string = this.allDbFields.find((field: TransformerDbFieldModel) => field.displayName === lastVariableName) ? lastVariableName : this.allDbFields[0].displayName;
                        this.changeFieldName(newVariableName);
                    } else {
                        this.dbFields = this.databasesUtilitiesService.reduceToAggregate(this.allDbFields);

                        // TODO other db_fields api response stuff

                        const transformerParams: TransformersRequest = {
                            field_name: this.stateParamFieldName
                        };

                        forkJoin({
                            exports: this.exportsDataService.getExports(this.databaseId),
                            transformerFieldDependency: this.transformersDataService.getTransformerFieldDependencies(this.databaseId, transformerParams),
                            transformers: this.transformersDataService.getTransformers(this.databaseId, transformerParams),
                            triggers: this.ftpTriggerDataService.getFtpTriggers(this.databaseId)
                        }).pipe(takeUntil(this.unsubscribe$)).subscribe({
                            next: (
                                values: {
                                    exports: GetExportsResponse,
                                    transformerFieldDependency: TransformerFieldDependencyModel,
                                    transformers: TransformerModel[],
                                    triggers: GetFtpTriggersResponse,
                                }
                            ) => {
                                /* Exports */
                                this.exports = values.exports;

                                /* Transformer Field Dependencies */
                                this.transformerFieldDependency = values.transformerFieldDependency;

                                /* Triggers */
                                this.triggers = values.triggers;

                                this.getTransformers(transformerParams);
                            },
                            error: (errors: {
                                exports?: HttpErrorResponse,
                                transformerFieldDependency?: HttpErrorResponse,
                                transformers?: HttpErrorResponse,
                                triggers?: HttpErrorResponse,
                            }) => {
                                if (errors.exports) {
                                    this.fdxUI.showToastError(errors.exports.error);
                                }
                                if (errors.transformerFieldDependency) {
                                    this.fdxUI.showToastError(errors.transformerFieldDependency.error);
                                }
                                if (errors.transformers) {
                                    this.fdxUI.showToastError(errors.transformers.error);
                                }
                                if (errors.triggers) {
                                    this.fdxUI.showToastError(errors.triggers.error);
                                }
                            }
                        });
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.fdxUI.showToastError(error.error);
                }
            });
    }

    getTransformers(transformerParams: TransformersRequest): void {
        this.transformersDataService.getTransformers(this.databaseId, transformerParams)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (transformers: TransformerModel[]) => {
                    /* Transformers */
                    this.allTransformers = transformers;
                    this.updateTransformers();

                    // TO DO: Move below elsewhere
                    this.filterForm.valueChanges.pipe(takeUntil(this.unsubscribe$))
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe({
                            next: () => {
                                this.updateTransformers();
                            }
                        });
                },
                error: (errors: HttpErrorResponse) => {
                    this.fdxUI.showToastError(errors.error);
                }
            });
    }

    getTransformersClone(): TransformerModel[] {
        return [...this.allTransformers];
    }

    filterTransformers(data: TransformerModel[]): TransformerModel[] {
        return data.filter((transformer: TransformerModel) => {

            const transformerExports: {
                export_ids: string[],
                all_exports: boolean
            } = JSON.parse(transformer.exports);

            const mappedExportsIds: string[] = this.exportsValue?.map<string>((value: Export) => value.id);

            const matchesQuery: boolean = isEmpty(this.queryValue) || this.searchPipe.transform([transformer.selector, transformer.transformer], this.queryValue).length > 0;
            const matchesStatus: boolean = this.statusValue === '' || transformer.enabled === this.statusValue;
            const matchesExports: boolean = !mappedExportsIds || mappedExportsIds.length === 0 ||
                (transformerExports.all_exports && mappedExportsIds?.includes('0')) ||
                (!transformerExports.all_exports && mappedExportsIds?.some(id => transformerExports.export_ids.includes(id)));

            return matchesQuery && matchesStatus && matchesExports;
        });
    }

    sortAllTransformers(data: TransformerModel[]): TransformerModel[] {
        return this.orderByPipe.transform(data, ['sort_order']);
    }

    paginateTransformers(data: TransformerModel[]): TransformerModel[] {
        this.totalItems = data.length;
        this.numPages = Math.ceil(data.length / this.transformersPerPage);
        const currentPage = this.currentPage - 1;
        return data.slice(currentPage * this.transformersPerPage, (currentPage + 1) * this.transformersPerPage);
    }

    changePage(): void {
        this.updateTransformers();
    }

    updateTransformers(): void {
        this.transformers = this.paginateTransformers(this.sortAllTransformers(this.filterTransformers(this.getTransformersClone())))
            .map<TransformerDisplayModel>((transformer: TransformerModel) => {

            const mappedExports = [];

            const transformerExports: {
                export_ids: string[],
                all_exports: boolean
            } = JSON.parse(transformer.exports);

            if (transformerExports.all_exports) {
                mappedExports.push(ExportConstants.allExports);
            } else {
                this.exports.forEach((exprt: Export) => {
                    if (transformerExports.export_ids.includes(exprt.id)) {
                        mappedExports.push(exprt);
                    }
                });
            }

            const mapped: TransformerDisplayModel = {
                id: transformer.id,
                newTransformer: false,
                enabled: transformer.enabled === '1',
                exportModels: mappedExports,
                isExpanded: false,
                type: QueryType.Advanced,
                error: null,
                selector: transformer.selector,
                transformer: transformer.transformer,
                order: transformer.sort_order
            };

            return mapped;
        });
    }

    changeFieldName(newFieldName: string = this.currentField?.displayName): void {
        this.$state.go(this.$state.current, {field_name: newFieldName});
        this.localStorageService.setItem(this.transformerUtilities.variableSelectionKey, newFieldName);
    }

    showHelp(): void {
        this.modalService.open(TransformersFaqModalComponent, {
            size: 'lg'
        });
    }

    showFieldDependencyModal(): void {
        this.modalService.open(TransformersFieldDependencyTreeModalComponent, {
            size: 'lg',
            resolve: {
                transformerFieldDependency: this.transformerFieldDependency
            }
        });
    }

    confirmDeleteAllTransformers(): void {
        this.modalService.showDangerConfirmationModal(
            `Confirm Delete All Transformers`,
            `Are you sure you wish to delete <strong>all</strong> transformers for <span class="text-primary">[${this.currentField.displayName}]</span>?<br>This will delete <strong>${this.transformers.length}</strong> transformers in total.`,
            'Delete'
        ).closed.pipe(
            switchMap(() => this.transformersDataService.deleteAllTransformers(this.databaseId, this.stateParamFieldName)),
            tap((_response: DeleteAllTransformersResponse) => {
                this.fdxUI.showToastSuccess('Deleted all transformers');
                this.initData();
            }),
            takeUntil(this.unsubscribe$)
        )
        .subscribe()
    }

    showCheatSheet(): void {
        this.offcanvasService.open(CheatSheetOffcanvasComponent, {
            position: 'end'
        });
    }

    get downloadType(): typeof DownloadTypeEnum {
        return DownloadTypeEnum
    }

    downloadCurrentView(transformers: TransformerDisplayModel[]): void {
        const rows: Array<(string | number)[]> = [[
            'field_name',
            'export_id',
            'selector',
            'transformer',
            'sort_order',
            'enabled'
        ]];

        transformers.forEach((transformer: TransformerDisplayModel) => {
            rows.push([
				this.currentField?.displayName,
				transformer.exportModels.map<string>(val => val.id).join(','),
				transformer.selector,
				transformer.transformer,
				transformer.order,
				transformer.enabled ? '1' : '0'
            ]);
        });

        this.csvService.download('transformers_current_view.csv', rows);
    }

    downloadTransformers(type: DownloadTypeEnum): void {
        switch (type) {
            case DownloadTypeEnum.All:
            case DownloadTypeEnum.FEO:
                this.window.open(this.transformerUtilities.getDownloadUrl(this.databaseId, type));
                break;
            case DownloadTypeEnum.Select:
                this.modalService.open(TransformersDownloadModal);  // TO DO: Implement this
                break;
        }
    }

    navigateToOlder(): void {
        this.$state.go('app.transformers', {id:this.databaseId});
    }

    drop(event: CdkDragDrop<TransformerDisplayModel[], TransformerDisplayModel[], TransformerDisplayModel>): void {
        console.log(event);

        // TODO: Implement drag and drop ability

        /* if (event.previousIndex === event.currentIndex) {
            return;
        }

        moveItemInArray(this.dbFields, event.previousIndex, event.currentIndex);

        const modalRef = this.modalService.showConfirmationModal(
            'Update Sort Order',
            `Are you sure you want to change the order of ${event.item.data.field_name} from ${event.previousIndex} to ${event.currentIndex}?`
        );

        modalRef.closed.subscribe(() => {
            this.reorderClicked(this.dbFields[event.currentIndex], event.currentIndex.toString());
        });

        modalRef.dismissed.subscribe(() => {
            moveItemInArray(this.dbFields, event.currentIndex, event.previousIndex);
        }); */
    }

    // TODO: Implement filtering and pagination
}
