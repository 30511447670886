import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-table-pager',
    templateUrl: './table-pager.component.html',
    styleUrls: ['./table-pager.component.scss']
})
export class TablePagerComponent {
    @Input() rowCountLimit: string;
    @Output() readonly rowCountLimitChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() rowCount?: string | number | null;

    /**
     * The text to display after the word `total` when it is only 1 item.
     */
    @Input() rowTextSingular?: string = 'row';

    /**
     * The text to display after the word `total` when it is 2+ items.
     */
    @Input() rowTextPlural?: string = 'rows';

    @Input() disableDropdown?: boolean = false;
    @Input() disablePrev: boolean = false;
    @Input() disableNext: boolean = false;

    @Output() readonly clickPrev: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly clickNext: EventEmitter<void> = new EventEmitter<void>();

    readonly prevIcon: IconDefinition = faChevronLeft;
    readonly nextIcon: IconDefinition = faChevronRight;

    get showRowCount(): boolean {
        switch (this.rowCount) {
            case null:
            case undefined:
            case '':
                return false;

            default:
                return true;
        }
    }

    get rowText(): string {
        if (this.rowCount === 1 || this.rowCount === '1') {
            return this.rowTextSingular;
        }

        return this.rowTextPlural;
    }

    prev(): void {
        this.clickPrev.emit();
    }

    next(): void {
        this.clickNext.emit();
    }

    updateSelection(): void {
        this.rowCountLimitChange.emit(this.rowCountLimit);
    }
}
