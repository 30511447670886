<fdx-sticky-header>
	<!-- Field Selection / Order -->
	<div class="d-flex align-items-center justify-content-between">
        <fdx-page-title [classes]="{'mb-0': true}" [beta]="true" [darkBackground]="true">{{title}}</fdx-page-title>

        <div [formGroup]="fieldSelectForm" #formFieldSelect="ngForm" class="flex-grow-1">
            <fdx-form-field
                formControlName="currentFieldSelect"
                [classes]="{'row': true}"
                [form]="formFieldSelect" >
                <fdx-label class="col-4 text-end">Choose variable:</fdx-label>
                <fdx-input-wrapper
                    class="col-5">
                    <ng-select
                        formControlName="currentFieldSelect"
                        id="currentFieldSelect"
                        bindLabel="label"
                        [searchFn]="transformerUtilities.transformerDbFieldModelSearchFn"
                        (change)="changeFieldName()"
                        [clearable]="false"
                        placeholder="Select / search for a field" >
                        <ng-option *ngFor="let dbField of allDbFields" [value]="dbField">
                            <span [attr.title]="dbField.title">{{ dbField.label }}</span>
                        </ng-option>
                    </ng-select>
                </fdx-input-wrapper>
            </fdx-form-field>
        </div>

		<div class="d-flex align-items-center ms-3">
            <button
                type="button"
                class="btn btn-primary me-3"
                *ngIf="shouldShowDependencyFieldsButton"
                (click)="showFieldDependencyModal()">
                View {{transformerFieldDependency?.dependent_fields.length}} dependent {{transformerFieldDependency?.dependent_fields.length != 1 ? 'fields' : 'field'}}
            </button>
			<fa-icon [icon]="helpIcon" size="lg" (click)="showHelp()" role="button"></fa-icon>
		</div>
	</div>
	<!-- /Field Selection / Order -->
</fdx-sticky-header>

<div class="mb-3">
    <!-- Add Transformer -->
    <fdx-alert theme="dark"
        [clickable]="true"
        (alertClick)="navigateToOlder()"
        title="You're using the beta version of Transformers!">
        Click here to revert to the old version.
        <fa-icon [icon]="faAlertArrow" size="lg"></fa-icon>
    </fdx-alert>
    <fdx-transformer
        [initialField]="currentField"
        [transformersLength]="allTransformers.length"
        (transformerAction)="initData()"
        [transformer]="newTransformer"
        [transformerFieldDependency]="transformerFieldDependency"
        [dbFields]="dbFields"
        [exports]="exports"
        [triggers]="triggers" >
    </fdx-transformer>
    <!-- /Add Transformer -->
</div>

<div class="d-flex align-items-center gap-3">
    <fdx-page-subtitle>{{existingTransformersText}}<span class="text-primary">[{{currentField?.displayName}}]</span></fdx-page-subtitle>
    <fdx-pagination *ngIf="numPages > 1"
        [(page)]="currentPage"
        (pageChange)="changePage()"
        [pageCount]="numPages"
        [pageSize]="transformersPerPage"
        class="ms-auto">
    </fdx-pagination>
</div>

<form [formGroup]="filterForm" #formFilter="ngForm" class="filter-form mb-3">

    <fdx-form-field
        formControlName="query"
        [form]="formFilter"
        class="flex-grow-2" >
        <fdx-input
            labelText="Filter transformers by query"
            placeholder="Transformer search" >
        </fdx-input>
    </fdx-form-field>

    <fdx-form-field
        formControlName="status"
        [form]="formFilter" >
        <fdx-input-wrapper
            labelText="Filter transformers by status" >
            <ng-select
                formControlName="status"
                id="status"
                [items]="statusOptions"
                bindValue="value"
                bindLabel="display"
                [clearable]="false" >
            </ng-select>
        </fdx-input-wrapper>
    </fdx-form-field>

    <fdx-form-field
        formControlName="exports"
        [form]="formFilter" >
        <fdx-input-wrapper
            labelText="Filter transformers by exports">
            <fdx-export-select
                controlName="exports"
                [formGroup]="filterForm"
                [exports]="exports"
                [triggers]="triggers"
                [multiple]="true"
                placeholder="Filter by exports"
                [allowBlank]="true" >
            </fdx-export-select>
        </fdx-input-wrapper>
    </fdx-form-field>

    <div>
        <button (click)="showCheatSheet()" class="btn btn-outline-primary">FQL cheat sheet</button>
    </div>
	<div ngbDropdown placement="bottom-end">
        <button class="btn btn-outline-primary" id="downloadTransformers" ngbDropdownToggle>
            Download transformers
        </button>
        <div ngbDropdownMenu aria-labelledby="downloadTransformers">
            <button ngbDropdownItem (click)="downloadCurrentView(transformers)">
                Download transformers in current view
            </button>
            <button ngbDropdownItem (click)="downloadTransformers(downloadType.All)">
                Download all transformers
            </button>
            <button ngbDropdownItem (click)="downloadTransformers(downloadType.FEO)">
                Download all transformers (FEO)
            </button>
            <button ngbDropdownItem (click)="downloadTransformers(downloadType.Select)">
                Select fields for transformer download
            </button>
        </div>
	</div>
	<div class="text-end">
		<button
			class="btn btn-outline-primary"
			(click)="confirmDeleteAllTransformers()"
			*ngIf="currentField && currentField.transformerCount !== 0"
			ngbTooltip="Delete All Transformers"
            placement="start"
			tabindex="-1"
            type="button" >
            <fa-icon [icon]="trashIcon" class="no-margin"> Delete all transformers</fa-icon>
        </button>
	</div>
</form>

<!-- <div (cdkDropListDropped)="drop($event)" class="fdx-drop-list" cdkDropList> -->
    <!-- | orderBy: 'order' -->
    <div
        *ngFor="let transformer of transformers"
        class="mb-3 bg-light">
        <!--
            class="mb-3 bg-light fdx-drop-list-handle fdx-expand-wrapper"
            [cdkDragData]="transformer"
        cdkDrag -->

        <fdx-transformer
            [initialField]="currentField"
            [transformersLength]="allTransformers.length"
            (transformerAction)="initData()"
            [transformer]="transformer"
            [transformerFieldDependency]="transformerFieldDependency"
            [dbFields]="dbFields"
            [exports]="exports"
            [triggers]="triggers" >
        </fdx-transformer>
    </div>
<!-- </div> -->

<div class="d-flex">
    <fdx-pagination *ngIf="numPages > 1"
        [(page)]="currentPage"
        (pageChange)="changePage()"
        [pageCount]="numPages"
        [pageSize]="transformersPerPage"
        class="ms-auto">
    </fdx-pagination>
</div>

<fsc-empty-state
    *ngIf="allTransformers.length > 0 && transformers.length === 0"
    [imageType]="emptyStateImageType.NoResults"
    [bodyTextWidth]="emptyStateBodyTextSize.Large">
</fsc-empty-state>
