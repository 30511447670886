<div *ngIf="filtersCollapsed && hasActiveFilters" class="position-relative">
    <div class="d-flex gap-2 overflow-hidden" [ngClass]="{'flex-wrap shorten-flex-width': !chipsCollapsed}" #flexContainer>
        <button
            *ngIf="hasClearableFilters"
            type="button"
            class="btn badge rounded-pill btn-secondary"
            (click)="clearAllFilters()">
            Clear all filters
        </button>
        <ng-container *ngFor="let filter of filterChips; trackBy: trackByKey">
            <div class="badge rounded-pill filter-badge">
                <span>{{ filter.label }}:</span>
                <span class="fw-normal">{{ getDisplayValue(filter) | truncate:truncateLength }}</span>
                <button
                    *ngIf="!filter.permanent"
                    class="btn btn-link text-secondary p-0"
                    type="button"
                    (click)="clearFilter(filter)">
                    <fa-icon [icon]="closeIcon" class="no-margin"></fa-icon>
                </button>
            </div>
        </ng-container>
    </div>
    <div
        *ngIf="chipsOverflow || !chipsCollapsed"
        class="position-absolute bottom-0 end-0 chips-overflow-container">
        <div class="chip-overflow-fader" *ngIf="chipsCollapsed"></div>
        <div class="bg-light">
            <button
                type="button"
                class="btn btn-sm btn-link text-darker-secondary p-0"
                (click)="toggleChipsCollapsed()">
                <span>View {{ chipsCollapsed ? 'more' : 'less' }}</span>
                <fa-icon [icon]="chevronDownIcon" class="fa-icon-right" [flip]="chipsCollapsed ? null : 'vertical'" size="sm"></fa-icon>
            </button>
        </div>
    </div>
</div>
