<form
    name="new-export"
    [formGroup]="form"
    (submit)="onSubmit($event)"
    class="row">
    <div class="col-5">
        <fdx-credentials-form
            headerLabel
            [idPrepend]="idPrepend"
            [exportItem]="exportItem"
            (formReady)="addCredentialsForm($event)"
            (webhookTypeChange)="webhookTypeChange.emit($event)">
        </fdx-credentials-form>

        <fdx-form-section
            *ngIf="displayImportDgq"
            class="row mt-5"
            header="Import DGQs">
            <div class="col-12">
                <div class="form-check d-flex align-items-start">
                    <input
                        [id]="idPrepend + 'importAccountTemplates'"
                        class="form-check-input"
                        type="checkbox"
                        formControlName="importDgqTemplates">
                    <label
                        [for]="idPrepend + 'importAccountTemplates'"
                        class="form-check-label ms-2">
                        Import account level Data
                        Governance Query templates
                    </label>
                </div>
            </div>
        </fdx-form-section>

        <fdx-alert
            *ngIf="addExportError"
            theme="danger"
            [body]="addExportError"
            class="mt-3"></fdx-alert>

        <fdx-export-if-form
            [exportItem]="exportItem"
            (formReady)="addExportIfForm($event)"
            [dbFields]="aggregatedDbFields"
            class="mt-5">
        </fdx-export-if-form>

        <fieldset
            class="row mt-4 pb-3 mb-3"
            [class.border-bottom]="showAdvancedOptions">
            <button
                (click)="toggleAdvancedOptions()"
                name="advanced-options"
                type="button"
                class="btn btn-link">
                Show advanced options
            </button>
        </fieldset>

        <ng-container *ngIf="showAdvancedOptions">
            <fdx-export-retries-form
                [exportItem]="exportItem"
                (formReady)="addExportRetriesForm($event)"
                class="pb-4 border-bottom mb-4"></fdx-export-retries-form>

            <fdx-export-tags-form
                [exportItem]="exportItem"
                (formReady)="addExportTagsForm($event)"
                class="pb-4 border-bottom mb-4"></fdx-export-tags-form>

            <fdx-export-options-form
                [dbSortableFields]="dbSortableFields"
                [exportItem]="exportItem"
                [exportFields]="exportFields"
                (formReady)="addExportOptionsForm($event)">
            </fdx-export-options-form>
        </ng-container>

        <div class="row mt-3">
            <div class="col-6">
                <label
                    [for]="idPrepend + 'copyExport'"
                    class="form-label me-2">
                    Copy transformers and overrides from
                </label>
            </div>
            <fdx-exports-dropdown
                [ngSelectId]="idPrepend + 'copyExport'"
                class="col-6"
                [setDefaultValue]="false"
                [exportId]="exportItem.copied_from?.id"
                [exportItems]="exportItems"
                [triggers]="triggers"
                [clearable]="true"
                (exportChange)="setExportToCopy($event)">
            </fdx-exports-dropdown>
        </div>
    </div>

    <div class="col-7 d-flex flex-column gap-3">
        <div class="offset-1 hstack gap-3 align-items-start justify-content-between">
            <h4>Export map</h4>
            <div *ngIf="shouldDisplayAddExportFieldsButton">
                <button
                    type="button"
                    name="add-export-fields"
                    class="btn btn-outline-primary"
                    (click)="openExportFieldsOffcanvas()">
                    <fa-icon [icon]="iconPlus"></fa-icon>
                    <span>Add export fields</span>
                </button>
            </div>
        </div>

        <label
            [for]="idPrepend + 'template'"
            class="sr-only">
            Custom / Choose a template
        </label>

        <ng-select
            [id]="idPrepend + 'template'"
            class="offset-1"
            [clearable]="false"
            [items]="presetOptions"
            (change)="changePreset($event)"
            bindLabel="name"
            groupBy="group"
            placeholder="Custom / Choose a template"
            formControlName="preset">
        </ng-select>

        <fdx-export-fields
            [dbFields]="dbFields"
            [(fields)]="exportFields"
            (fieldsChange)="onExportFieldsChange()"
            [showRequired]="true"
            [deltaExport]="exportItem.delta_export"
            [expanded]="showAdvancedOptions">
        </fdx-export-fields>
    </div>

    <div class="col-12 text-center mt-3">
        <button
            type="submit"
            name="submit"
            class="btn btn-primary">
            Add export
        </button>
    </div>
</form>
