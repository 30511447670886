import { Component } from '@angular/core';

@Component({
    selector: 'fdx-logs-export-event-queue',
    templateUrl: './logs-export-event-queue.component.html',
    styleUrls: ['./logs-export-event-queue.component.scss']
})
export class LogsExportEventQueueComponent {

}
