import { Injectable, OnDestroy } from '@angular/core';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { BehaviorSubject } from 'rxjs';


@Injectable({
    providedIn: null
})
export class LogsStateService implements OnDestroy {
    readonly logsRequest$: BehaviorSubject<LogsRequest> = new BehaviorSubject<LogsRequest>(null);
    readonly logsTotalCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    ngOnDestroy(): void {
        this.logsRequest$.complete();
        this.logsTotalCount$.complete();
    }
}
