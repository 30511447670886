/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import AppStateService from '@ajs/services/AppStateService';
import { Injectable } from '@angular/core';
import { CheatSheetContentType } from '@app/modules/cheat-sheet/models/cheat-sheet-content.type';

export type CheatSheetContentGroup = {
    title: string,
    data: CheatSheetContentType[],
    restricted?: boolean
}

export enum CheatSheetTabTitles {
    Intro = 'Intro',
    If = 'If conditions',
    Then = 'Then transformations'
}

export type CheatSheetTab = {
    title: string,
    description: string,
    data: CheatSheetContentGroup[]
}

@Injectable({
    providedIn: 'root'
})
export class CheatSheetService {

    get hasImageProcessing(): boolean {
        return this.appStateService.getAccount()?.hasFeature('image_processing', 'enabled');
    }

    constructor(
        private readonly appStateService: AppStateService
    ) { }

    isTitleOnly(data: CheatSheetContentType): boolean {
        return !data?.description && !data?.examples && !data?.examples;
    }
    // #region INTRO
    /** Definitions **/
    private readonly variable: CheatSheetContentType = {
        title: "variable",
        description: "A database field of data that is being transformed. Database variables are displayed by execution order.",
        hideCopy: true
    };

    private readonly field: CheatSheetContentType = {
        title: "[field]",
        description: "A column or property of the database data. Soemtimes, the terms \"variable\" and \"field\" are used interchangably. Fields are signified by [square brackets].",
        hideCopy: true
    };

    private readonly field_order: CheatSheetContentType = {
        title: "field order",
        description: "The sequence that transformers for each variable field are executed. Sometimes, one field should be transformed after another field is transformed in order to work properly. This can be useful, if you first generate a [material] field from the [description] using one transformer, and then use that [material] field to generate the [title] in another transformer. In this case, the [title] field must execute after the [material] field.",
        hideCopy: true
    };

    private readonly string: CheatSheetContentType = {
        title: "'string'",
        description: "A type of data named for data values that are made up of ordered sequences of characters, such as 'hello world'. Strings start and end with single-quotes, like 'this'. They can contain any sequence of characters, visible or invisible, and characters may be repeated. Strings can be empty as denoted by '', or two single-quotes with nothing between them.",
        hideCopy: true
    };

    private readonly numbers: CheatSheetContentType = {
        title: "numbers",
        description: "Integers or decimals signified as plain text without single-quotes.",
        hideCopy: true
    };

    private readonly spaces_and_new_lines: CheatSheetContentType = {
        title: "spaces and new lines",
        description: "In general, spaces and new lines are ignored unless used within ' ', so feel free to use new lines and spaces to increase readability.",
        hideCopy: true
    };

    private readonly comment: CheatSheetContentType = {
        title: "# this is a comment",
        description: "Include a comment in a transformer by inserting # at the beginning of a line. Anything after the # will be ignored until the end of a line unless the # is part of another valid token like a string: '#1 Dad Coffee Mug'.",
        hideCopy: true
    };
    /** Definitions **/
    // #endregion

    // #region IF_CONDITIONS
    /** Text **/
    private readonly equal: CheatSheetContentType = {
        title: "[field] equal 'string'",
        description: "Selects rows with column values that equal a particular string. Strings can be either static, e.g. [field] equal 'string', or dynamic from a field, e.g. [field1] equal [field2]."
    };

    private readonly equal_any: CheatSheetContentType = {
        title: "[field] equal_any ('string1', 'string2')",
        description: "Selects rows with column values that equal a particular string from a list. Strings can be either static, e.g. [field] equal_any ('string'), or dynamic from a field, e.g. [field1] equal_any ([field2])."
    };

    private readonly not_equal: CheatSheetContentType = {
        title: "[field] not_equal 'string'",
        description: "Selects rows with column values that do not equal a particular string. Strings can be either static, e.g. [field] not_equal 'string', or dynamic from a field, e.g. [field1] not_equal [field2]."
    };

    private readonly contains: CheatSheetContentType = {
        title: "[field] contains 'string'",
        description: "Selects rows with column values that contain a particular string. Strings can be either static, e.g. [field] contains 'string', or dynamic from a field, e.g. [field1] contains [field2]. Add '_s' like 'contains_s' to make the transformer case-sensitive. Note, 'contains' does not take into account word boundaries. This means that 'Los Angeles' contains 'angel' is true. To respect word boundaries use contains_word."
    };

    private readonly contains_any: CheatSheetContentType = {
        title: "[field] contains_any ('string1', 'string2')",
        description: "Selects rows with column values that contain a particular string from a list. Strings can be either static, e.g. [field] contains_any ('string'), or dynamic from a field, e.g. [field1] contains_any ([field2]). Note, 'contains_any' does not take into account word boundaries. This means that 'Los Angeles' contains_any ('angel') is true. To respect word boundaries use contains_word."
    };

    private readonly contains_all: CheatSheetContentType = {
        title: "[field] contains_all ('string1', 'string2')",
        description: "Selects rows with column values that contain all particular strings from a list. Strings can be either static, e.g. [field] contains_all ('string'), or dynamic from a field, e.g. [field1] contains_all ([field2]). Note, 'contains_all' does not take into account word boundaries. This means that 'Los Angeles' contains_all ('angel') is true. To respect word boundaries use contains_word."
    };

    private readonly contains_word: CheatSheetContentType = {
        title: "[field] contains_word 'string'",
        description: "Selects rows with column values that contain the provided word. A word is a string surrounded by spaces or punctuation. Strings can be either static, e.g. [field] contains_word 'string', or dynamic from a field, e.g. [field1] contains_word [field2]. "
    };

    private readonly contains_words: CheatSheetContentType = {
        title: "[field] contains_words 'string1 string2'",
        description: "Selects rows with column values that contain all of the provided words, or strings surrounded by spaces or punctuation, as a space-delimited list. Strings can be either static, e.g. [field] contains_words 'string', or dynamic from a field, e.g. [field1] contains_words [field2]."
    };

    private readonly not_contains: CheatSheetContentType = {
        title: "[field] not_contains 'string'",
        description: "Selects rows with column values that do not contain a particular string. Strings can be either static, e.g. [field] not_contains 'string', or dynamic from a field, e.g. [field1] not_contains [field2]. Add '_s' like 'not_contains_s' to make the transformer case-sensitive."
    };

    private readonly begins_with: CheatSheetContentType = {
        title: "[field] begins_with 'string'",
        description: "Selects rows with column values that begin with a particular string. Strings can be either static, e.g. [field] begins_with 'string', or dynamic from a field, e.g. [field1] begins_with [field2]. "
    };

    private readonly not_begins_with: CheatSheetContentType = {
        title: "[field] not_begins_with 'string'",
        description: "Selects rows with column values that do not begin with a particular string. Strings can be either static, e.g. [field] not_begins_with 'string', or dynamic from a field, e.g. [field1] not_begins_with [field2]. "
    };

    private readonly ends_with: CheatSheetContentType = {
        title: "[field] ends_with 'string'",
        description: "Selects rows with column values that end with a particular string. Strings can be either static, e.g. [field] ends_with 'string', or dynamic from a field, e.g. [field1] ends_with [field2]. "
    };

    private readonly not_ends_with: CheatSheetContentType = {
        title: "[field] not_ends_with 'string'",
        description: "Selects rows with column values that do not end with a particular string. Strings can be either static, e.g. [field] not_ends_with 'string', or dynamic from a field, e.g. [field1] not_ends_with [field2]. "
    };

    private readonly if_str_len: CheatSheetContentType = {
        title: "str_len( [field] ) > 70",
        description: "Outputs the length of the string. Use this in combination with math comparisons to evaluate the length of the string, e.g. If str_len( [description] ) > 5000."
    };

    private readonly after: CheatSheetContentType = {
        title: "[field] after current_time()",
        description: "Selects rows with column date values that occur after a given time, e.g. If [sale_end_date] after current_time()."
    };

    private readonly before: CheatSheetContentType = {
        title: "[field] before current_time()",
        description: "Selects rows with column date values that occur before a given time, e.g. If [sale_start_date] before current_time()."
    };

    private readonly if_match: CheatSheetContentType = {
        title: "match( 'regex', [field], offset, 'pattern_modifiers' )",
        description: "Selects rows with column values that match a regular expression (regex). Regex is a sequence of characters that specifies a search pattern in a text. For more help with regex, please search online or contact us."
    };

    private readonly is_empty: CheatSheetContentType = {
        title: "[field] is_empty",
        description: "Selects rows with column values that are empty, e.g. empty strings, blanks, ''."
    };

    private readonly is_not_empty: CheatSheetContentType = {
        title: "[field] is_not_empty",
        description: "Selects rows with column values that are not empty, e.g. not an empty string, not blank, not ''."
    };
    /** Text **/

    /** Boolean/Groupings **/
    private readonly not: CheatSheetContentType = {
        title: "NOT",
        description: "Negates the proceeding statement, e.g. NOT [product_type] equal 'shoes'."
    };

    private readonly and: CheatSheetContentType = {
        title: "AND",
        description: "Selects rows that match both clauses, e.g. 'this' AND 'that'."
    };

    private readonly or: CheatSheetContentType = {
        title: "OR",
        description: "Selects rows that match either clause, e.g. 'this' OR 'that'."
    };

    private readonly group_parenthesis: CheatSheetContentType = {
        title: "()",
        description: "Parentheses are used to group logical clauses. They are useful to narrow query results and make the criteria of a complex query easier to read and track during query construction. Use parentheses to make the intention of the query clearer. This becomes very important when using AND and OR clauses, to ensure your queries return the exact subsets desired. If one has an AND statement and a number of logically related OR statements, then one should always use parentheses to group the OR statements together, e.g. 'this' AND ( 'that' OR 'the other' )."
    };
    /** Boolean/Groupings **/
    // #endregion

    // #region THEN_TRANSFORMATIONS
    /** HTML **/
    private readonly decode_html_entities: CheatSheetContentType = {
        title: "decode_html_entities( [field] )",
        description: "Convert HTML entities to their corresponding characters. Some files will have encoded characters, which often happens with XML files. Since XML uses the characters < and > to create tags, any strings that need < or > in them will represent then as encoded characters which look like &gt; and &lt;. For example, raw data value 'Barney &amp; Sons &lt; Mother&#39;s Recipe' would decode to 'Barney & Sons > Mother's Recipe'. If you want to remove HTML characters thereafter, then add another transformer for nohtml( [field] )."
    };

    private readonly extract_html_list_items: CheatSheetContentType = {
        title: "extract_html_list_items( [field], 'separator', start_offset, length )",
        description: "Extracts all &lt;li&gt; elements of a [field] into a delimited list.",
        parameters: [
            {
                name: "separator",
                required: "no",
                defaultValue: "&lt;br /&gt;",
                description: "Character that will separate list items."
            },
            {
                name: "offset",
                required: "no",
                defaultValue: "0",
                description: "Position in list to begin from, starting at 0."
            },
            {
                name: "length",
                required: "no",
                defaultValue: "null",
                description: "Count of how many items from the list to return. Omit this field to return all items after provided offset."
            }
        ],
        examples: [
            {
                value: "'&lt;div&gt;Sizes:&lt;ul&gt;&lt;li&gt;S&lt;/li&gt;&lt;li&gt;M&lt;/li&gt;&lt;li&gt;L&lt;/li&gt;&lt;li&gt;XL&lt;/li&gt;&lt;/ul&gt;&lt;/div&gt;'",
                input: "extract_html_list_items( [field] )",
                output: "S&lt;br /&gt;M&lt;br /&gt;L&lt;br /&gt;XL&lt;br /&gt;"
            },
            {
                value: "'&lt;div&gt;Sizes:&lt;ul&gt;&lt;li&gt;S&lt;/li&gt;&lt;li&gt;M&lt;/li&gt;&lt;li&gt;L&lt;/li&gt;&lt;li&gt;XL&lt;/li&gt;&lt;/ul&gt;&lt;/div&gt;'",
                input: "extract_html_list_items( [field], \",\", 1 )",
                output: "M,L,XL"
            },
            {
                value: "'&lt;div&gt;Sizes:&lt;ul&gt;&lt;li&gt;S&lt;/li&gt;&lt;li&gt;M&lt;/li&gt;&lt;li&gt;L&lt;/li&gt;&lt;li&gt;XL&lt;/li&gt;&lt;/ul&gt;&lt;/div&gt;'",
                input: "extract_html_list_items( [field], \",\", 2, 1 )",
                output: "L"
            },
            {
                value: "'&lt;div&gt;Sizes:&lt;ul&gt;&lt;li&gt;S&lt;/li&gt;&lt;li&gt;M&lt;/li&gt;&lt;li&gt;L&lt;/li&gt;&lt;li&gt;XL&lt;/li&gt;&lt;/ul&gt;&lt;/div&gt;'",
                input: "extract_html_list_items( [field], \",\", 2, 2 )",
                output: "L,XL"
            }
        ]
    };

    private readonly nohtml: CheatSheetContentType = {
        title: "nohtml( [field], 'allowed_tags' )",
        description: "Strips all HTML tags from a [field]. The optional field 'allowed_list_optional' will prevent specific tags from being stripped.",
        parameters: [
            {
                name: "allowed_tags",
                required: "no",
                defaultValue: "",
                description: "List of tags that will not be replaced."
            }
        ],
        examples: [
            {
                value: "'&lt;div&gt;This product is &lt;strong&gt;amazing&lt;/strong&gt;.&lt;/div&gt;'",
                input: "nohtml( [field] )",
                output: "'This product is amazing.'"
            },
            {
                value: "'&lt;div&gt;This product is &lt;strong&gt;amazing&lt;/strong&gt;.&lt;/div&gt;'",
                input: "nohtml( [field], '&lt;div&gt;&lt;i&gt;' )",
                output: "'&lt;div&gt;This product is &lt;i&gt;amazing&lt;/i&gt;.&lt;/div&gt;'"
            }
        ]
    };

    private readonly repair_html: CheatSheetContentType = {
        title: "repair_html( [field] )",
        description: "Will fix errors with HTML formatting for the specified [field].",
        parameters: [],
        examples: [
            {
                value: "'&lt;div&gt;This is &lt;i&gt;some&lt;/i&gt; HTML.'",
                input: "repair_html( [field] )",
                output: "'&lt;div&gt;This is &lt;i&gt;some&lt;/i&gt; HTML.&lt;/div&gt;'"
            }
        ]
    };
    /** HTML **/

    /** Text case **/
    private readonly lcase: CheatSheetContentType = {
        title: "lcase( [field] )",
        description: "Converts all alphabetic characters to lowercase.",
        parameters: [],
        examples: [
            {
                value: "'BLUE'",
                input: "lcase( [field] )",
                output: "'blue'"
            }
        ]
    };

    private readonly lcase_first: CheatSheetContentType = {
        title: "lcase_first( [field] )",
        description: "Converts first character to lowercase.",
        parameters: [],
        examples: [
            {
                value: "'BLUE'",
                input: "lcase_first( [field] )",
                output: "'bLUE'"
            }
        ]
    };

    private readonly tcase: CheatSheetContentType = {
        title: "tcase( [field] )",
        description: "Converts the first character of each word to uppercase, i.e. title case.",
        parameters: [],
        examples: [
            {
                value: "'Our favorite classic tee'",
                input: "tcase( [field] )",
                output: "'Our Favorite Classic Tee'"
            }
        ]
    };

    private readonly ucase: CheatSheetContentType = {
        title: "ucase( [field] )",
        description: "Converts all alphabetic characters to uppercase.",
        parameters: [],
        examples: [
            {
                value: "'blue'",
                input: "ucase( [field] )",
                output: "'BLUE'"
            }
        ]
    };

    private readonly ucase_first: CheatSheetContentType = {
        title: "ucase_first( [field] )",
        description: "Converts first character of the string to uppercase, i.e. sentence case.",
        parameters: [],
        examples: [
            {
                value: "'blue'",
                input: "ucase_first( [field] )",
                output: "'Blue'"
            }
        ]
    };
    /** Text case **/

    /** Text modification **/
    private readonly filter_items_from_list: CheatSheetContentType = {
        title: "filter_items_from_list( [field], 'delimiter', 'query_to_keep' )",
        description: "Filters from a list using regex pattern match to isolate any matching strings out of a field. Useful, for example, to extract images out of comma separated fields. Try also using csv_get() to get an item from the list using index instead of regex."
    };

    private readonly flip_values: CheatSheetContentType = {
        title: "flip_values( [field], 'delimiter' )",
        description: "Reverses the order of a delimited list of values. Useful in combination with sort_values() to both sort and reverse the order of a list of items."
    };

    private readonly deduplicate_words: CheatSheetContentType = {
        title: "deduplicate_words( [field] )",
        description: "Eliminate duplicate or redundant words in a field. Useful for reducing unhelpful duplicate words in titles. \"Words\" are character strings separated by spaces. If whole phrases are meant to be kept together, substitute the spaces in the phrases with another character, use the deduplicate transformer, and substitute spaces back in.<br><br>Note, punctuation characters are considered part of the word which would make it unique from the same word without punctuation. For example, 'word.' and 'word' appearing in the same field will not deduplicate because they are different words: w - o - r - d - . versus w - o  - r - d<br><br>If there are other types of white spaces (e.g. non-break spaces, new lines that render in the platform looking like spaces, etc.), they are treated as \"word\" characters. For example, \"word word\" won't deduplicate if the white space character is something other than a regular space. In fact, the whole thing is treated as a single word: w–o–r–d-[ ]-w–o–r–d"
    };

    private readonly repeat_str: CheatSheetContentType = {
        title: "repeat_str( [field], 'times' )",
        description: "Repeats a 'string', 'character', or a 'string' from a [field] the specified number of times. For example, \"If [color] = 'Red', Then repeat_str( [color], '3' ) = 'RedRedRed'.\""
    };

    private readonly replace: CheatSheetContentType = {
        title: "replace( 'search_for', 'replace_with', [field] )",
        description: "Replace all occurrences of the search string, case-sensitive, with the replacement string. Add '_i' like 'replace_i' to make the transformer case-insensitive. Use either static strings or fields to dynamically search or replace strings. Note, 'replace' does not take into account word boundaries. This means that replacing 'male' with 'men' will also replace the string found in 'female' as 'femen'. To respect word boundaries use the replace_exact() transformer."
    };

    private readonly replace_i: CheatSheetContentType = {
        title: "replace_i( 'search_for', 'replace_with', [field] )",
        description: "Like replace(), this transformer can replace all occurrences of the search string with the replacement string, but it is case-insensitive. Remove the '_i' in 'replace_i' to make the transformer case-sensitive. Use either static strings or fields to dynamically search or replace strings. Note, 'replace' does not take into account word boundaries. This means that replacing 'male' with 'men' will also replace the string found in 'female' as 'femen'. To respect word boundaries use the replace_exact() transformer."
    };

    private readonly replace_exact: CheatSheetContentType = {
        title: "replace_exact( 'search_for', 'replace_with', [field] )",
        description: "Replace all whole-word occurrences of the search string with the replacement string. Note, 'replace_exact' is case-sensitive. It also uses word boundaries to limit matches of the search string by performing a \"whole words only\" search using a regular expression in the form of <strong>word</strong> to ignore word boundaries use the replace() transformer."
    };

    private readonly replace_pattern: CheatSheetContentType = {
        title: "replace_pattern( 'regex', 'replace_with', [field] )",
        description: "Replace all occurrences of a match to the provided regular expression (regex) pattern with the replacement string, case-sensitive. Use either static strings or fields to dynamically search or replace strings. Regex is a sequence of characters that specifies a search pattern in a text. For more help with regex, please search online or contact us."
    };

    private readonly separate_words: CheatSheetContentType = {
        title: "separate_words( [field], 'separator' )",
        description: "Separates two conjoined words where the second word starts with a capital letter, e.g. \"blueShirt\" can become \"blue. Shirt\".",
        parameters: [
            {
                name: "separator",
                required: "no",
                defaultValue: "'. '",
                description: "Sets the string that will be used between words."
            },
        ],
        examples: [
            {
                value: "'Machine washDo not dry cleanDo not iron'",
                input: "separate_words( [field] )",
                output: "'Machine wash. Do not dry clean. Do not iron'"
            },
            {
                value: "'Machine washDo not dry cleanDo not iron'",
                input: "separate_words( [field], ', ' )",
                output: "'Machine wash, Do not dry clean, Do not iron'"
            },
            {
                value: "'Machine washDo not dry cleanDo not iron'",
                input: "separate_words( [field], '&lt;br /&gt;' )",
                output: "'Machine wash&lt;br /&gt;Do not dry clean&lt;br /&gt;Do not iron'"
            }
        ]
    };

    private readonly sort_values: CheatSheetContentType = {
        title: "sort_values( [field], 'delimiter' )",
        description: "Sort a delimited lsit using a \"natural order\" algorithm that orders alphanumeric strings in the way a human being would while maintaining key/value associations. This is described as a \"natural ordering\". This transformer is case-insensitive. Note, if two members compare as equal, they retain their original order. <br><br>Example:<br>Input: '1|5|12|3|8|4|33|10'<br>Transformer: sort_values( [field], '|' )<br>Output: '1|3|4|5|8|10|12|33'"
    };

    private readonly strip_backslash: CheatSheetContentType = {
        title: "strip_backslash( [field] )",
        description: "Removes all backslash ( \\ ) characters."
    };

    private readonly transliterate_to_ascii: CheatSheetContentType = {
        title: "transliterate_to_ascii( [field] )",
        description: "Outputs data as ASCII. Useful to get rid of special characters that may not be acceptable to the destination system."
    };
    /** Text modification **/

    /** Text query **/
    private readonly csv_get: CheatSheetContentType = {
        title: "csv_get( [field], index, 'separator', 'enclosure', 'escape' )",
        description: "Outputs the value at the specified column index from a delimited field.",
        parameters: [
            {
                name: "index",
                required: "yes",
                defaultValue: "",
                description: "The 0-based column index that contains the desired value."
            },
            {
                name: "separator",
                required: "no",
                defaultValue: ",",
                description: "Sets the string delimiter."
            },
            {
                name: "enclosure",
                required: "no",
                defaultValue: "\"",
                description: "Sets the string enclosure character."
            },
            {
                name: "escape",
                required: "no",
                defaultValue: "\\",
                description: "Sets the string escape character."
            },
        ],
        examples: [
            {
                value: "'red,blue,green'",
                input: "csv_get( [field], 2 )",
                output: "'green'"
            },
            {
                value: "'\"red\"|\"blue\"|\"green\"'",
                input: "csv_get( [field], 1, '|', '\"' )",
                output: "'blue'"
            },
            {
                value: "'\"red\"|\"a color I like to call -\"blue-\"\"|\"green\"'",
                input: "csv_get( [field], 1, '|', '\"', '-' )",
                output: "'blue'"
            }
        ]
    };

    private readonly index_of: CheatSheetContentType = {
        title: "index_of( [field], 'search_for', offset )",
        description: "Outputs the position of the 'search_of' string in the given [field] starting by default at the beginning of the string value unless provided with the optional offset starting position. <br><br>For example, given [field] value of 'ababc' transformed by index_of( [string], 'a', 2 ), the output is '2'. This is because the search starts at position 2 of the [string] value. The output is the index of the next 'a' that is found which is 2 because the second 'a' is in position two where the index starts at 0.<br>0 1 2 3 4<br>a b a b c"
    };

    private readonly json_get: CheatSheetContentType = {
        title: "json_get( [json_field], 'json_key' )",
        description: "Outputs a value associated to the provided key from a JSON object.<br><br>Note, The JSON has to be perfectly formatted with all keys and values enclosed in double-quotes, key-values pairs separated by commas, and the whole JSON enclosed in curly braces, or else the whole field is unreadable to json_get(). The parameter 'json_key' is case-sensitive.<br><br>Useful when data contains a JSON object. For example, a [json_field_name] value of { \"color\":\"Blue\", \"size\":\"Medium\", \"material\":\"100% Cotton\", \"Pattern\":\"Solid\" } transformed by json_get( [json_field], 'size' ) outputs 'Medium\"."
    };

    private readonly then_match: CheatSheetContentType = {
        title: "match( 'regex', [field], offset, 'pattern_modifiers' )",
        description: "Selects rows with column values that match a regular expression (regex). Regex is a sequence of characters that specifies a search pattern in a text. For more help with regex, please search online or contact us."
    };

    private readonly str_len: CheatSheetContentType = {
        title: "str_len( [field] )",
        description: "Outputs the length of the string. Use this in combination with math comparisons to evaluate the length of the string, e.g. If str_len( [description] ) > 5000."
    };

    private readonly word_count: CheatSheetContentType = {
        title: "word_count( [field], 'separator' )",
        description: "Outputs the number of items before and/or after the provided separator. If separator is alphabetic, it is case-sensitive."
    };
    /** Text query **/

    /** Text substring **/
    private readonly substring: CheatSheetContentType = {
        title: "substring( [field], start_offset, length )",
        description: "Outputs data from the provided field from the point of the start_offset, starting at 0 not 1, to the set length amount. Like saying, \"take the data in this field starting at this character position and output this many characters thereafter.\"<br><br>For example, data has TV sizes in the 2nd and 3rd characters in [mpn]. To extract TV sizes based on their model numbers, an input value 'B4245' transformed by substring( [mpn], 1, 2 ) 'in' outputs '42 in'.<br><br>Also useful for any invalid GTIN values of 9-13 digits (not 14 digits in length) where substring can to pad it with the appropriate number of zeros to get it up to 14 digits. For example, IF str_len( [gtin] ) >= 9 AND str_len( [gtin] ) < 14, THEN substring( '00000', 0, 14 - str_len( [gtin] ) ) [gtin]."
    };

    private readonly left: CheatSheetContentType = {
        title: "left( [field], 'text_to_stop_before' )",
        description: "Outputs a string from a [field] to the left of the provided string for 'text_to_stop_before'. If the 'text_to_stop_before' occurs more than once, then by default the transformer uses the first instance of the 'text_to_stop_before' found. Optionally, define the numbered instance (starting at 0) of the 'text_to_stop_before', i.e. take everything left of the 3rd '>'.<br><br>Examples<ul><li>left ( [field_name], 'text_to_stop_before' )</li><li>left ( [field_name], 'text_to_stop_before', offset_number )</li></ul>Alternatively, output a string from a [field] to the left of the provided \"length_number\", i.e. provide an integer number instead of a 'text_to_stop_before' string.<br><br>Example<ul><li>left ( [field_name], length_number )</li></ul>"
    };

    private readonly left_s: CheatSheetContentType = {
        title: "left_s( [field], 'text_to_stop_before' )",
        description: "The case-sensitive version of left()."
    };

    private readonly leftNoTrunc: CheatSheetContentType = {
        title: "leftNoTrunc( [field], 150 )",
        description: "Outputs a defined number of characters to the left of the provided \"length_number\" and does not truncate the middle of a word."
    };

    private readonly right: CheatSheetContentType = {
        title: "right( [field], 'text_to_start_after' )",
        description: "Outputs a string from a [field] to the right of the provided string for 'text_to_start_after'. If the 'text_to_start_after' occurs more than once, then by default the transformer uses the first instance of the 'text_to_start_after' found.  Optionally, define the numbered instance (starting at 0) of the 'text_to_start_after', i.e. take everything right of the 3rd '>'.<br><br>Examples<ul><li>right ( [field_name], 'text_to_start_after' )</li><li>right ( [field_name], 'text_to_start_after', offset_number )</li></ul>Alternatively, Outputs a string from a [field] to the right of the provided \"length_number\", i.e. provide an integer number instead of a 'text_to_start_after' string. <br><br>Example<ul><li>right ( [field_name], length_number )</li></ul>"
    };

    private readonly right_s: CheatSheetContentType = {
        title: "right_s( [field], 'text_to_start_after' )",
        description: "The case-sensitive version of right()."
    };

    private readonly rightNoTrunc: CheatSheetContentType = {
        title: "rightNoTrunc( [field], 150 )",
        description: "Outputs a defined number of characters to the right of the provided \"length_number\" and does not truncate the middle of a word."
    };

    private readonly mid: CheatSheetContentType = {
        title: "mid( [field], 'txt_start', 'txt_stop' )",
        description: "Outputs a string from a [field] between the provided 'txt_start' and the 'txt_stop' strings.<br><br>For example, if a value of 'Id4420 Computer Part' where the desired output is the number 4420 between the word 'Id' and the word 'Computer', then a transformer such as \"mid( [title], 'Id', 'Computer' )\" would output '4420'."
    };

    private readonly mid_s: CheatSheetContentType = {
        title: "mid_s( [field], 'txt_start', 'txt_stop' )",
        description: "The case-sensitive version of mid()."
    };
    /** Text substring **/

    /** Character type **/
    private readonly is_alpha: CheatSheetContentType = {
        title: "is_alpha( [field], 'true_value_output', 'false_value_output' )",
        description: "Determines if the value contains only alpha characters.  By default, will output the [field] if the value is alpha, or '' if it is not alpha.",
        parameters: [
            {
                name: "true_value_output",
                required: "no",
                defaultValue: "[field]",
                description: "The specific value to output if true."
            },
            {
                name: "false_value_output",
                required: "no",
                defaultValue: "''",
                description: "The specific value to output if false."
            },
        ],
        examples: [
            {
                value: "'Hello'",
                input: "is_alpha( [field] )",
                output: "'Hello'"
            },
            {
                value: "'7'",
                input: "is_alpha( [field] )",
                output: "''"
            },
            {
                value: "'XL'",
                input: "is_alpha( [field], 'Alpha', '' )",
                output: "'Alpha'"
            },
            {
                value: "'spaces exist'",
                input: "is_alpha( [field], 'Alpha', '' )",
                output: "''"
            },
        ]
    };

    private readonly is_alphanumber: CheatSheetContentType = {
        title: "is_alphanumber( [field], 'true_value_output', 'false_value_output' )",
        description: "Determines if the value contains only alpha or numeric characters.  By default, will output the [field] if the value is alphanumeric, or '' if it is not alphanumeric.",
        parameters: [
            {
                name: "true_value_output",
                required: "no",
                defaultValue: "[field]",
                description: "The specific value to output if true."
            },
            {
                name: "false_value_output",
                required: "no",
                defaultValue: "''",
                description: "The specific value to output if false."
            },
        ],
        examples: [
            {
                value: "'Hello'",
                input: "is_alphanumber( [field] )",
                output: "'Hello'"
            },
            {
                value: "'7'",
                input: "is_alphanumber( [field] )",
                output: "'7'"
            },
            {
                value: "'42.00'",
                input: "is_alphanumber( [field] )",
                output: "''"
            },
            {
                value: "'$4.98'",
                input: "is_alphanumber( [field] )",
                output: "''"
            },
            {
                value: "'3T'",
                input: "is_alphanumber( [field], 'Alphanumeric', '' )",
                output: "'Alphanumeric'"
            },
            {
                value: "'Our best product!'",
                input: "is_alphanumber( [field], '', 'Contains non-aplhanumeric' )",
                output: "'Contains non-aplhanumeric'"
            },
        ]
    };

    private readonly is_number: CheatSheetContentType = {
        title: "is_number( [field], 'true_value_output', 'false_value_output' )",
        description: "Determines if the value contains only numeric characters.  By default, will output the [field] if the value is numeric, or '' if it is not numeric.",
        parameters: [
            {
                name: "true_value_output",
                required: "no",
                defaultValue: "[field]",
                description: "The specific value to output if true."
            },
            {
                name: "false_value_output",
                required: "no",
                defaultValue: "''",
                description: "The specific value to output if false."
            },
        ],
        examples: [
            {
                value: "'Hello'",
                input: "is_number( [field] )",
                output: "''"
            },
            {
                value: "'7'",
                input: "is_number( [field] )",
                output: "'7'"
            },
            {
                value: "'-14.5'",
                input: "is_number( [field] )",
                output: "'-14.5'"
            },
            {
                value: "'36'",
                input: "is_number( [field], 'Numeric', 'Alpha' )",
                output: "'Numeric'"
            },
            {
                value: "'XL'",
                input: "is_number( [field], 'Numeric', 'Alpha' )",
                output: "'Alpha'"
            }
        ]
    };
    /** Character type **/

    /** Whitespace **/
    private readonly collapse_spaces: CheatSheetContentType = {
        title: "collapse_spaces( [field] )",
        description: "Replaces any instance of more than one repeated space with one space. <br><br>Sometimes there may be multiple spaces somewhere in a field (not just the beginning or end). This can be taken care of with the collapse_spaces transformer, which (you guessed it!) collapses the spaces into a single space. If you have data that looks like this in your field: \"I'll     get you,     my      pretty, and       your       little     dog,      too!\" If you apply the collapse_spaces( [field] ) transformer, your transformed data will look like this: \"I'll get you, my pretty, and your little dog, too!\""
    };

    private readonly trim_spaces: CheatSheetContentType = {
        title: "trim_spaces( [field] )",
        description: "Strip whitespace from the beginning and end of a string."
    };
    /** Whitespace **/

    /** Date **/
    private readonly current_time: CheatSheetContentType = {
        title: "current_time()",
        description: "Return the current Unix timestamp in UTC timezone, the number of seconds since the Unix Epoch (January 1 1970 00:00:00 GMT). This function can be used in both IF conditions and THEN functions.",
        parameters: [],
        examples: [
            {
                input: "THEN current_time()",
                output: "'1666191000'"
            },
            {
                input: "IF current_time() before '2022-07-27T00:00 PDT'",
                output: "<i>Transformer only runs before July 27th, 2022 at midnight PDT</i>"
            }
        ]
    };

    private readonly format_date: CheatSheetContentType = {
        title: "format_date( [field], 'Y-m-d H:i' )",
        description: "Returns date formatted according to the given format. The default timezone is Eastern Time (ET) and changes with daylight savings. You can prevent a recognized character in the format string from being expanded by escaping it with a preceding backslash, e.g. adding \"GMT\" as the timezone include \"\\G\\M\\T\" otherwise the capital M is recognized as the abbreviated month format.",
        parameters: [
            {
                name: "format string",
                required: "yes",
                defaultValue: "N/A",
                description: "The format of the outputted date string. Acceptable DateTime formatting options are found in the PHP manual online."
            }
        ],
        examples: [
            {
                value: "'6/11/2020 3:00:00 AM'",
                input: "format_date( [field], 'Y-m-d H:i' )",
                output: "'2020-06-11 03:00'"
            },
            {
                value: "'2000-01-01'",
                input: "format_date( [field], 'Y-m-d H:i:s' )",
                output: "'2000-01-01 00:00:00'"
            },
            {
                value: "'1666192937'",
                input: "format_date( [field], 'l, d-M-Y H:i:s T' )",
                output: "'Sunday, 13-Apr-1975 05:22:17 EDT'"
            },
            {
                value: "'1666192937'",
                input: "format_date( [field], 'l jS \\o\\f F Y h:i:s A' )",
                output: "'Wednesday 19th of October 2022 11:22:17 AM'"
            },
            {
                value: "'03/24/2022'",
                input: "format_date( [field], 'Y-m-d H:i:s \\G\\M\\T' )",
                output: "'2022-03-24 00:00:00 GMT'"
            }
        ]
    };

    private readonly parse_date: CheatSheetContentType = {
        title: "parse_date( [field] )",
        description: "Parse any English textual datetime description into a Unix timestamp. The default timezone is Eastern Time (ET) and changes with daylight savings.",
        parameters: [],
        examples: [
            {
                value: "'2020-12-7 14:54'",
                input: "parse_date( [field] )",
                output: "'1607370840'"
            },
            {
                value: "'Monday, March 24, 2022 2:33:14 AM'",
                input: "parse_date( [field] )",
                output: "'1648103594'"
            },
            {
                value: "'02/14/18 7:02 pm'",
                input: "parse_date( [field] )",
                output: "'1518652920'"
            }
        ]
    };
    /** Date **/

    /** Math **/
    private readonly math_comparison: CheatSheetContentType = {
        title: "&gt;, &lt;, &gt;=, &lt;=",
        description: "Used to determine whether a number is smaller, greater, or equal to another number according to its values. The symbols used for comparing numbers are \"&gt;\", which means \"greater than\"; \"&lt;\", which means \"less than\"; \"&gt;=\" which means \"greater than or equal to\"; and \"&lt;=\", which means \"less than or equal to\"."
    };

    private readonly math_operation: CheatSheetContentType = {
        title: "+, -, *, /",
        description: "Used to calculate elementary arithmetic, such as \"+\", plus (addition); \"-\", minus (subtraction); \"/\", slash (division); \"x\", times (multiplication); and \"=\" equals (equation)."
    };

    private readonly math_parenthesis: CheatSheetContentType = {
        title: "()",
        description: "Parentheses are used in mathematical expressions to denote modifications to the normal order of operations. The part of the expression within the parentheses is evaluated first, and then this result is used in the rest of the expression."
    };

    private readonly margin: CheatSheetContentType = {
        title: "margin( [field_1], [field_2] )",
        description: "The difference between the price at which a product is sold and the costs associated with making or selling the product (or cost of goods sold). The output is a number as a percentage and it is not rounded. Use the rounding transformer for precise rounding. This transformer uses the following logic: ( ( [field_1] - [field_2] ) / [field_2] ) * 100."
    };

    private readonly maximum: CheatSheetContentType = {
        title: "maximum( [field_1], [field_2], 'value1' )",
        description: "Outputs the highest value from a comparison of fields and values."
    };

    private readonly minimum: CheatSheetContentType = {
        title: "minimum( [field_1], [field_2], 'value1' )",
        description: "Outputs the lowest value from a comparison of fields and values."
    };

    private readonly parse_num: CheatSheetContentType = {
        title: "parse_num( [field] )",
        description: "Outputs numerical data. Useful for both if conditions and then transformers. For example, if [price] contains a currency code like 'USD' then use parse_num( [price] ) > 5 to extract only the number value from [price] and determine if it is higher than 5. Can also be combined with round_number, e.g. '22.442436346 USD' transformed by round_number( parse_num( [price] ), 2 ) outputs 22.44."
    };

    private readonly percent: CheatSheetContentType = {
        title: "percent( [field] )",
        description: "Outputs a number as a percentage including the '%' character. For example, an input of '0.25' transformed by percent( [field] ) outputs '25%'. "
    };

    private readonly round_number: CheatSheetContentType = {
        title: "round_number( [field], 'precision', 'mode' )",
        description: "Round a number to a certain decimal place, or precision, using a specified rounding mode, e.g. up, down, even, odd.",
        parameters: [
            {
                name: "precision",
                required: "yes",
                defaultValue: "N/A",
                description: "The number of decimal digits to round to"
            },
            {
                name: "mode",
                required: "no",
                defaultValue: "'up'",
                description: "The rounding mode, valid values: 'up', 'down', 'even', 'odd', 'ceil', 'floor'"
            }
        ],
        examples: []
    };
    /** Math **/

    /** Image processing */
    private readonly add_overlay: CheatSheetContentType = {
        title: "add_overlay( position_x, position_y, image_url )",
        description: "Image transformer that adds an overlay image on top of the original image at the specified position coodinates."
    };

    private readonly add_padding: CheatSheetContentType = {
        title: "add_padding( max_width, max_height, background_color_optional )",
        description: "Image transformer that adds padding space to the original image at the defined max width and height. Optionally, define the background color to use for the added padding space, otherwise, the default is white. "
    };

    private readonly add_text: CheatSheetContentType = {
        title: "add_text( position_x, position_y, text, options )",
        description: "Image transformer that adds text on top of the original image at the defined position coordinates. Define the font size, color, stroke, and background color. "
    };

    private readonly blur_background: CheatSheetContentType = {
        title: "blur_background( max_width, max_height )",
        description: "Image transformer that adds padding space to the original image at the defined max width and height with a blurred image as the background instead of a solid color like add_padding."
    };

    private readonly extend_background: CheatSheetContentType = {
        title: "extend_background( max_width, max_height )",
        description: "Image transformer that adds padding space to the original image at the defined max width and height with a color best determined by its edges."
    };

    private readonly convert_to_rgb: CheatSheetContentType = {
        title: "convert_to_rgb()",
        description: "Image transformer that converts to an RGB value."
    };

    private readonly crop_image: CheatSheetContentType = {
        title: "crop_image( max_width, max_height, anchor_x_optional, anchor_y_optional )",
        description: "Image transformer that crops the original image at the defined position coordinates and optionally at the defined anchor coordinates."
    };

    private readonly image_info: CheatSheetContentType = {
        title: "image_info( [field], delimiter, flags_optional )",
        description: "Outputs information about the image, such as file type, size, color space, and dimensions. This transformer works differently. Once it is saved, an initial export should be run to queue up all of the generation of image info. This can be a local export as well. It is a one time ordeal, after which this info is readily available. "
    };

    private readonly transform_image: CheatSheetContentType = {
        title: "transform_image( [field], delimiter, transformation_1, transformation_2, ... )",
        description: "Runs the series of image transformations provided. Consider this a wrapper of the desired transformations, i.e. add text and add overlay image to the same image. The field parameter contains the image or images to be transformed. This can be either single URLs or delimited by '|' or ';'."
    };

    private readonly resize: CheatSheetContentType = {
        title: "resize( max_width, max_height, maintain_aspect_ratio )",
        description: "Resizes an image to the given max width and max height, optionally maintaining aspect ratio.",
        parameters: [
            {
                name: "maintain_aspect_ratio",
                required: "no",
                defaultValue: "false",
                description: "Setting to true will maintain the aspect ratio when resizing."
            }
        ]
    };

    private readonly renew: CheatSheetContentType = {
        title: "renew()",
        description: "Renews and regenerates an image in the case where an incorrect function was applied to it, resulting in a bad image."
    };
    /** Image processing */

    /** Miscellaneous */
    private readonly combine_list_of_lists: CheatSheetContentType = {
        title: "combine_list_of_lists( 'input_delimiter', 'columns_delimiter', 'lists_delimiter', [field], [field] )",
        description: "Combine multiple lists into 1 list with each value that was in the same index next to each other.<br><br>Most useful when needing to combine year, make, and model for vehicles. <br><br>Note, one can also use deduplicate_words() on this if first changing the pipe delimiter to space since the deduplication operation deduplicates the words between spaces.<br><br>combine_list_of_lists( 'input_delimiter', 'columns_delimiter', 'lists_delimiter', [field_name], [field_name], [field_name], [field_name], etc... )<br><br>Example:<br>[indices] = '1,2,3,4'<br>[values] = 'dog,cat,mouse,worm'<br><br>[result] = combine_list_of_lists( ',', ':', '|', [sorted_skus_and_values], [index] )<br><br>result = 'dog:1|cat:2|mouse:3|worm:4'"
    };

    private readonly convert_category: CheatSheetContentType = {
        title: "convert_category( [google_shopping_category], 'shopzilla', [gender] )",
        description: "A legacy transformer to lookup a matching category from a given channel for a Google product category. "
    };

    private readonly grab_from_delimited_map: CheatSheetContentType = {
        title: "grab_from_delimited_map( 'key', 'map_string', 'map_delimiter', 'values_string', 'values_delimiter' )",
        description: "Use a key, or string value, to identify the position of an item in one delimited list and pull the corresponding value at the same position from another delimited list. Useful for a special case where the content type of a bunch of attributes are in one field and the values to those attributes are in another.<br><br>Example input:<br>[attribute_names] = 'Size|Color|Material'<br>[attribute_values] = '10|Orange|100% Cotton'<br><br>Transformer:<br>grab_from_delimited_map( 'Color', [attribute_names], '|', [attribute_values], '|' )<br><br>Example output: Orange<br><br>In this case, it is easier to pick an attribute from [attribute_names] and return the value from [attribute_values] using this transformer."
    };

    private readonly parse_color: CheatSheetContentType = {
        title: "parse_color( [field] )",
        description: "Outputs the first matching color word found from the refernce color list. The matching color word must be surrounded by spaces or punctuation. <br><br>For example, If [description] = 'T-Shirt comes in red.', Then parse_color( [description] ) results in the output string of 'red'. <br><br>Colors currently parsed include: red orange, red, light orange, orange, yellow green, yellow orange, yellow, aqua green, green blue, green, navy blue, sky blue, baby blue, light blue, blue violet, blue, white, light brown, dark brown, brown, black, gray, grey, magenta, mahogany, peach, baby pink, pink, tan, salmon, turquoise, raspberry, gold, mango, mauve, pale rose, sand, silver, teal, platinum, azure, beige, blond, crimson, cyan, purple, navy, charcoal"
    };

    private readonly parse_measures: CheatSheetContentType = {
        title: "parse_measures( [field] )",
        description: "Outputs a number and unit of measure found in the 'string' of a [field].<br><br>For example, 'Commission Classic-Fit Short 7 in - Chino Shorts' transformed by replace_i( '\"', 'in', [ai_title] ) outputs '7 in'<br><br>Supported:<br>mm<br>in<br>cm<br>m<br>g<br><br><br>Not supported:<br>oz<br>oz.<br>fl. oz.<br>ft<br>lb<br>km<br>ml (outputs 'm')<br>gal (outputs 'g')<br>inch (outputs 'in')<br>gram (outputs 'g')"
    };

    private readonly unique_id: CheatSheetContentType = {
        title: "unique_id( [field], 'hash_method' )",
        description: "Generates a pseudo-random hexadecimal (0-9a-f) string, 32 characters long. The input can be any combination of fields. Note, unique inputs are required to generate unique ids, i.e. same inputs generate same ids. The output will change if the input value changes, so fields like [id], which aren't typically changed, are safer to use for input if the output needs to not change. The default value is \"md5,\" but you can use \"sha1.\" Using \"sha1\" is a little bit slower than \"md5\", but it is a more secure hashing method. Both are valid hashing methods.<br><br>Useful to conduct A/B testing. For example, once a field is populated with unique id values, subsets with variations of data can be determined by the first character using at transformer if condition like [unique_id_field] begins_with 'a' OR [unique_id_field] begins_with '2'. Probability (for a uniform distribution of 16 possible values) tells us the sample would be about ⅛ of the product set.<br><br>Examples of a group of products that share a landing page (i.e. identical links), but are unique based on combinations of color and size:<br>unique_id( [link] ) = Same id for the whole set<br>unique_id( [link][color] ) = Same ids for products of different sizes with the same color<br>unique_id( [link][color][size] ) = All unique ids",
        parameters: [
            {
                name: "hash_method",
                required: "no",
                defaultValue: "'md5'",
                description: "The hash method to use, valid values: 'md5, 'sha1', 'crc32'"
            }
        ],
        examples: []
    };

    private readonly url_encode: CheatSheetContentType = {
        title: "url_encode( [field] )",
        description: "Useful to add tracking links where the parameter is the destination URL. Also useful for some export formats, like XML, may require this type of encoding to prevent issues with the file formatting. <br><br>For example, https://auth.feedonomics.com/login encodes as https%3A%2F%2Fauth.feedonomics.com%2Flogin."
    };

    private readonly url_decode: CheatSheetContentType = {
        title: "url_decode( [field] )",
        description: "Useful to decode a URL from an XML source. This performs the reverse of url_encode. <br><br>For example, https%3A%2F%2Fauth.feedonomics.com%2Flogin decodes as https://auth.feedonomics.com/login."
    };

    private readonly valid_gtin: CheatSheetContentType = {
        title: "valid_gtin( [field] )",
        description: "Ensures the GTIN is a valid length (8, 12, 13, 14, or 18) and runs the checksum calculation. If the GTIN is invalid, then the value is cleared out as an empty string. Please note, this does not remove the value '000000000000' because it is technically the correct length and passes the checksum calculation..<br><br>This transformer can help identify GTINs which may be of the right length but fail to pass the GTIN check digit test, which means that they're not valid. If the length is correct and the checksum is correct, then that doesn't mean the GTIN is valid for that product, or represents what the product actually is, just that it's a numerically/mathematically valid GTIN."
    };

    private readonly infer_product_id_type: CheatSheetContentType = {
        title: "infer_product_id_type( [field] )",
        description: "Infers product type based on length of the passed ID or if the ID begins with 'B'. An ID length of 12 maps to 'UPC', 13 maps to 'EAN', and 14 maps to 'GTIN'. An ID that begins with 'B' will map to 'ASIN'. An ID that begins with '978' and has a length of 13 characters maps to 'ISBN'.",
        parameters: [],
        examples: [
            {
                value: "'000000000000'",
                input: "infer_product_id_type( [field] )",
                output: "'UPC'"
            },
            {
                value: "'B00AN2KTKQ'",
                input: "infer_product_id_type( [field] )",
                output: "'ASIN'"
            },
            {
                value: "'9781472104908'",
                input: "infer_product_id_type( [field] )",
                output: "'ISBN'"
            }
        ]
    };

    private readonly write_xml: CheatSheetContentType = {
        title: "write_xml( 'tag_name', 'content' )",
        description: "Outputs a line of XML, e.g. <name>Tile</name>"
    };
    /** Miscellaneous */
    // #endregion

    private readonly intro: CheatSheetContentGroup[] = [
        {
            title: 'Definitions',
            data: [
                this.variable,
                this.field,
                this.field_order,
                this.string,
                this.numbers,
                this.spaces_and_new_lines,
                this.comment
            ]
        },
    ];

    private readonly if: CheatSheetContentGroup[] = [
        {
            title: 'Text',
            data: [
                this.equal,
                this.not_equal,
                this.equal_any,
                this.contains,
                this.contains_any,
                this.contains_all,
                this.contains_word,
                this.contains_words,
                this.not_contains,
                this.begins_with,
                this.not_begins_with,
                this.ends_with,
                this.not_ends_with,
                this.if_str_len,
                this.after,
                this.before,
                this.if_match,
                this.is_empty,
                this.is_not_empty
            ]
        },
        {
            title: 'Boolean/groupings',
            data: [
                this.not,
                this.and,
                this.or,
                this.group_parenthesis
            ]
        }
    ];

    private readonly then: CheatSheetContentGroup[] = [
        {
            title: 'HTML',
            data: [
                this.decode_html_entities,
                this.extract_html_list_items,
                this.nohtml,
                this.repair_html,
            ]
        },
        {
            title: 'Text case',
            data: [
                this.lcase,
                this.lcase_first,
                this.tcase,
                this.ucase,
                this.ucase_first,
            ]
        },
        {
            title: 'Text modification',
            data: [
                this.filter_items_from_list,
                this.flip_values,
                this.deduplicate_words,
                this.repeat_str,
                this.replace,
                this.replace_i,
                this.replace_exact,
                this.replace_pattern,
                this.separate_words,
                this.sort_values,
                this.strip_backslash,
                this.transliterate_to_ascii,
            ]
        },
        {
            title: 'Text query',
            data: [
                this.csv_get,
                this.index_of,
                this.json_get,
                this.then_match,
                this.str_len,
                this.word_count
            ]
        },
        {
            title: 'Text substring',
            data: [
                this.substring,
                this.left,
                this.left_s,
                this.leftNoTrunc,
                this.right,
                this.right_s,
                this.rightNoTrunc,
                this.mid,
                this.mid_s
            ]
        },
        {
            title: 'Character type',
            data: [
                this.is_alpha,
                this.is_alphanumber,
                this.is_number
            ]
        },
        {
            title: 'Whitespace',
            data: [
                this.collapse_spaces,
                this.trim_spaces
            ]
        },
        {
            title: 'Date',
            data: [
                this.current_time,
                this.format_date,
                this.parse_date
            ]
        },
        {
            title: 'Math',
            data: [
                this.math_comparison,
                this.math_operation,
                this.math_parenthesis,
                this.margin,
                this.maximum,
                this.minimum,
                this.parse_num,
                this.percent,
                this.round_number,
            ]
        },
        /* {
            title: 'Image processing',
            data: [
                this.add_overlay,
                this.add_padding,
                this.add_text,
                this.blur_background,
                this.extend_background,
                this.convert_to_rgb,
                this.crop_image,
                this.image_info,
                this.transform_image,
                this.resize,
                this.renew
            ],
            restricted: this.hasImageProcessing
        }, */
        {
            title: 'Miscellaneous',
            data: [
                this.combine_list_of_lists,
                this.convert_category,
                this.grab_from_delimited_map,
                this.parse_color,
                this.parse_measures,
                this.unique_id,
                this.url_decode,
                this.url_encode,
                this.valid_gtin,
                this.infer_product_id_type,
                this.write_xml,
            ]
        }
    ];

    public tabs: CheatSheetTab[] = [
        {
            title: CheatSheetTabTitles.Intro,
            description: "<div class=\"mb-1\">Use Feedonomics Query Language (FQL) to write powerful queries or transformers. Queries, or selectors, are used to view specific subsets of rows in a database. Transformers allow you to add IF and THEN rules to optimize your raw data into transformed data.</div><a target=\"_blank\" href=\"https://feedonomics.docebosaas.com/learn/course/29/play/123/Introduction%2Bto%2BQueries%2Band%2BTransformers\">Learn more in FeedAcademy: Intro to Queries and Transformers</a>",
            data: this.intro
        },
        {
            title: CheatSheetTabTitles.If,
            description: "The condition that makes the \"THEN\" statement execute. For example, \"IF [sale_end_date] after current_time() AND [sale_start_date] before current_time()\" would be true while the current time is within the sale start and end dates.",
            data: this.if
        },
        {
            title: CheatSheetTabTitles.Then,
            description: "This is the change being made to the variable. All 'elements' in a transformer get concatanated together to produce the new output for the selected field. Accepted elements: 'strings', [fields], funcions(), arithmetic, numbers, and #comments. In general, spaces and new lines are ignored unless used within ' ', so feel free to use new lines and spaces to increase readability.",
            data: this.then
        }
    ];
}
