<ng-template #content>
    <div class="d-flex gap-2">
        <fdx-spinner *ngIf="isLoading" size="sm"></fdx-spinner>
        <fa-icon *ngIf="!isLoading && (iconOverride || themeType.icon)" [icon]="iconOverride || themeType.icon"></fa-icon>
        <div class="d-flex flex-column gap-2">
            <div class="text-start">
                <span
                    *ngIf="title"
                    class="alert-title">
                    {{title}}
                </span>

                <span *ngIf="body; else ngContent" [innerHTML]="body | prettyHtml" fdxStopPropagation></span>
                <ng-template #ngContent>
                    <span fdxStopPropagation><ng-content></ng-content></span>
                </ng-template>
            </div>
            <div *ngIf="extraContent" fdxStopPropagation>
                <ng-container *ngTemplateOutlet="extraContent"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ngb-alert [type]="theme" [ngClass]="alertClasses" [dismissible]="dismissible" (closed)="close()">
    <button *ngIf="clickable; else notClickable" type="button" class="unstyled-button w-100" (click)="alertClicked()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <ng-template #notClickable>
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-template>
</ngb-alert>
