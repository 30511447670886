<table class="table d-block w-100 overflow-auto" *ngIf="logs.length > 0">
    <thead>
        <tr>
            <!-- <th>Time</th>
            <th>Status</th>
            <th>Channel</th>
            <th>Total products</th>
            <th>Total errors</th>
            <th>Error rate</th> -->
            <th *ngFor="let data of logs[0] | keyvalue; trackBy: trackByIndex">{{data.key}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let log of logs; trackBy: trackByIndex">
            <td *ngFor="let data of log | keyvalue; trackBy: trackByIndex">{{data.value}}</td>
        </tr>
    </tbody>
</table>
