import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name:'durationFormat',
})

export class DurationPipe implements PipeTransform {

    transform(duration: number, ...args: any[]) {
        var hrs = ~~(duration / 3600);
        var min = ~~((duration % 3600)/60);
        var sec = Math.round(duration % 60);

        return `${hrs ? hrs + ' hr ': ''} ${min ? min + ' min ' : ''} ${sec  ? sec + ' sec ': ''}`
    }
}