import { AccountFeatureFlag } from '@app/core/models/enums/account-feature-flag.enum';
import { AccountDataModel, AccountPermissions } from './AccountDataModel';

export class AccountModel implements AccountDataModel {
    account_name: string;
    default_technical_support_email: string;
    features: Record<string, string>;
    id: number;
    logo: string;
    permissions: AccountPermissions;

    constructor(data: AccountDataModel) {
        for (const [key, val] of Object.entries(data)) {
            this[key] = val;
        }
    }

    hasFeature(feature: string, value: string): boolean {
        if (!this.features) {
            return false;
        }

        return this.features[feature] === value;
    }

    hasFeatureEnabled(feature: AccountFeatureFlag): boolean {
        return this.hasFeature(feature, 'enabled');
    }

    hasAccountLevelAccess() {
        return !(typeof this.permissions === 'undefined' || this.permissions === null);
    }

}
