<div class="d-flex align-items-center">
    <ng-select
        [id]="ngSelectId"
        [formControl]="exportFormCtrl"
        [clearable]="clearable"
        [placeholder]="exportsDropdownPlaceholder"
        [items]="exports"
        [searchFn]="exportSearch"
        [trackByFn]="trackExport"
        class="flex-grow-1">

        <ng-template
            ng-label-tmp
            let-item="item"
            let-clear="clear">
            <ng-container
                [ngTemplateOutlet]="exportEntry"
                [ngTemplateOutletContext]="{ export: item }">
            </ng-container>
        </ng-template>

        <ng-template
            ng-option-tmp
            let-item="item">
            <ng-container
                [ngTemplateOutlet]="exportEntry"
                [ngTemplateOutletContext]="{ export: item }">
            </ng-container>
        </ng-template>
    </ng-select>
</div>

<ng-template
    #exportEntry
    let-export="export">
    <fa-icon
        [icon]="exportItemIcon(export)"
        [ngClass]="exportItemIconClass(export)"
        size="xs"
        class="me-1"></fa-icon>
    <span
        [title]="export.name"
        class="titled-text">
        {{export.name}}
    </span>
</ng-template>
