import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CategoryPathsModalComponent } from '@app/analyze-data/components/category-paths-modal/category-paths-modal.component';
import { CountryDropdownOption } from '@app/analyze-data/components/column-stats/column-stats.component';
import { COLUMN_STATS_EMPTY } from '@app/analyze-data/constants/analyze-data.constants';
import { CategoryPathsUtilitiesService } from '@app/analyze-data/services/category-paths-utilities.service';
import { ChannelOptionsType } from '@app/analyze-data/types/channel-options.type';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export type CategoryPathCellType = {
    value: string,
    categoryPaths: CategoryPath[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CategoryPathsCellRendererParams<T = any, V = string> = ICellRendererParams<T, V> & ICategoryPathsCellRendererParams;

export interface ICategoryPathsCellRendererParams {
    channel: ChannelOptionsType,
    country: CountryDropdownOption,
    config: CategoryPathCellType;
    invalid?: boolean;
    onErrorIconClick?: (event: MouseEvent) => void;
}

/**
 * Use `ChangeDetectionStrategy.OnPush` for faster performance.
 * Call `this.changeDetectorRef.markForCheck();` in `agInit()` and `refresh()` methods.
 */
@Component({
    selector: 'fdx-category-paths-cell-renderer',
    templateUrl: './category-paths-cell-renderer.component.html',
    styleUrls: ['./category-paths-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryPathsCellRendererComponent implements ICellRendererAngularComp {

    channel?: ChannelOptionsType;
    country?: CountryDropdownOption;
    config?: CategoryPathCellType;
    invalid?: boolean;

    readonly blankItem: string = COLUMN_STATS_EMPTY;

    private params?: ICategoryPathsCellRendererParams;

    hasErrorIconClickCallback: boolean = false;

    get displayedValue(): string {
        return this.categoryPathsUtilitiesService.getDisplayedValue(this.config);
    }

    get displayedPath(): string {
        return this.categoryPathsUtilitiesService.getDisplayedPath(this.config.categoryPaths[0]);
    }

    get shouldDisplayPathInline(): boolean {
        return this.categoryPathsUtilitiesService.shouldDisplayPathInline(this.country, this.config);
    }

    get shouldDisplayPathModal(): boolean {
        return this.categoryPathsUtilitiesService.shouldDisplayPathModal(this.country, this.config);
    }

    get pathIcon(): IconDefinition {
        return this.categoryPathsUtilitiesService.pathIcon;
    }

    get errorIcon(): IconDefinition {
        return this.categoryPathsUtilitiesService.errorIcon;
    }

    constructor(
        private readonly categoryPathsUtilitiesService: CategoryPathsUtilitiesService,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly modalService: ModalService
    ) { }

    agInit(params: CategoryPathsCellRendererParams): void {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Returning true tells AG Grid not to destroy and recreate the cell renderer component.
     * When returning true, you must handle changes to `params` yourself.
     * @param params cell params
     * @returns true to handle refresh logic yourself in Angular or false for AG Grid to destroy and recreate the component
     */
    refresh(params: CategoryPathsCellRendererParams): boolean {
        this.bindParams(params);
        this.changeDetectorRef.markForCheck();
        return true;
    }

    private bindParams(params: CategoryPathsCellRendererParams): void {
        this.params = params;

        this.channel = params.channel;
        this.country = params.country;
        this.config = params.config;
        this.invalid = params.invalid;

        if (this.invalid && typeof (params.onErrorIconClick) === 'function') {
            this.hasErrorIconClickCallback = true;
        }
    }

    openPathModal(): void {
        this.modalService.open(CategoryPathsModalComponent, {
            resolve: <ICategoryPathsCellRendererParams>{
                channel: this.channel,
                country: this.country,
                config: this.config
            },
            size: 'lg',
            scrollable: true
        });
    }

    errorIconClick($event: MouseEvent): void {
        if (this.hasErrorIconClickCallback) {
            this.params.onErrorIconClick($event);
        }
    }
}
