import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives/directives.module';
import { BasicTransformerFieldComponent } from '@app/modules/inputs/components/basic-transformer-field/basic-transformer-field.component';
import { BasicTransformerComponent } from '@app/modules/inputs/components/basic-transformer/basic-transformer.component';
import { CodeInputComponent } from '@app/modules/inputs/components/code-input/code-input.component'; // might need to be setup as child of base input component
import { CollapsibleCodeComponent } from '@app/modules/inputs/components/collapsible-code/collapsible-code.component';
import { DatepickerComponent } from '@app/modules/inputs/components/datepicker/datepicker.component';
import { ExportSelectComponent } from '@app/modules/inputs/components/export-select/export-select.component';
import { FormBuilderComponent } from '@app/modules/inputs/components/form-builder/form-builder.component';
import { FormFieldComponent } from '@app/modules/inputs/components/form-field/form-field.component';
import { InputCheckboxComponent } from '@app/modules/inputs/components/input-checkbox/input-checkbox.component';
import { InputFieldOrValueComponent } from '@app/modules/inputs/components/input-field-or-value/input-field-or-value.component';
import { InputFileComponent } from '@app/modules/inputs/components/input-file/input-file.component';
import { InputGroupAddonsComponent } from '@app/modules/inputs/components/input-group-addons/input-group-addons.component';
import { InputRadioComponent } from '@app/modules/inputs/components/input-radio/input-radio.component';
import { InputWrapperComponent } from '@app/modules/inputs/components/input-wrapper/input-wrapper.component';
import { InputComponent } from '@app/modules/inputs/components/input/input.component';
import { LabelComponent } from '@app/modules/inputs/components/label/label.component';
import { TextareaComponent } from '@app/modules/inputs/components/textarea/textarea.component';
import { ValidationComponent } from '@app/modules/inputs/components/validation/validation.component';
import { NgSelectTemplatesModule } from '@app/modules/ng-select-templates/ng-select-templates.module';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { SpinnerComponent } from '@app/standalone-components/spinner/spinner.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { NgSelectModule } from '@feedonomics/ng-select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbAccordionModule, NgbDatepickerModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InputNumberComponent } from './components/input-number/input-number.component';

@NgModule({
    declarations: [
        BasicTransformerComponent,
        BasicTransformerFieldComponent,
        CodeInputComponent,
        CollapsibleCodeComponent,
        DatepickerComponent,
        ExportSelectComponent,
        FormBuilderComponent,
        FormFieldComponent,
        InputCheckboxComponent,
        InputComponent,
        InputFieldOrValueComponent,
        InputFileComponent,
        InputGroupAddonsComponent,
        InputNumberComponent,
        InputRadioComponent,
        InputWrapperComponent,
        LabelComponent,
        TextareaComponent,
        ValidationComponent
    ],
    imports: [
        CodemirrorModule,
        CommonModule,
        DirectivesModule,
        DragDropModule,
        FontAwesomeModule,
        FormsModule,
        NgbAccordionModule,
        NgbDatepickerModule,
        NgbPopoverModule,
        NgbTooltipModule,
        NgSelectModule,
        NgSelectTemplatesModule,
        PipesModule,
        ReactiveFormsModule,
        SpinnerComponent
    ],
    exports: [
        BasicTransformerComponent,
        BasicTransformerFieldComponent,
        CodeInputComponent,
        CollapsibleCodeComponent,
        DatepickerComponent,
        ExportSelectComponent,
        FormBuilderComponent,
        FormFieldComponent,
        InputCheckboxComponent,
        InputComponent,
        InputFieldOrValueComponent,
        InputFileComponent,
        InputGroupAddonsComponent,
        InputNumberComponent,
        InputRadioComponent,
        InputWrapperComponent,
        LabelComponent,
        TextareaComponent,
        ValidationComponent
    ]
})
export class InputsModule { }
