import AppStateService from '@ajs/services/AppStateService';
import fdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@app/core-legacy/abstract/base.component';
// import { SortComparison } from '@app/core/helpers/sort-comparison';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { Export } from '@app/exports/services/responses/get-exports.response';
import { FtpTriggerFieldModel } from '@app/ftp-trigger/models/ftp-trigger-field.model';
import { FtpTriggerDataService } from '@app/ftp-trigger/services/ftp-trigger-data.service';
import { GetFtpTriggersResponse } from '@app/ftp-trigger/services/responses/get-ftp-triggers.response';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { faCircle as faCircleOutline } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { forkJoin, takeUntil } from 'rxjs';

// TODO: Remove this component and swap with export-select when we get a chance

export interface AugmentedExport extends Export {
    hasFtpTrigger: boolean;
}

@Component({
    selector: 'fdx-exports-dropdown',
    styleUrls: ['./exports-dropdown.component.scss'],
    templateUrl: './exports-dropdown.component.html'
})
export class ExportsDropdownComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() ngSelectId: string = 'export-dropdown';
    @Input() label: string = 'Choose export:';
    @Input() exportId: string;
    @Input() exportItems: Export[]; // optional input to limit data fetches
    @Input() setDefaultValue = true;
    @Input() triggers: GetFtpTriggersResponse; // optional input to limit data fetches
    @Input() clearable: boolean = false;
    @Input() bindChangeAutomatically: boolean = true;
    @Output() exportChange = new EventEmitter<AugmentedExport>();

    exports: AugmentedExport[] = [];
    exportItem: AugmentedExport;
    initialized: boolean = false;

    exportFormCtrl: FormControl<AugmentedExport> = new FormControl<AugmentedExport>(null);

    constructor(
        private appStateService: AppStateService,
        private exportsDataService: ExportsDataService,
        private ftpTriggerDataService: FtpTriggerDataService,
        private fdxUI: fdxUI,
        private orderByPipe: OrderByPipe
    ) {
        super();
    }

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get exportsDropdownPlaceholder(): string {
        return this.exports.length > 0 ? 'Choose an export' : 'This database has no exports yet';
    }

    ngOnChanges({ exportId, exportItems }: SimpleChanges): void {
        if (this.initialized) {
            const didExportIdChange = exportId?.currentValue && exportId.currentValue !== exportId.previousValue;
            const didExportItemsChange = exportItems?.currentValue && exportItems.currentValue !== exportItems.previousValue;

            if (didExportItemsChange) {
                this.loadExports();
            } else if (didExportIdChange && this.exports) {
                setTimeout(() => {
                    this.setInitialExport(this.exports, exportId.currentValue);
                }, 0);

            }
        }
    }

    ngOnInit(): void {
        this.initialized = true;
        this.loadExports();
        this.trackChanges();
    }

    exportHasFtpTrigger(exportItem: Export, ftpTriggers: FtpTriggerFieldModel[]): boolean {
        return ftpTriggers.some(({ export_id }) => {
            return export_id === exportItem.id;
        });
    }

    showGreenDot(exportItem: AugmentedExport): boolean {
        return !!exportItem.cron || exportItem.hasFtpTrigger;
    }

    exportItemIcon(exportItem: AugmentedExport): IconDefinition {
        return this.showGreenDot(exportItem) ? faCircle : faCircleOutline;
    }

    exportItemIconClass(exportItem: AugmentedExport): string {
        return this.showGreenDot(exportItem) ? 'active-circle' : 'inactive-circle';
    }

    loadExports(): void {
        if (this.exportItems && this.triggers) {
            this.augmentExports(this.exportItems, this.triggers);
        } else {
            forkJoin([
                this.exportsDataService.getExports(this.databaseId),
                this.ftpTriggerDataService.getFtpTriggers(this.databaseId)
            ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ([exports, ftpTriggers]) => {
                    this.augmentExports(exports, ftpTriggers);
                },
                this.showToastError.bind(this)
            );
        }
    }

    augmentExports(exports, ftpTriggers): void {
        this.exports = this.processExports(exports, ftpTriggers);
        if (this.exports.length >= 1) {
            this.exportFormCtrl.enable();
        } else {
            this.exportFormCtrl.disable();
        }


        if (this.setDefaultValue) {
            this.setInitialExport(this.exports, this.exportId);
        }
    }

    processExports(exports: Export[], ftpTriggers: FtpTriggerFieldModel[]): AugmentedExport[] {
        const _exports = exports.map((exportItem) => {
            return {
                ...exportItem,
                hasFtpTrigger: this.exportHasFtpTrigger(exportItem, ftpTriggers)
            };
        });

        return this.orderByPipe.transform(_exports, ['id == 0', 'name']);
    }

    setInitialExport(exports: AugmentedExport[], exportId: string): void {
        if (!exports.length) {
            return;
        }

        let exportItem = exports.find(({ id }) => {
            return id === exportId;
        });

        if (exportItem === undefined) {
            exportItem = exports[0];
        }

        this.exportItem = exportItem;
        this.exportFormCtrl.setValue(exportItem, {emitEvent: false});
        this.exportChange.emit(exportItem);
    }

    showToastError(error: HttpErrorResponse): void {
        this.fdxUI.showToastError(error.error);
    }

    trackChanges(): void {
        this.exportFormCtrl.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
            next: (value) => {
                if (this.bindChangeAutomatically) {
                    this.exportItem = value;
                } else {
                    this.exportFormCtrl.reset(
                        this.exportItem,
                        {
                            emitEvent: false
                        }
                    );
                }

                this.exportChange.emit(value);
            }
        });
    }

    protected exportSearch(term: string, exprt: Export): boolean {
        const normalized = term.toLowerCase();
        return exprt.name.toLowerCase().includes(normalized);
    }

    protected trackExport(exprt: Export): string {
        return exprt.id;
    }
}
