import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DataValidationApiService } from '@app/analyze-data/services/data-validation-api.service';
import { DataValidationWithSummaryResponse } from '@app/analyze-data/services/responses/data-validation.response';
import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { ErrorService } from '@app/core/services/error.service';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { ExportFieldsOffcanvasComponent, ExportFieldsOffcanvasResolver } from '@app/exports/components/export-fields-offcanvas/export-fields-offcanvas.component';
import { ImportTemplateStringModalComponent } from '@app/exports/components/import-template-string-modal/import-template-string-modal.component';
import { WebhookType } from '@app/exports/data/webhook-type';
import { ExportProtocol } from '@app/exports/enums/export.enums';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { ExportSchedule } from '@app/exports/models/interfaces/export-schedule.interface';
import { presetOptions } from '@app/exports/pages/exports-page/preset-options';
import { AggregatedDbFields, CodeInputService } from '@app/exports/services/code-input.service';
import { ExportField } from '@app/exports/services/responses/get-exports.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { OffcanvasService } from '@app/modules/offcanvas/services/offcanvas.service';
import { faCircle, faPlus, faQuestionCircle, faRetweet, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { catchError, EMPTY, merge, Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-export-config-form-existing',
    styleUrls: ['export-config-form-existing.component.scss'],
    templateUrl: 'export-config-form-existing.component.html'

})
export class ExportConfigFormExistingComponent implements OnInit, OnChanges, OnDestroy {
    @Input() editExportError: string;
    @Input() dbFields: DbFieldModel[] = [];
    @Input() dbSortableFields: { name: string; source: string; value: string; }[];
    @Input() exportItem: ExistingExport;
    @Input() exportSaved: boolean;
    @Output() readonly editExport: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly exportChange: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();
    @Output() readonly webhookTypeChange: EventEmitter<WebhookType> = new EventEmitter<WebhookType>();
    @Output() readonly scheduleUpdated: EventEmitter<ExportSchedule> = new EventEmitter<ExportSchedule>();

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    aggregatedDbFields: AggregatedDbFields;
    exportFieldsTotalCount = 0;
    exportFieldsMappedCount = 0;
    readonly iconHelp: IconDefinition = faQuestionCircle;
    readonly iconArrowCycle: IconDefinition = faRetweet;
    readonly iconPlus: IconDefinition = faPlus;
    readonly solidCircle: IconDefinition = faCircle;
    form: UntypedFormGroup;
    presetOptions;
    showAdvancedOptions = false;
    validationStatus: { status: 'loading' | 'complete' | 'error' | 'noTaxonomy' | 'invalidCategory', errors?: string[] } = {
        status: 'noTaxonomy',
        errors: []
    }

    cancelTaxonomyCalls$: Subject<void> = new Subject<void>();

    get idPrepend(): string {
        if (this.exportItem?.id) {
            return `export${this.exportItem.id}-`
        }

        return 'existingExport-';
    }

    get shouldDisplayAddExportFieldsButton(): boolean {
        return this.exportItem.protocol === ExportProtocol.Webhook;
    }

    get modified(): boolean {
        return this.exportItem.modified;
    }

    get submitDisabled(): boolean {
        return this.exportItem.export_running || this.exportItem.submit_running;
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly codeInputService: CodeInputService,
        private readonly fdxUI: FdxUI,
        private readonly fdxUtilsService: FdxUtilsService,
        private readonly fb: UntypedFormBuilder,
        private readonly modalService: ModalService,
        private readonly offcanvasService: OffcanvasService,
        private readonly dataValidationApiService: DataValidationApiService,
        private readonly errorService: ErrorService
    ) {
        this.presetOptions = this.fdxUtilsService.clone(presetOptions).filter(option => {
            if (option.name === 'ShopStyle SKU Feed (NDJSON)' && this.accountHasFeature('shopstyle_export_template', 'enabled')) {
                option.isVisible = true;
            }
            return option.isVisible;
        });
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            copiedFrom: [null],
            importDgqTemplates: [false],
            preset: [null]
        });

        setTimeout(() => {
            this.form.valueChanges
                .pipe(
                    tap((changes) => {
                        const parsedChanges = Object.entries(changes).reduce((result, [key, val]: [string, Record<string, any>]) => {
                            if (key === 'importDgqTemplates' || key === 'copiedFrom' || key === 'preset') {
                                result[key] = val;
                            } else if (key === 'exportOptions') {
                                const exportOptions = this.extractStringDigitsFromBooleans(val as Record<string, unknown>, [
                                    'deltaExport',
                                    'includeColumnNames',
                                    'showEmptyTags',
                                    'showEmptyParentTags',
                                    'quotedFields',
                                    'deduplicatePreserveNulls',
                                    'useCdata',
                                    'xmlWriteDocumentTag'
                                ]);

                                result = {
                                    ...result,
                                    ...val,
                                    ...exportOptions
                                } as Record<string, any>;
                            } else {
                                result = {
                                    ...result,
                                    ...val
                                } as Record<string, any>;
                            }

                            return result;
                        }, {} as Record<string, any>);
                        this.exportChange.emit(parsedChanges);
                    }),
                    takeUntil(this.unsubscribe$)
                ).subscribe();
        }, 0);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('dbFields' in changes && changes.dbFields.currentValue !== changes.dbFields.previousValue) {
            this.aggregatedDbFields = this.codeInputService.aggregateDbFields(changes.dbFields.currentValue);
            this.onExportFieldsChange(!changes.dbFields.firstChange);
        }

        if ('exportItem' in changes && changes.exportItem.currentValue?.id !== changes.exportItem.previousValue?.id) {
            this.onExportFieldsChange(false);
            this.getTaxonomyData();
        } else if ('exportSaved' in changes && changes.exportSaved.currentValue === true) {
            this.getTaxonomyData();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.cancelTaxonomyCalls$.next();
        this.cancelTaxonomyCalls$.complete();
    }

    onSubmit(_e: SubmitEvent): void {
        if (!this.modified) {
            return;
        }

        if (this.submitDisabled) {
            return;
        }

        this.editExport.emit();
    }

    accountHasFeature = (feature, value) => this.appStateService.getAccount().hasFeature(feature, value);

    addCredentialsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportCredentials', formGroup);
    }

    addExportIfForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportIf', formGroup);
    }

    addExportOptionsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportOptions', formGroup);
    }

    addExportRetriesForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportRetries', formGroup);
    }

    addExportTagsForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('exportTags', formGroup);
    }

    addExportTimeoutForm(formGroup: UntypedFormGroup): void {
        this.addFormGroup('timeout', formGroup);
    }

    addFormGroup(controlName: string, formGroup: UntypedFormGroup): void {
        this.form.addControl(controlName, formGroup);
    }

    booleanToStringDigit(bool: boolean): '1' | '0' {
        return bool ? '1' : '0';
    }

    extractStringDigitsFromBooleans(obj: Record<string, unknown>, keys: string[]): Record<string, '1' | '0'> {
        return keys.reduce((result, key) => {
            const val = obj[key];

            if (typeof val === 'boolean') {
                result[key] = this.booleanToStringDigit(val);
            }

            return result;
        }, {});
    }

    onExportFieldsChange(triggerModified: boolean = true): void {
        this.exportFieldsMappedCount = 0;
        this.exportFieldsTotalCount = 0;

        for (const field of this.exportItem.export_fields) {
            if (field.export_field_name !== '') {
                this.exportFieldsTotalCount++;

                if (field.field_name) {
                    this.exportFieldsMappedCount++;
                }
            }
        }

        this.updateItemGroupIdRows();

        if (triggerModified) {
            this.exportItem.modified = true;
        }
    }


    updateItemGroupIdRows(): void {

        const fields: { name: string, value: string; }[] = [];

        if (Array.isArray(this.exportItem.export_fields)) {
            this.exportItem.export_fields.forEach((field) => {
                if (field.export_field_name) {
                    fields.push({
                        name: field.export_field_name,
                        value: field.export_field_name
                    });
                }
            });
        }

        const itemGroupFields = fields;

        const fieldStillExists = itemGroupFields.some((item, index) => {

            const matches = typeof item !== 'undefined' && item.name === this.exportItem.item_group_id;

            if (matches) {
                this.exportItem.item_group_id = itemGroupFields[index].value;
            }

            return matches;

        });

        if (!fieldStillExists && itemGroupFields.length) {
            this.exportItem.item_group_id = itemGroupFields[0].value;
        }

    }

    onExportOptionsChange(triggerModified: boolean = true): void {
        if (triggerModified) {
            this.exportItem.modified = true;
        }
    }

    openImportTemplateStringModal(): void {
        this.modalService.open(ImportTemplateStringModalComponent, {
            size: 'lg',
            resolve: {
                exportId: this.exportItem?.id,
                exportFields: this.exportItem.export_fields
            }
        })
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((exportFields) => {
                this.exportItem.export_fields = exportFields;
            });
    }

    openExportFieldsOffcanvas(): void {
        this.offcanvasService.open(ExportFieldsOffcanvasComponent, {
            position: 'end',
            resolve: <ExportFieldsOffcanvasResolver>{
                dbFields: this.dbFields,
                exportFields: this.exportItem.export_fields,
                missingFields: this.validationStatus.errors,
                exportSelector: this.exportItem.export_selector ?? 'true',
                destinationMetadata: this.exportItem.destination_metadata
            }
        }).closed.pipe(
            tap((newFields: ExportField[]) => {
                this.fdxUI.showToastSuccess(`${newFields.length} export fields added`);

                this.exportItem.export_fields = this.exportItem.export_fields.concat(newFields);
                this.onExportFieldsChange();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    updateCurrentSchedule(cron: ExportSchedule): void {
        this.scheduleUpdated.emit(cron);
    }

    getTaxonomyData(): void {
        this.cancelTaxonomyCalls$.next();

        this.validationStatus = {
            status: 'loading',
            errors: []
        }

        // As of right now, we don't need any of the exports rows from the call, so minimizing the payload size.
        this.dataValidationApiService.validateTaxonomyWithSummary(this.exportItem.db_id, this.exportItem.id, { offset: 0, limit: 0}).pipe(
            tap((taxonomy: DataValidationWithSummaryResponse) => {
                if (this.isInvalidCategory(taxonomy)) {
                    this.validationStatus = {
                        status: 'invalidCategory',
                        errors: [taxonomy.category_field]
                    };
                    return;
                }
                this.validationStatus = {
                    status: 'complete',
                    errors: Object.keys(taxonomy.missing_from_export) ?? []
                }
            }),
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse && error.status === 400 && error.error.message === 'Can not recognize marketplace for this export') {
                    this.validationStatus = {
                        status: 'noTaxonomy',
                        errors: []
                    }
                } else {
                    this.errorService.handleError(error, {toast: false});
                    this.validationStatus = {
                        status: 'error',
                        errors: []
                    }
                }
                return EMPTY;
            }),
            // Allows us to stop a previous call when an export is changed,
            // alongside the normal unsubscribe.
            takeUntil(merge(this.cancelTaxonomyCalls$, this.unsubscribe$))
        ).subscribe();
    }

    taxonomyErrorMessage(): string {
        if (this.validationStatus.status === 'complete') {
            return `${this.validationStatus.errors.length} missing`;
        }
        return null;
    }

    taxonomyErrorStatus(): BootstrapThemeProp | null {
        if (this.validationStatus.status === 'complete' && this.validationStatus.errors.length > 0) {
            return 'danger';
        }

        if (this.validationStatus.status === 'complete' && this.validationStatus.errors.length === 0) {
            return 'secondary';
        }
    }

    /**
     * Handles the backend being unable to enumerate all of the missing fields when the category is
     * mapped entirely to an empty column.
     * @param data The validation summary
     * @returns {boolean} Is the category mapped to an empty column
     */
    private isInvalidCategory(data: DataValidationWithSummaryResponse): boolean {
        if (Array.isArray(data.missing_from_export) && data.missing_from_export.length > 0) {
            return false;
        }
        const category = data.category_field;
        const erroredCategory = data.taxonomy_summary.find(
            (summaryItem) => {
                return summaryItem.column_name === category;
            }
        );
        return erroredCategory && erroredCategory.issue_count === data.total_count;
    }
}
