import { Component } from '@angular/core';

@Component({
    selector: 'fdx-logs-transformers',
    templateUrl: './logs-transformers.component.html',
    styleUrls: ['./logs-transformers.component.scss']
})
export class LogsTransformersComponent {

}
