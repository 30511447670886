import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { LocalStorageService } from '@app/core/services/local-storage.service';
import { DatabasesDataService } from '@app/databases/services/databases-data.service';
import { ExportConstants } from '@app/exports/constants/exports.constants';
import { ExportsDataService } from '@app/exports/services/exports-data.service';
import { Export, GetExportsResponse } from '@app/exports/services/responses/get-exports.response';
import { FtpTriggerFieldModel } from '@app/ftp-trigger/models/ftp-trigger-field.model';
import { CodeMirrorConstants } from '@app/modules/inputs/constants/code-mirror.constants';
import { CodeMirrorOptionsModel } from '@app/modules/inputs/models/code-mirror-options.model';
import { AggregatedDbFieldsType } from '@app/modules/inputs/types/aggregated-db-fields.type';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { QueryInputsComponent } from '@app/modules/query/components/query-inputs/query-inputs.component';
import { TransformerInputsComponent } from '@app/modules/query/components/transformer-inputs/transformer-inputs.component';
import { TransformersDeleteModalComponent } from '@app/transformers/components/transformers-delete-modal/transformers-delete-modal.component';
import { TransformerConstants } from '@app/transformers/constants/transformer.constants';
import { SelectorRowOption } from '@app/transformers/enums/selector-row-option.enum';
import { TransformerDbFieldModel } from '@app/transformers/models/transformer-db-field.model';
import { TransformerDisplayModel } from '@app/transformers/models/transformer-display.model';
import { TransformerFieldDependencyModel } from '@app/transformers/models/transformer-field-dependency.model';
import { TransformerModel } from '@app/transformers/models/transformer.model';
import { TransformerRequest } from '@app/transformers/services/requests/transformer.request';
import { DbFieldsTransformerCountResponse } from '@app/transformers/services/responses/db-fields-transformer-count.response';
import { TransformerUtilitiesService } from '@app/transformers/services/transformer-utilities.service';
import { TransformersDataService } from '@app/transformers/services/transformers-data.service';
import { IconDefinition, faBoltLightning, faColumns, faSortAlt } from '@fortawesome/pro-solid-svg-icons';
import { Subject, distinctUntilChanged, pairwise, startWith, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-transformer',
    templateUrl: './transformer.component.html',
    styleUrls: ['./transformer.component.scss']
})
export class TransformerComponent implements OnInit, OnChanges, OnDestroy {

    get accountId(): string {
        return this.appStateService.getAccountId() as string;
    }

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    processingAction: boolean = false;

    @Input() mode: 'normal' | 'defer' = 'normal';

    @Input() initialField?: TransformerDbFieldModel;
    @Input() initialFieldName?: string;

    allDbFields: TransformerDbFieldModel[] = [];
    @Input() dbFields: AggregatedDbFieldsType = {
        autocompleteFields: [],
        validFields: []
    };

    @Input() exports?: Export[];
    @Input() triggers: FtpTriggerFieldModel[];
    @Input() transformersLength?: number;
    @Output() readonly transformerAction: EventEmitter<void> = new EventEmitter<void>();
    @Output() readonly addTransformerRequested: EventEmitter<TransformerRequest> = new EventEmitter<TransformerRequest>();
    @Input() transformer: TransformerDisplayModel;
    @Input() transformerFieldDependency: TransformerFieldDependencyModel;

    @Input() onTransformersPage?: boolean = true;

    codeMirrorOptions: CodeMirrorOptionsModel = {
        ...CodeMirrorConstants.defaultOptions,
        placeholder: ''
    };

    @ViewChild(QueryInputsComponent) readonly queryInputsComponent: QueryInputsComponent;
    @ViewChild(TransformerInputsComponent) readonly transformerInputsComponent: TransformerInputsComponent;

    // eslint-disable-next-line @typescript-eslint/typedef
    advancedSelectorGroup = new FormGroup({
        advancedSelector: new FormControl<string>('')
    });
    // eslint-disable-next-line @typescript-eslint/typedef
    basicSelectorGroup = new FormGroup({});

    // eslint-disable-next-line @typescript-eslint/typedef
    advancedTransformerGroup = new FormGroup({
        advancedTransformer: new FormControl<string>('')
    });
    // eslint-disable-next-line @typescript-eslint/typedef
    basicTransformerGroup = new FormGroup({});

    // eslint-disable-next-line @typescript-eslint/typedef
    selectorForm = new FormGroup({
        advancedSelectorGroup: this.advancedSelectorGroup,
        basicSelectorGroup: this.basicSelectorGroup
    });

    // eslint-disable-next-line @typescript-eslint/typedef
    currentFieldSelect = new FormControl<TransformerDbFieldModel>(null);

    get currentField(): TransformerDbFieldModel {
        return this.initialField ? this.initialField : this.currentFieldSelect?.value;
    }

    // eslint-disable-next-line @typescript-eslint/typedef
    tForm = new FormGroup({
        currentFieldSelect: this.currentFieldSelect,
        enabled: new FormControl<boolean>(true),
        forRowsSelection: new FormControl<SelectorRowOption>(null),
        selectorForm: this.selectorForm,
        transformerForm: new FormGroup({
            advancedTransformerGroup: this.advancedTransformerGroup,
            basicTransformerGroup: this.basicTransformerGroup
        }),
        exportModels: new FormControl<Export[]>([ExportConstants.allExports]),
        order: new FormControl<number>(null)  // no validators even though there's a min/max since we don't want the error text to display
    });

    originalTransformer: TransformerDisplayModel;

    get showQueryInputs(): boolean {
        return this.tForm?.value['forRowsSelection'] !== SelectorRowOption.All;
    }

    get transformerForm(): UntypedFormGroup {
        return this.tForm.controls['transformerForm'];
    }

    readonly iconBolt: IconDefinition = faBoltLightning;

    // NOTE: the following icons are WIP for the new transformers page
    readonly sortIcon: IconDefinition = faSortAlt;
    readonly columnsIcon: IconDefinition = faColumns;

    modified: boolean = false;
    @Output() modifiedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    expanded: boolean = false;
    rotation: string = '0';
    dependenciesHidden: boolean = false;

    get shouldShowDependentFields(): boolean {
        return !this.newTransformer && this.modified && this.transformerFieldDependency?.dependent_fields.length > 0 && !this.dependenciesHidden;
    }

    get wellClasses(): Record<string, boolean> {
        if (!this.newTransformer) {
            return {
                'border-primary': this.modified
            };
        } else if (!this.onTransformersPage) {
            return {
                'border-0': true,
                'shadow-none': true
            };
        }
        return {};
    }

    get bodyClasses(): Record<string, boolean> {
        if (!this.onTransformersPage) {
            return {
                'px-4': true
            };
        }
        return {};
    }

    get headerClasses(): Record<string, boolean> {
        const baseClasses = { 'd-flex': true, 'gap-2': true, 'align-items-center': true };
        if (!this.onTransformersPage) {
            return {
                ...baseClasses,
                'border-bottom-0': true,
                'px-4': true
            };
        }
        return baseClasses;
    }

    get newTransformer(): boolean {
        return this.transformer.newTransformer;
    }

    get selectorRowOption(): typeof SelectorRowOption {
        return SelectorRowOption;
    }

    get transformerRequest(): TransformerRequest {
        return {
            field_name: this.currentField.displayName,
            selector: this.transformer.selector,
            transformer: this.transformer.transformer,
            export_id: this.transformer.exportModels.map((exprt: Export) => {
                return exprt.id;
            }),
            enabled: this.transformer.enabled ? '1' : '0'
        };
    }

    get enabledTooltip(): string {
        return `Transformer: ${this.transformer.enabled ? 'Enabled' : 'Disabled'}`;
    }

    get reorderWidth(): string {
        if (this.transformersLength >= 1000) {
            return '58px';
        } else if (this.transformersLength >= 100) {
            return '45px';
        } else if (this.transformersLength >= 10) {
            return '32px';
        }
        return '19px';
    }

    get maxLength(): string {
        return String(this.transformersLength);
    }

    get reordered(): boolean {
        return this.originalTransformer.order !== this.transformer.order;
    }

    get valid(): boolean {
        this.updateBasicComponentQueries();
        this.updateTransformerFromValue();
        this.tForm.markAllAsTouched();
        return this.tForm.valid;
    }

    readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private readonly appStateService: AppStateService,
        private readonly databasesDataService: DatabasesDataService,
        private readonly exportsDataService: ExportsDataService,
        private readonly fdxUI: FdxUI,
        private readonly localStorageService: LocalStorageService,
        private readonly modalService: ModalService,
        private readonly transformersDataService: TransformersDataService,
        public readonly transformerUtilities: TransformerUtilitiesService
    ) {}

    ngOnInit(): void {
        if (!this.exports) {
            this.exportsDataService.getExports(this.databaseId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                    next: (value: GetExportsResponse) => {
                        this.exports = value;
                    }
                });
        }

        if (!this.onTransformersPage) {
            this.getTransformerData();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getTransformerData(): void {
        this.databasesDataService.getFieldsWithParams<DbFieldsTransformerCountResponse>(this.databaseId, { transformer_count: 1 }).pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe({
            next: (value: DbFieldsTransformerCountResponse[]) => {
                const lastVariableName: string = this.localStorageService.getItem(this.transformerUtilities.variableSelectionKey);

                this.allDbFields = value.map<TransformerDbFieldModel>((dbField: DbFieldsTransformerCountResponse, index: number) => {
                    const mapped = this.transformerUtilities.mapDbFieldToModel(dbField, index);

                    if (
                        this.initialFieldName ? this.initialFieldName === mapped.displayName :
                            (
                                (isEmpty(lastVariableName) && index === 0) ||
                                mapped.displayName === lastVariableName
                            )
                    ) {
                        this.currentFieldSelect.patchValue(mapped);
                    }

                    return mapped;
                });
                this.initForm();
            }
        });
    }

    initForm(): void {
        this.patchTransformer();

        let ifConditionalValForSomeSelector = this.tForm.value.forRowsSelection === SelectorRowOption.Some ? this.transformer.selector : '';
        this.tForm.valueChanges.pipe(
            distinctUntilChanged(),
            startWith(this.tForm.value),
            pairwise(),
            tap(([prev, next]) => {
                if (prev.forRowsSelection === SelectorRowOption.All && next.forRowsSelection === SelectorRowOption.Some) {
                    this.transformer.selector = ifConditionalValForSomeSelector;
                    this.tForm.addControl('selectorForm', this.selectorForm, { emitEvent: false });
                    this.updateBasicComponentQueries();
                } else if (prev.forRowsSelection === SelectorRowOption.Some && next.forRowsSelection === SelectorRowOption.All) {
                    ifConditionalValForSomeSelector = this.transformer.selector;
                    this.transformer.selector = 'true';
                    this.tForm.removeControl('selectorForm', { emitEvent: false });
                    this.updateBasicComponentQueries();
                }
                this.updateTransformerFromValue();
                this.checkModified();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialField && this.initialField && this.onTransformersPage) {
            this.initForm();
        }

        if (changes.transformer && changes.transformer.firstChange) {
            this.originalTransformer = { ...this.transformer };
        }
    }

    checkModified(): void {
        if (JSON.stringify(this.originalTransformer) === JSON.stringify(this.transformer)) {
            this.modified = false;
        } else {
            this.modified = true;
        }

        this.modifiedChange.emit(this.modified);
    }

    updateBasicComponentQueries(): void {
        if (this.showQueryInputs) {
            this.queryInputsComponent?.updateComponentQuery();
        }
        this.transformerInputsComponent?.updateComponentQuery();
    }

    patchTransformer(): void {
        this.tForm.patchValue({
            enabled: this.transformer.enabled,
            forRowsSelection: this.transformer.selector === 'true' ? SelectorRowOption.All : SelectorRowOption.Some,
            selectorForm: {
                advancedSelectorGroup: {
                    advancedSelector: this.transformer.selector
                }
            },
            transformerForm: {
                advancedTransformerGroup: {
                    advancedTransformer: this.transformer.transformer
                }
            },
            exportModels: this.transformer.exportModels,
            order: this.transformer.order
        });
        if (this.transformer.selector === 'true') {
            this.tForm.removeControl('selectorForm');
        }
    }

    changeFieldName(): void {
        if (this.initialFieldName) {
            return;
        }

        this.localStorageService.setItem(this.transformerUtilities.variableSelectionKey, this.currentField.displayName);
    }

    // NOTE: WIP for the new transformers page
    idOfControl(controlName: string): string {
        return `${controlName}-${this.transformer.order}`;
    }

    // NOTE: WIP for the new transformers page
    expandTransformer(): void {
        this.expanded = !this.expanded;
        this.rotation = this.expanded ? '270' : '0';
    }

    updateTransformerFromValue(): void {
        this.transformer.enabled = this.tForm.value.enabled;
        this.transformer.order = this.tForm.value.order;
        this.transformer.exportModels = this.tForm.value.exportModels;
    }

    addTransformer(): void {
        if (!this.valid) {
            return;
        }

        if (this.mode === 'defer') {
            this.addTransformerRequested.emit(this.transformerRequest);
            return;
        }

        this.processingAction = true;

        this.transformersDataService.addTransformer(this.databaseId, this.transformerRequest)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (_value: TransformerModel) => {
                    this.transformer = { ...TransformerConstants.newTransformer };
                    this.transformerAction.emit();
                    this.fdxUI.showToastSuccess('Transformer added');
                    this.processingAction = false;
                },
                error: (error: HttpErrorResponse) => {
                    this.transformer.error = error.error;
                    this.processingAction = false;
                }
            });
    }

    editTransformer(): void {
        this.processingAction = true;
        if (this.valid) {
            if (this.reordered) {   // Ideally, the editTransformer API would handle resorting
                this.transformersDataService.changeTransformerSortOrder(this.databaseId, this.transformer.id, { sort_order: this.transformer.order })
                    .pipe(takeUntil(this.unsubscribe$))
                    .subscribe({
                        next: (_value: TransformerModel) => {
                            this.callEditTransformer();
                        },
                        error: (error: HttpErrorResponse) => {
                            this.transformer.error = error.error;
                            this.processingAction = false;
                        }
                    });
            } else {
                this.callEditTransformer();
            }


        } else {
            this.processingAction = false;
        }
    }

    private callEditTransformer(): void {
        this.transformersDataService.editTransformer(this.databaseId, this.transformer.id, this.transformerRequest)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (_value: TransformerModel) => {
                    this.modified = false;
                    this.modifiedChange.emit(this.modified);

                    this.transformer.error = null;
                    this.transformerAction.emit();

                    this.fdxUI.showToastSuccess('Transformer updated');
                    this.processingAction = false;
                },
                error: (error: HttpErrorResponse) => {
                    this.transformer.error = error.error;
                    this.processingAction = false;
                }
            });
    }

    deleteTransformer(): void {
        this.modalService.open(TransformersDeleteModalComponent, {
            size: 'lg',
            resolve: {
                'transformer': this.transformer,
                'currentField': this.currentField,
                'dbFields': this.dbFields
            }
        })
            .closed
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: () => {
                    this.transformersDataService.deleteTransformers(this.databaseId, {
                        transformer_ids: [this.transformer.id]
                    })
                        .pipe(takeUntil(this.unsubscribe$))
                        .subscribe({
                            next: () => {
                                this.transformerAction.emit();
                                this.fdxUI.showToastSuccess('Deleted transformer');
                            },
                            error: (_error: HttpErrorResponse) => {
                                this.fdxUI.showToastError('Unable to delete transformer');
                            }
                        });
                }
            });
    }

    clearTransformer(): void {
        if (this.showQueryInputs) {
            this.transformer.selector = '';
        } else {
            this.transformer.selector = 'true';
        }
        this.transformer.transformer = '\'\'';
    }
}
