<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div [formGroup]="formGroup">
    <ng-select
        [id]="controlName"
        [formControlName]="controlName"
        [placeholder]="exportsDropdownPlaceholder"
        [items]="orderedExports"
        [searchFn]="exportSearch"
        [trackByFn]="trackExport"
        [clearable]="false"
        [multiple]="multiple"
        [hideSelected]="multiple"
        [tabIndex]="tabIndex">
        <ng-template
            ng-label-tmp
            let-item="item"
            let-clear="clear">
            <fdx-select-multiple-label-template
                *ngIf="multiple else single"
                [item]="item"
                [clear]="clear">
                <ng-container
                    [ngTemplateOutlet]="label"
                    [ngTemplateOutletContext]="{ export: item }">
                </ng-container>
            </fdx-select-multiple-label-template>

            <ng-template #single>
                <ng-container
                    [ngTemplateOutlet]="label"
                    [ngTemplateOutletContext]="{ export: item }">
                </ng-container>
            </ng-template>
        </ng-template>

        <ng-template
            ng-option-tmp
            let-item="item">
            <ng-container
                [ngTemplateOutlet]="label"
                [ngTemplateOutletContext]="{ export: item }">
            </ng-container>
        </ng-template>
    </ng-select>
</div>

<ng-template
    #label
    let-export="export">
    <fa-icon
        *ngIf="export.id !== '0'"
        [icon]="getExportDot(export)"
        class="me-1"
        [ngClass]="getExportDotClass(export)">
    </fa-icon>
    <span [title]="export.name" class="titled-text">{{ export.name }}</span>
</ng-template>
