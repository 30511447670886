import AppStateService from '@ajs/services/AppStateService';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogsDataService } from '@app/logs/services/logs-data.service';
import { LogsStateService } from '@app/logs/services/logs-state.service';
import { LogsRequest } from '@app/logs/services/requests/logs-request.type';
import { FeedAlertsLogsResponse, IFeedAlertsLog } from '@app/logs/services/responses/logs-responses.types';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-logs-feed-alerts',
    templateUrl: './logs-feed-alerts.component.html',
    styleUrls: ['./logs-feed-alerts.component.scss']
})
export class LogsFeedAlertsComponent implements OnInit, OnDestroy {

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    logs: IFeedAlertsLog[] = [];

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    trackByIndex(index: number): number {
        return index;
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly logsDataService: LogsDataService,
        private readonly logsStateService: LogsStateService
    ) {}

    ngOnInit(): void {
        this.initLogsStateService();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initLogsStateService(): void {
        this.logsStateService.logsRequest$.pipe(
            tap((request: LogsRequest) => {
                this.getLogs(request);
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    getLogs(request: LogsRequest): void {
        this.logsDataService.getFeedAlertsLogs(this.databaseId, request).pipe(
            tap((response: FeedAlertsLogsResponse) => {

                /**
                 * TODO: Remove test data
                 */
                /* response = FEED_ALERTS_LOGS_RESPONSE; */

                this.logsStateService.logsTotalCount$.next(response.total_logs);
                this.logs = response.logs;
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }
}
