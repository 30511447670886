import { KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PipesModule } from '@app/modules/pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition, faChevronDown, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

export type FilterChip = {
    label: string,              // The label for the control
    control: AbstractControl,   // The control itself
    display?: string,           // A value to display for the control other than the control's value,
    permanent?: boolean         // Whether or not this filter chip should be permanent
};

@Component({
    standalone: true,
    selector: 'fdx-filter-chips',
    templateUrl: './filter-chips.component.html',
    styleUrls: ['./filter-chips.component.scss'],
    imports: [
        FontAwesomeModule,
        KeyValuePipe,
        NgbCollapseModule,
        NgClass,
        NgFor,
        NgIf,
        PipesModule
    ]
})
export class FilterChipsComponent {

    @Input() filterChips: FilterChip[] = [];
    @Output() readonly clearFilterClick: EventEmitter<FilterChip> = new EventEmitter<FilterChip>();
    @Output() readonly clearAllFiltersClick: EventEmitter<void> = new EventEmitter<void>();
    @Input() filtersCollapsed: boolean;
    @Input() truncateLength: number = 25;

    chipsCollapsed: boolean = true;

    closeIcon: IconDefinition = faXmark;
    chevronDownIcon: IconDefinition = faChevronDown;

    @ViewChild('flexContainer') flexContainer: ElementRef<HTMLDivElement>;

    get chipsOverflow(): boolean {
        const el = this.flexContainer?.nativeElement;
        return el && el.scrollWidth > el.clientWidth;
    }

    get hasActiveFilters(): boolean {
        return this.filterChips.length > 0;
    }

    get hasClearableFilters(): boolean {
        return this.filterChips.some((chip) => chip.permanent !== true);
    }

    getDisplayValue(filter: FilterChip): void {
        return filter.display ?? filter.control.value;
    }

    clearFilter(filter: FilterChip): void {
        this.clearFilterClick.emit(filter);
    }

    clearAllFilters(): void {
        this.clearAllFiltersClick.emit();
    }

    toggleChipsCollapsed(): void {
        this.chipsCollapsed = !this.chipsCollapsed;
    }

    trackByKey(_index: number, item: any): string {
        return item;
    }
}
